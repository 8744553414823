import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import Section from 'src/components/container/Section';
import { DeliveryTripHistory } from 'src/domain/deliveryTripHistoryType';
import deliveryTripService from 'src/services/deliveryTripService';

interface IProps {
  driverUId: string;
  trigger: boolean;
  setTrigger: any;
}

const FleetDriverJobHistory : React.FC<IProps> = (props) =>{

  const [page, setPage] = React.useState(1);

  const { driverUId, trigger, setTrigger } = props;
  const [ deliveryTrips,setDeliveryTrips] = useState<DeliveryTripHistory[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
        render: (value: number, item: DeliveryTripHistory, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่เริ่มงาน",
      dataIndex: "smcShipmentDateStr",
      key: "smcShipmentDateStr",
    },
    {
      title: "เลขที่งาน",
      dataIndex: "smcShipmentNo",
      key: "smcShipmentNo",
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "registId",
      key: "registId",
    },
    {
      title: "สาขา",
      dataIndex: "stationName",
      key: "stationName",
    },

  ];

  useEffect(() => {

    console.log("driverUId >>",driverUId)

    deliveryTripService.getHitoryBydrvCode(driverUId)
    .then((res) => {
      setDeliveryTrips(res.data)
      // setStations(stations => stations.concat(data));
        console.log(res.data)
    })
 }, []);

 

  return (
    <> 
    <Section >
    <Table
      pagination={{
        pageSize: 10,
        onChange(current) {
          setPage(current);
        },
        showSizeChanger: false
      }}
      // rowKey={(item: FleetDrv) => item.id}
      columns={columns}
      dataSource={deliveryTrips}
      size="small" />

  </Section></>
  )
}

export default FleetDriverJobHistory