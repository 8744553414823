/* eslint-disable react-hooks/exhaustive-deps */
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, message, Row, Select, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ExBookingTruck } from 'src/domain/exBookingTruckType';
import PageHeader from 'src/layouts/PageHeader';
import bookingTruckServies from 'src/services/bookingTruckServies';
import { showMessage } from 'src/utils';
import Section from "../../components/container/Section";
import { currDate, formatDate, showDate, showTime  } from "../../utils/dateUtils";
import classCss from "./ListBookingTruck.module.css";
import { toDayjs } from '../../utils/dateUtils';



const type = [
    { value: '', name: 'ทั้งหมด' },
    { value: 'LINE HALL', name: 'ขนย้ายสินค้า' },
    { value: 'DIST', name: 'กระจายสินค้า' }
];

const flg = [
    { value: '', name: 'ทั้งหมด' },
    { value: -1, name: 'ยกเลิก' },
    { value: 0, name: 'สร้าง' },
    { value: 1, name: 'ใช้งานแล้ว' }
];

const { Option } = Select;

const ListBookingTruck = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [listData, setListData] = useState<ExBookingTruck[]>([]);
    const [page, setPage] = React.useState(1);

    console.log("xxx");
    const columns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as "center",
            render: (value: number, item: ExBookingTruck, index: number) => (<>{(page - 1) * 20 + index + 1}</>)
        },
        {
            title: "วันที่", dataIndex: "exBookDate", key: "exBookDate",
            align: "center" as "center",
            render: (value: any, item: any, index: number) => {
                return <span>{value && showDate(value)}</span>;
            },
        },
        {
            title: "เวลา", dataIndex: "exBookTime", key: "exBookTime",
            align: "center" as "center",
            render: (value: any, item: ExBookingTruck, index: number) => {
                return <span>{showTime(item.exBookDate!, item.exBookTime!)}</span>;
            },
        },
        {
            title: "ประเภทการวิ่งรถ", dataIndex: "bookingType", key: "bookingType",
            align: "center" as "center",
            render: (value: any, item: any, index: number) => {
                if (item.useFlg === 0) {
                    return <Link to={`/app/bookingtruck/edit/${item.id}`}>{value}</Link>
                } else {
                    return <Link to={`/app/bookingtruck/view/${item.id}`}>{value}</Link>
                }
            }
        },
        {
            title: "ทะเบียนรถ", dataIndex: "registId", key: "registId",
        },
        {
            title: "ต้นทาง", dataIndex: "srcStation", key: "srcStation",
        },
        {
            title: "ปลายทาง", dataIndex: "destStation", key: "destStation",
        },
        {
            title: "จุด Stop", dataIndex: "stopPoint", key: "stopPoint",
            align: "center" as "center",
        },
        {
            title: "สถานะ", dataIndex: "useFlg", key: "useFlg",
            render: (value: string, item: any, index: number) => {
                const name = flg.find((item) => (item.value === value))
                return <span>{name?.name}</span>;
            },
        },
    ];


    useEffect(() => {
        onSearch(null);
    }, []);

    function onSearch(values: any) {
        if (values) {
            console.log("search param", values, values.rangeDate.toString());
        }

        let sDate:string = currDate();
        let eDate:string = currDate();

        if (values !== null && values.rangeDate !== undefined) {
            sDate = formatDate(values.rangeDate[0])
            eDate = formatDate(values.rangeDate[1])
        }

        (async () => {
            try {
                const res = await bookingTruckServies.getByParam(
                    sDate,
                    eDate,
                    '',
                    '',
                    'LINE HALL', '',  //fix to 'LINE HALL'
                    form.getFieldValue('useFlg')
                );

                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].index = i + 1;
                }

                setListData(res.data);
            } catch (err) {
                showMessage("ไม่พบข้อมูล!");
            }
        })();
    }

    const onFinishFailed = (values: any) => {
        message.info("ไม่พบข้อมูล");
    };

    return (
			<>
				<PageHeader
					title="ตั้งรถส่วนกลาง (ขนย้าย)"
					subTitle="กำหนดรถขนย้าย ระบุทะเบียน และ เอกสารอ้างอิงการทำงานจาก พาร์ทเนอร์"
				>
					<Space>
						<Button
							type="primary"
							icon={<PlusCircleOutlined />}
							onClick={() => {
								history.push("/app/bookingtruck/add");
							}}
							style={{ width: 120 }}
						>
							เพิ่ม
						</Button>
					</Space>
				</PageHeader>

				<div>
					<Form
						form={form}
						name="basic"
						initialValues={{
							rangeDate: [toDayjs(currDate()),
							            toDayjs(currDate())],
							useFlg: "",
							bookingType: "",
						}}
						layout="vertical"
						onFinish={onSearch}
						onFinishFailed={onFinishFailed}
					>
						<Section style={{ display: "flex", flexDirection: "column" }}>
							<Row gutter={[20, 10]}>
								<Col span={8}>
									<Form.Item label="วันที่บันทึกตั้งรถ" name="rangeDate">
										<DatePicker.RangePicker format={"DD/MM/YYYY"} />
									</Form.Item>
								</Col>
								{/* <Col span={8}>
									<Form.Item label="ประเภทการวิ่งรถ" name="bookingType">
										<Select>
											{type.map((item: any) => (
												<Option value={item.value} key={item.value}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col> */}
								<Col span={6}>
									<Form.Item label="สถานะ" name="useFlg">
										<Select>
											{flg.map((item: any) => (
												<Option value={item.value} key={item.value}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
                                <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        icon={<SearchOutlined />}
                                        onClick={() => form.submit()}
                                        style={{ width: 100, marginTop: 30}}
                                    >
                                        ค้นหา
                                    </Button>
								</Col>
							</Row>
							{/* <Row>
								<Col span={24} style={{ display: 'flex', justifyContent: 'flex-end'}}>
									<Button
										icon={<SearchOutlined />}
										onClick={() => form.submit()}
                                        style={{ width: 100}}
									>
										ค้นหา
									</Button>
								</Col>
							</Row> */}
						</Section>
					</Form>
				</div>
				<Section style={{ marginTop: 30}}>
					<Table
						columns={columns}
						dataSource={listData}
                        pagination={{
                            pageSize: 20,
                            onChange(current) {
								setPage(current);
							},
                            showSizeChanger:false
                        }}
						rowKey={(item: any) => item.id}
						rowClassName={(record, index) => (record.useFlg === -1 ? classCss.red : classCss.black)}
                        size="small"
					/>
				</Section>
			</>
		);
}

export default ListBookingTruck;
