import axios from 'axios';
import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'driver';

//const url = "http://localhost:8085/driver";

const getDrvUId = (drvUId: string) => {
     console.log(url + "/getbyDriverCode/" + drvUId);
    return http.get(url + "/getbyDriverCode/" + drvUId);
}

const addFleetDrv = (fleetDrv: any) => {
    console.log(url + "/addfleetdrv", fleetDrv);
    return http.post(url + "/addfleetdrv", fleetDrv);
};

const getFleetDrvByParam = async (params: any) => {
    let url1 = `${url}/byparam`;
    let res = await axios.post(url1, params);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}

export default {
    getDrvUId,
    getFleetDrvByParam,
    addFleetDrv,
}