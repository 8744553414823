
import axios from 'axios';
import { fleetRequest } from 'src/domain/fleetRequestType';
import http from '../lib/http';
import { fleetPort } from './configServices';


 const url = fleetPort + 'fleetrequestdrv';
 // const url = "http://localhost:8080/fleetrequestdrv";


const getDriverDataFromFleet = async (drvUId: any) => {
    console.log(url + "/driver/getDriver/" + drvUId)
    return http.get(url + "/driver/getDriver/" + drvUId);
}

const getDriverlicenseFromFleet = async (drvUId: any) => {
    console.log(url + "/driver/getDriverlicense/" + drvUId)
    return http.get(url + "/driver/getDriverlicense/" + drvUId);
}

const getAllFleetDriver = () => {
    console.log(`${url}/driver/getallfleetdrv`)
    return http.get(`${url}/driver/getallfleetdrv`);
 };

export default {
    getDriverDataFromFleet,
    getDriverlicenseFromFleet,
    getAllFleetDriver,
}