import { Button, Col, Form, Input, Drawer, Row, Layout, Radio, Table, message } from "antd";
import React, { useEffect, useState } from 'react'
import { Class } from "../../domain/classType";
import { ClassDetail } from "../../domain/classDetailType";
import { PlusCircleOutlined,EditOutlined } from '@ant-design/icons';
import classServices from "src/services/classServices";
import { showMessage, handleEnter } from "src/utils";
import Section from "src/components/container/Section";
import { SessionUser } from "src/store/authen/types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const flg = [
  { value: -1, name: 'ไม่ใช้งาน' },
  { value: 0, name: 'ใช้งาน' },
];

const FormClassAndDetail: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  console.log(':: load :: ',id)
  const history = useHistory();
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [classtype, setClassType] = useState<Class>({} as Class);
  const [classdetail, setClassDetail] = useState<ClassDetail>({} as ClassDetail);
  const [formEdit] = Form.useForm();
  const [formAdd] = Form.useForm();
  const [formAddItem] = Form.useForm();
  const [action, setAction] = useState(false);
  const [classDetails, setClassDetails] = useState<ClassDetail[]>([]);
  const [newId, setNewId] = useState(Number);
  const [newItemId, setNewItemId] = useState(Number);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: ClassDetail, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "ชื่อประเภทข้อมูลย่อย",
      dataIndex: "classDetailName",
      key: "classDetailName",
      render: (value: string, item: ClassDetail, index: number) => {
        return (
          <div onClick={() => {
            console.log('edit item')
            onEditItem(item.id)

          }} style={{ cursor: "pointer" }}>

            {value}
          </div>
        )
      },
    },
    {
      title: "สถานะ", dataIndex: "useFlg", key: "useFlg",
      render: (value: number, item: any, index: number) => {
          const name = flg.find((item) => (item.value === value))
          return <span>{name?.name}</span>;
      },
    },

  ];
  useEffect(() => {
    setNewId(id);
    if (id !== 0) {
      setAction(true);
      classServices.getById(id)
        .then((res) => {
          console.log(res.data)
          setClassType(res.data);
        })
    }
  }, []);

  useEffect(() => {
    if (trigger) {
      console.log(" form class submit")
      formEdit.submit();
    }
  }, [trigger]);


  useEffect(() => {
    if (newId !== 0) {
      formEdit.setFieldsValue({
        codeMain: classtype.classNo,
        classMain: classtype.className,
        remarkMain: classtype.remark,
        useFlgMain : classtype.useFlg
      });
      getItems();
    }
    console.log(" action ", action);
  }, [classtype]);

  const onFinish = async (values: any) => {
    console.log(' finish formadd ', values)
    //if (id === 0) {
      const newClassObj: Class = {
        id: id === 0 ? 0: id,
        classNo: values.classno,
        className: values.classname,
        remark: values.remark === undefined ? "" : values.remark,
        useFlg: values.useFlg === undefined ? 0 : values.useFlg,
        savStation: sessionUser.stationCode,
        createUserid: sessionUser.userId,
        createUsername: sessionUser.username
      }
      console.log(" obj ", newClassObj);
      try {
        // stationAreaServices.addStationAreaProcess(stationCode, values.locationList);
        if (id === 0) {
          const res = await classServices.addClass(newClassObj)
          message.success("บันทึกข้อมูลหลักเรียบร้อยแล้ว", 1.5);
          setClassType(res.data);
          setNewId(res.data.id);
          setAction(true);
          history.push("/app/class/edit/"+ res.data.id);
          console.log(" new ข้อมูลหลัก ", res.data);
        } else {
          classServices.updateClass(newClassObj)
          message.success("แก้ไขข้อมูลหลักเรียบร้อยแล้ว", 1.5);
        }

      } catch (err) {
        showMessage(''+err);
      }
    //}

    setTrigger(false);
    setIsShowDrawer(false);
  };

  function onEditItem(selectedDetailId: number) {
    setNewItemId(selectedDetailId);
    
    let editItem = classDetails.find((val) => { return val.id === selectedDetailId })
    console.log(' edit item - ', editItem)
    if (editItem) {
      formAddItem.setFieldsValue({
        classdetailno: editItem.classDetailNo,
        classdetailname: editItem.classDetailName,
        classdetailremark: editItem.remark,
        classdetailuseflg: editItem.useFlg
      });
    };
    setIsShowDrawerItem(true);
  }

  const onFinishItem = async (values: any) => {
    //if (id === null) {
    const newClassDetailObj: ClassDetail = {
      id: newItemId !== 0 ? newItemId : 0,
      classId: newId,
      classDetailNo: values.classdetailno,
      classDetailName: values.classdetailname,
      remark: values.classdetailremark,
      useFlg: values.classdetailuseflg,
      savStation: sessionUser.stationCode,
      createUserid: sessionUser.userId,
      createUsername: sessionUser.username
    }
    console.log(" newClassDetailObj ", newClassDetailObj);
    try {
      // stationAreaServices.addStationAreaProcess(stationCode, values.locationList);
      if (newItemId === 0) {
        await classServices.addClassDetail(newClassDetailObj)
        message.success("บันทึกข้อมูลย่อยเรียบร้อยแล้ว", 1.5);

      } else {
        await classServices.updateClassDetail(newClassDetailObj)
        message.success("แก้ไขข้อมูลย่อยเรียบร้อยแล้ว", 1.5);
      }
    } catch (err) {
      showMessage(''+err);
    }
    clearFormItem();
    onCloseItem();
    getItems();
  };

  const clearFormItem = () => {
    formAddItem.setFieldsValue({
      classdetailno: "",
      classdetailname: "",
      classdetailremark: "",
      classdetailuseflg: 0
    });
  }

  const getItems = async () => {
    console.log(' new id =', newId);
    let resData: ClassDetail[] = await classServices.getAllDetailByClassId(newId);
    console.log(' getItems classdetail ::: ', resData);
    if (resData) {
      
      setClassDetails(resData);
    }
  }

  const add = () => {
    console.log(" add class")
    //setTrigger(true);
    formAdd.submit();
  }

  const addItem = () => {
    console.log(" add class detail ")
    formAddItem.submit();
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const onClose = () => {
    setIsShowDrawer(false);
  }

  const onShow = () => {
    setIsShowDrawer(true);
  }

  const [isShowDrawerItem, setIsShowDrawerItem] = useState(false);
  const onCloseItem = () => {
    setIsShowDrawerItem(false);
  }

  const onShowItem = () => {
    if(newId === 0) {
      showMessage(" กรุณากำหนดข้อมูลหลัก "+ newId);
    } else {
      clearFormItem();
      setIsShowDrawerItem(true);
    }
  }

  return (
    <>
      <Layout.Content className="app-page-content">
      {action ?
        <Form
          {...layout}
          form={formEdit}
          name="formEdit"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
        
        <Row gutter={[20, 10]}>
						<Col span={16}>
            <Section>
              <Form.Item label="รหัสข้อมูลหลัก" name="classMain">
                  <Input disabled={action} onKeyDown={handleEnter}/>
              </Form.Item>
              <Form.Item label="ชื่อข้อมูลหลัก" name="classMain">
                  <Input disabled={action} onKeyDown={handleEnter}/>
              </Form.Item>
              <Form.Item label="หมายเหตุ" name="remarkMain">
                <Input disabled={action} onKeyDown={handleEnter}/>
              </Form.Item>
            </Section>
          </Col>
          <Col span={8}>
            <Section>
              <Form.Item name="useFlgMain" label="สถานะ">
								<Radio.Group defaultValue={0}>
									<Radio value={0}>ใช้งาน</Radio>
									<Radio value={-1}>ไม่ใช้งาน</Radio>
								</Radio.Group>
							</Form.Item>
            </Section>
          </Col>
        </Row>
        </Form>
        : 
        <Row gutter={[20, 10]}>
        <Col span={8}>
                <Button type="primary" size="large" icon={<PlusCircleOutlined />} onClick={() => setIsShowDrawer(true)}>
                  เพิ่มประเภทข้อมูลหลัก
                </Button>
          </Col>
        </Row>
      }

        <Drawer
          title="เพิ่มประเภทข้อมูลหลัก"
          width="30%"
          onClose={onClose}
          visible={isShowDrawer}
          bodyStyle={{ paddingBottom: 80 }}
          closable={false}
          destroyOnClose={true}
        >
          <Layout.Content className="app-page-content">

            <Form
              {...layout}
              form={formAdd}
              name="formAdd"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Row gutter={5} style={{ paddingTop: "15px", }}>
                <Col span={24}>
                  <Form.Item
                    name="classno"
                    label="รหัส"
                  >
                    <Input maxLength={5} onKeyDown={handleEnter}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="classname"
                    label="ชื่อ"
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input maxLength={50} onKeyDown={handleEnter}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="remark"
                    label="หมายเหตุ"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="useFlg"
                    label="สถานะ"
                    initialValue={0}
                  >
                    <Radio.Group>
                      <Radio value={0}>ใช้งาน</Radio>
                      <Radio value={-1}>ไม่ใช้งาน</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item>
                    <>
                      <Button type="primary" onClick={() => add()} style={{ marginLeft: 100, }} icon={<PlusCircleOutlined />}>
                        เพิ่ม
                      </Button>
                    </>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Layout.Content>
        </Drawer>

        <Drawer
          title="เพิ่มประเภทข้อมูลย่อย"
          width="30%"
          onClose={onCloseItem}
          visible={isShowDrawerItem}
          bodyStyle={{ paddingBottom: 80 }}
          closable={false}
          destroyOnClose={true}
        >
          <Layout.Content className="app-page-content">

            <Form
              {...layout}
              form={formAddItem}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinishItem}
            //onFinishFailed={onFinishFailedItem}
            >
              <Row gutter={5} style={{ paddingTop: "15px", }}>
                <Col span={24}>
                  <Form.Item name="classdetailno" label="รหัส" 
                  rules={[{ required: true, message: 'กรุณาระบุรหัส' }]}
                  >
                    <Input maxLength={5}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="classdetailname" label="ชื่อ"
                    rules={[{ required: true, message: 'กรุณาระบุชื่อ' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="classdetailremark" label="หมายเหตุ">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item name="classdetailuseflg" label="สถานะ" initialValue={0}>
                    <Radio.Group>
                      <Radio value={0}>ใช้งาน</Radio>
                      <Radio value={-1}>ไม่ใช้งาน</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item>
                    <>
                        {newItemId === 0 ?
                        <Button type="primary" onClick={() => addItem()} style={{ marginLeft: 100, }} icon={<PlusCircleOutlined />}>เพิ่ม</Button>
                        :
                        <Button type="primary" onClick={() => addItem()} style={{ marginLeft: 100, }} icon={<EditOutlined />}>แก้ไข</Button>

                        }
                    </>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Layout.Content>
        </Drawer>

      </Layout.Content>

      <Section style={{ marginTop: 30 }}>
        <Button
          type="primary"
          size="middle"
          icon={<PlusCircleOutlined />}
          onClick={() => onShowItem()}
        >
          ข้อมูลย่อย
        </Button>
        <Form.Item style={{ marginTop: 10 }} name="classDetail">
          <Table
            rowKey={(item: ClassDetail) => item.id}
            columns={columns}
            dataSource={classDetails}
            size="small" />
        </Form.Item>
        </Section>
    </>
  )
}

export default FormClassAndDetail;