import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'productType';

const getByParam = (code: any, name: any, useFlg: any) => {
    const param = `code=${code}&name=${name}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
};

const getByCode = (code: any) => {
    const param = `code=${code}`;
    return http.get(url + "/byCode/?" + param);
};

export default { getByParam, getByCode }