import React from "react";
import classes from "./SideNavMenu.module.css";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../assets/imgs/renger-logo.jpg";
import { useAppSelector } from "src/store";

interface IProps {
  menuItems: { id: number; name: string; path: string, roles: string[], type?: string }[];
  onMenuClick: () => void;
}

const menus = (
  <Menu
    // onClick={this.handleClick}
    style={{ width: "225px" }}
    defaultSelectedKeys={["1"]}
    defaultOpenKeys={["sub1"]}
    mode="inline"
    className="sideNavMenuContainer"
  >
    <Menu.Item key="1">Option 1</Menu.Item>
    <Menu.Item key="2">Option 2</Menu.Item>
  </Menu>
);



const SideNavMenu: React.FC<IProps> = (props) => {
  const { sessionUser } = useAppSelector((state) => state.auth);

  const _menuItems = props.menuItems;
  // const _menuItems = props.menuItems.filter(item => {
  //   let isFind = false;
  //   sessionUser.roles.forEach(role => {
  //     if (!isFind) isFind = item.roles.includes(role)
  //   })

  //   return isFind;
  // })

  const menuList = () => {
    return (
      <Menu
        // onClick={this.handleClick}
        style={{ width: "225px", transition: 'width 2s' }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="sideNavMenuContainer"
      >
        {_menuItems && _menuItems.map((menu) => {

          if (menu.type && menu.type === 'divider') {
            return (
              <Menu.Divider style={{  }}/>
            )
          } else if(menu.type && menu.type === 'title') {
            return (<div style={{
              fontSize: 12,
              fontWeight: 600,
              marginTop: 20,
              marginBottom: 20,
              color: '#333',
              paddingLeft: 10}}>{menu.name}</div>)
          }

          return (
            <Menu.Item key={menu.id}>
              <Link to={menu.path} onClick={props.onMenuClick}>{menu.name}</Link>
            </Menu.Item>
          )

        })}
      </Menu>
    );
  };

  return (
    <div className={classes["sideNavMenu"]}>
      <div
        className={classes.sideNavMenuBrandName}
        style={{ border: '1px solid red', margin: 10, backgroundColor: '#E20000', borderRadius: 6}}>
        <img src={logoImg} style={{ width: "120px", height: "auto",  }} />
      </div>
      {menuList()}
    </div>
  );
};

export default SideNavMenu;
