import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'station';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByCode = (code: string) => {
    return http.get(`${url}/byCode?code=${code}`);
}

const getByParam = (code: string ,name : string, frienchiseename : string, locationcode : string , useFlg : number) => {
    const param = `code=${code}&name=${name}&frienchiseename=${frienchiseename}&locationcode=${locationcode}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
    
  };

const addStation = (Station: any) => {
    return http.post(url + "/add", Station);
};

const updateStation = (Station: any) => {
    return http.put(url + "/update", Station);
};

export default {
    getAll,
    getById,
    getByParam,
    addStation,
    updateStation,
    getByCode
}