import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'fileprice';

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByFilter = (sDate: string, eDate: string, fileCode: string, fileName: string) => {
    const param = `sDate=${sDate}&eDate=${eDate}&fileCode=${fileCode}&fileName=${fileName}`;
    return http.get(url + "/byFilters/?" + param);
};

const addFileprice = (entity: any) => {
    return http.post(url + "/add", entity);
};

const updateFileprice = (entity: any) => {
    return http.put(url + "/update", entity);
};

const isUsedFileprice = (id: number) => {
    const param = `filepriceId=${id}`;
    return http.get(url + "/isUsedFileprice/?" + param);
}

export default { getById, getByFilter, addFileprice, updateFileprice, isUsedFileprice }
