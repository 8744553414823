import React, { useState } from "react";

import SiderNav from "src/layouts/SiderNav";
import SideNavMenu from "./SideNavMenu";
import SideNavModule from "./SideNavModule";
import classes from "./AppLayout.module.css";
import { menus, menuModules } from "./menu";

interface IProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<IProps> = (props) => {
  const { children, ...rest } = props;
  const [menuItems, setMenuItems] = useState<any>({});
  const [showMenu, setShowMenu] = useState(false);

  const changeMenuLabelByLang = (menuItems: any) => {
    if (menuItems && Array.isArray(menuItems)) {
      // for (let i = 0; i < menuItems.length; i++) {
      //   if (menuItems[i].key && localLangItems[menuItems[i].key]) {
      //     menuItems[i].name = localLangItems[menuItems[i].key].langValue;
      //   }
      // }
      setMenuItems([...menuItems]);
    }
  };

  const handleSelectModule = (moduleCode: string) => {
    console.log("module code", moduleCode);
    const moduleItem = menus.find((module) => module.moduleCode === moduleCode);
    // setMenuItems(moduleItem?.moduleMenus);
    if (moduleItem) {
      changeMenuLabelByLang(moduleItem.moduleMenus);
    }
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const showHandler = () => {
    setShowMenu((prev) => !prev);
  };

  const handleMenuClick = () => {
    setShowMenu(false);
  };

  return (
		<div style={{ display: "flex" }}>
			<div className={classes.sideNav}>
				<SideNavModule
					showMenu={showHandler}
					onSelectModule={handleSelectModule}
					menuModules={menuModules}
					menus={menus}
				/>
				{showMenu && <SideNavMenu menuItems={menuItems} onMenuClick={handleMenuClick} />}
			</div>

			<div
				style={{
					backgroundColor: "#f1f2f7",
					width: "100%",
					overflow: "auto",
					minHeight: "100vh",
					height: "100%",
					paddingLeft: showMenu ? "285px" : "60px",
				}}
			>
				{/* { process.env.REACT_APP_BUILD_LABEL === 'Staging' && (<div style={{ width: '100%', height: '3px', backgroundColor: '#1665c0c2'}} />)}
        { process.env.REACT_APP_BUILD_LABEL === 'Dev' && (<div style={{ width: '100%', height: '3px', backgroundColor: '#c0871678'}} />)} */}
				{(process.env.REACT_APP_BUILD_LABEL === "Dev" || process.env.REACT_APP_BUILD_LABEL === "UAT" || process.env.REACT_APP_BUILD_LABEL === "Staging") && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							height: "3px",
							backgroundColor: "#c0871678",
						}}
					>
						<div
							style={{
								position: "relative",
								top: "0px",
								textAlign: "center",
								height: "20px",
								width: "80px",
								borderBottomLeftRadius: "10px",
								borderBottomRightRadius: "10px",
								color: "#9e6c06",
								backgroundColor: "#c0871678",
							}}
						>
							{process.env.REACT_APP_BUILD_LABEL}
						</div>
					</div>
				)}

				<div
					style={{
						width: "100%",
						padding: "20px 40px 20px 40px",
						minHeight: "100vh",
						height: "100%",
						// border: '1px solid red'
					}}
				>
					{props.children}
				</div>
			</div>
		</div>
	);
};

export default AppLayout;
