import { Col, Form, Table, Button, Row, Select, Layout, message } from "antd";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router";
import { showMessage, handleEnter } from "src/utils";
import { SessionUser } from "src/store/authen/types";
import { useSelector } from "react-redux";
import { Station } from "src/domain/stationType";
import { Fleet, FleetStation } from "src/domain/fleetStation";
import fleetStationServices from "src/services/fleetStationServices";
import { LockTwoTone, UnlockTwoTone, PlusCircleOutlined } from '@ant-design/icons';
import StationModal from "./modal/StationModal";
import StatusModal from "./modal/StatusModal";
import Section from "../../components/container/Section";

const { Option } = Select;
interface IProps {
  truckUId: string;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FormFleetStation: React.FC<IProps> = (props) => {
  const { truckUId, trigger, setTrigger } = props;
  const [fleetStation, setFleetStation] = useState<FleetStation>({} as FleetStation);
  const [fleetStationList, setFleetStationList] = useState<FleetStation[]>([]);
  const [form] = Form.useForm();
  const [selectTruckUId, setSelectTruckUId] = useState(String);
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [page, setPage] = React.useState(1);
  const [isStationModalOpen, setIsStationModalOpen] = useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  //const [fleets, setFleets] = useState<Array<{ value: string, objectvalue: Fleet }>>([]);
  const [fleets, setFleets] = useState<Fleet[]>([]);
  const history = useHistory();
  const [fleet, setFleet] = useState<Fleet>({} as Fleet);

  const stationcolumns = [
    {
      title: "ลำดับ",
      key: "index",
      render: (value: number, item: FleetStation, index: number) => (<>{index + 1}</>)
    },
    {
      title: "สาขา",
      dataIndex: "stationName",
      key: "stationName",
    },
    {
      title: "สถานะ",
      key: "",
      render: (value: number, item: FleetStation, index: number) =>
        <>{item.useFlg === 0 ?
          <a onClick={() => showStatusModal(item)}><UnlockTwoTone /> </a> :
          <a onClick={() => showStatusModal(item)}><LockTwoTone /></a>}
        </>
      ,
    },
  ];

  useEffect(() => {
    console.log(' truckUId = ', truckUId);
    getAllFleet();
    setSelectTruckUId(truckUId);
    if (truckUId !== "0") {
      listFleetStation();
    }

  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  //Fleet
  const getAllFleet = async () => {
    const res = await fleetStationServices.getAllFleet("", 0);
    setFleets(res.data);
  }
  const onSearch = async (searchText: string) => {
    console.log(" searchText " + searchText);
    const res = await fleetStationServices.getAllFleet(searchText, 1)
    setFleets(res.data);
  };
  const onSelect = (selectedId: any) => {
    console.log(' selectedId = ', selectedId);
    let selectFleet = fleets.find((val) => { return val.truckUId === selectedId})
    console.log(' selectFleet = ', selectFleet);
    if (selectFleet != null) {
      let fleetEntity : Fleet = {
        id : 0,
        truckUId : selectFleet.truckUId,
        registId : selectFleet.registId,
        driverUId : selectFleet.driverUId,
        driverName : selectFleet.driverName,
        useFlg  : selectFleet.useFlg
      }
      console.log(' fleetEntity  = ', fleetEntity);
      setFleet(fleetEntity);
    }
    setSelectTruckUId(selectedId);
  };

  //edit
  const listFleetStation = async () => {
    let params = {
      truckUId: truckUId,
      stationCode: 0,
      useFlg: 1,
      truckRegistId : ''
    }
    console.log(' edit ', params)
    let resData: FleetStation[] = await fleetStationServices.getFleetStationByParam(params);
    if (resData) {
      setFleetStationList(resData);
      console.log(' fleet station list ::: ', resData);
    }
  }
  //save 
  const onFinish = async (values: any) => {
    console.log(truckUId)
    console.log(' fleetStationList.length  ' , fleetStationList.length );
    if (fleetStationList.length > 0) {
       //add fleet first
      if (truckUId === "0" ) {
      
        console.log(' new fleet to add ' , fleet)
        await fleetStationServices.addFleet(fleet);
      }
      for (let tempEntity of fleetStationList) {
        console.log(" ===== tempEntity  ====", tempEntity);
        if (tempEntity.id === 0) {
          //try {
            await fleetStationServices.addFleetStation(tempEntity).then(res => {
              //message.success("บันทึกข้อมูลเรียบร้อยแล้ว", 1.5);
            }).catch(err => {
              console.log(err);
            });
          // } catch (err) {
          //   showMessage(err);
          // }
        } else {
          try {
            await fleetStationServices.updateFleetStation(tempEntity).then(res => {
                //message.success("แก้ไขข้อมูลเรียบร้อยแล้ว", 1.5);
            }).catch(err => {
              console.log(err);
            });
          } catch (err) {
            //showMessage(err);
          }
        }
      }
      message.success("บันทึกข้อมูลเรียบร้อยแล้ว", 1.5);
      history.replace("/app/fleetstation");
      setTrigger(false);
    } else {
      showMessage(" กรุณาเพิ่มสาขาก่อนทำการบันทึกค่ะ ");

    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  //other
  const showModal = () => {
    console.log(' truckUId ++++', truckUId);
    console.log(' selectTruckUid ++++', selectTruckUId);
    if (selectTruckUId == null || selectTruckUId === "0") {
      showMessage(" กรุณาเลือกทะเบียนรถ ");
    } else {
      setIsStationModalOpen(true);
    }
  };

  const showStatusModal = (selectFleetStation: FleetStation) => {
    console.log("showStatusModal :", selectFleetStation);
    setIsStatusModalOpen(true);
    setFleetStation(selectFleetStation);
  };

  const onChangeStatus = (data: FleetStation) => {
    let onEditData = fleetStationList.find((val) => { return val.id === data.id })
    if (onEditData) {
      onEditData.useFlg = data.useFlg;
      onEditData.createUserid = data.createUserid;
      setFleetStationList([...fleetStationList]);
    }
    console.log(' onChangeStatus :::: fleet station list ', fleetStationList);
  }
  const onDoubleClickStation = (data: Station) => {
    let chkDuplicateObj = fleetStationList.find((val) => { return val.stationCode === data.stationCode })
    if (chkDuplicateObj != null) {
      showMessage(" สาขาซ้ำ กรุณาเลือกใหม่");

    } else {
      let fleetStationEntity: FleetStation = {
        id: 0,
        truckCode: truckUId==="0"? fleet.truckUId : truckUId ,
        stationCode: data.stationCode,
        stationName: data.stationName,
        registId: fleetStationList[0]?.registId,
        driverCode: "",
        driverName: "",
        truckUseFlg: 0,
        useFlg: data.useFlg,
        savStation: sessionUser.stationCode,
        createUserid: sessionUser.userId,
        createUsername: sessionUser.username
      };
      console.log(' onDoubleClickStation  fleetStationEntity ', fleetStationEntity);
      setFleetStationList([...fleetStationList, fleetStationEntity]);
      console.log(' fleet station list ', fleetStationList);
    }
  }

  return (
    <>
      {/* <Layout.Content className="app-page-content"> */}
       
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          
        <Section>
          <Row gutter={[20, 10]}>
            <Col span={12}>
              {truckUId === "0" ?
                <Form.Item
                  label="ทะเบียนรถ"
                  name="regitsId"
                  rules={[
                    { required: true, message: "กรุณาเลือกทะเบียนรถ" },
                  ]}
                >
                  <Select
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={onSearch}
                    onSelect={onSelect}
                  >
                    {fleets.map((obj: any) => {
                      return (
                        <Option value={obj.truckUId} key={obj.truckUId}>
                          {obj.registId} - {obj.driverName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                :
                <>
                  <Form.Item
                    label="ทะเบียนรถ"
                    name="regitsId"
                  >
                    <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                      <b>{fleetStationList[0]?.registId}</b>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="พนักงานขับรถ"
                    name="driverName"
                  >
                    <div style={{ fontWeight: "bold" }}>
                      <b>{fleetStationList[0]?.driverName} </b>
                    </div>
                  </Form.Item>
                </>
              }
            </Col>
          </Row>
          <Row style={{ paddingTop: "15px", paddingLeft: "100px", }}>
            <Col span={18}>
                <Button
                    size="middle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => showModal()}
                    type='primary'
                    style={{marginBottom: 10}}
                    >
									สาขา
								</Button>

              {/* table station  */}
              <Table
                style={{ paddingTop: "15px", }}
                pagination={{
                  pageSize: 10,
                  onChange(current) {
                    setPage(current);
                  }
                }}
                rowKey={(item: FleetStation) => item.id}
                columns={stationcolumns}
                dataSource={fleetStationList}
                size="middle" />
            </Col>
          </Row>
          </Section>
        </Form>
        <StationModal
          isModalOpen={isStationModalOpen}
          onOk={null}
          onCancel={() => { setIsStationModalOpen(false) }}
          onRowDoubleClick={(data: Station, index: number, evt: any) => {
            console.log("onRowDoubleClick: ", data, index, evt);
            onDoubleClickStation(data);
          }}
        />
        <StatusModal
          isModalOpen={isStatusModalOpen}
          onOk={onChangeStatus}
          onCancel={() => { setIsStatusModalOpen(false) }}
          fleetStation={fleetStation}
        />
      {/* </Layout.Content> */}
    </>
  )
}

export default FormFleetStation;