import { blue } from '@ant-design/colors';
import { CloseCircleOutlined, EditFilled } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Col, Drawer, Dropdown, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import filepriceServices from 'src/services/filepriceServices';
import templateExcelServices from 'src/services/templateExcelServices';
import { showMessage } from 'src/utils';
import { currDate } from 'src/utils/dateUtils';
import { utils, writeFileXLSX } from 'xlsx';
import Section from '../../components/container/Section';
import { Fileprice } from '../../domain/filepriceType';
import FilepriceDrawer from './drawer/FilepriceDrawer';
import FilepriceViewDetail from './FilepriceViewDetail';
import FilepriceViewHead from './FilepriceViewHead';

interface IParams {
    id: string;
}

const FilepriceView: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id = Number(props.match.params.id);
    const history = useHistory();
    const [fileprice, setFileprice] = useState<Fileprice>({} as Fileprice)
    const [isShowDrawer, setIsShowDrawer] = useState(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => onDownloadExcel('fileprice_area_markup')}>
                    เปอร์เซ็นต์ AREA
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => onDownloadExcel('fileprice_sizing')}>
                    ขนาดสินค้า (SIZE)
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a onClick={() => onDownloadExcel('fileprice_zone_pricelist')}>
                    ราคาสินค้า (PRICE)
                </a>
            ),
        },
    ];

    useEffect(() => {
        if (id !== undefined && id !== 0) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        const res = await filepriceServices.getById(id);
        setFileprice(res.data)
    }

    const getColumnHeader = async (name: string) => {
        const res = await templateExcelServices.getByHeadNameAndEffDate(name, currDate())
        if (res.status === 200) {
            return await res.data;
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }

    const onOpenDrawer = () => {
        setIsShowDrawer(true);
    }

    const onCloseDrawerWithRefresh = (val: boolean) => {
        setIsShowDrawer(false);
        if (val === true) {
            fetchData();
        }
    }

    const onDownloadExcel = async (name: string) => {
        const templateExcelDetails = await getColumnHeader(name);
        if (templateExcelDetails != null) {
            const header = templateExcelDetails.map((item: any) => {
                return item.columnName
            });
            const ws = utils.json_to_sheet([], { header: header });

            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, name);
            writeFileXLSX(wb, `${name}.xlsx`);
        }
    }

    return (
        <>
            <PageHeader title="กำหนดแฟ้มราคา">
                <Space>
                    <Typography.Text>นำเข้า</Typography.Text>
                    <Button
                        size="large"
                        style={{ width: 90 }}
                        type="primary"
                        onClick={() => history.push(`/app/fileprice/import/area/${fileprice.id}`)}
                    >
                        AREA
                    </Button>
                    <Button
                        size="large"
                        style={{ width: 90 }}
                        type="primary"
                        onClick={() => history.push(`/app/fileprice/import/size/${fileprice.id}`)}
                    >
                        SIZE
                    </Button>
                    <Button
                        size="large"
                        style={{ width: 90 }}
                        type="primary"
                        onClick={() => history.push(`/app/fileprice/import/value/${fileprice.id}`)}
                    >
                        PRICE
                    </Button>
                    <Dropdown menu={{ items }}>
                        <Button>DOWNLOAD FILE</Button>
                    </Dropdown>

                    <Button
                        size="large"
                        style={{ width: 120 }}
                        icon={<CloseCircleOutlined />}
                        onClick={() => history.goBack()}
                    >
                        กลับ
                    </Button>
                </Space>
            </PageHeader>

            <Section>
                <Row>
                    <Col span={22}>
                        <FilepriceViewHead fileprice={fileprice}></FilepriceViewHead>
                    </Col>
                    <Col span={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: 'flex-start' }}>
                        <Button shape="circle" size='middle' icon={<EditFilled style={{ color: blue.primary }} />} onClick={onOpenDrawer} />
                    </Col>
                </Row>
            </Section>

            <br />

            <FilepriceViewDetail filepriceId={id} fileCode={fileprice.fileCode} />

            <Drawer
                title="แฟ้ม"
                width="400px"
                onClose={() => onCloseDrawerWithRefresh(false)}
                open={isShowDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <FilepriceDrawer fileprice={fileprice}
                    saveComplete={() => onCloseDrawerWithRefresh(true)}
                    onCloseDrawer={() => onCloseDrawerWithRefresh(false)}
                />
            </Drawer>
        </>
    )
}

export default FilepriceView
