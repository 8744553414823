import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import customerServices from 'src/services/customerServices';
import { Customer } from 'src/domain/customerType';
import Section from "../../components/container/Section";

const ListCustomer = () => {
    const history = useHistory();
    const [customers, setCustomers] = useState([]);
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            render: (value: number, item: Customer, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "ชื่อ",
            dataIndex: "custName",
            key: "custName",
            render: (value: string, item: Customer, index: number) => <Link to={`/app/customer/edit/${item.id}`}>{value}</Link>,
        },
    ];
    useEffect(() => {
        customerServices.getAll()
            .then((res) => {
                setCustomers(res.data)
            })

    }, []);

    const search = () => {
        customerServices.getByParam(searchName, status)
            .then((res) => {
                setCustomers(res.data)
            })
    }

    return (
        <div>
            <PageHeader title="กำหนดข้อมูลลูกค้า" subTitle="บันทึก/แก้ไข ข้อมูลลูกค้า">
                <Space>
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/customer/add") }}
                        style={{ width: 120 }}
                    >
                        เพิ่ม
                    </Button>
                </Space>
            </PageHeader>
            
            <Section style={{ display: "flex", flexDirection: "column" }}>
                <Form layout="vertical">
                    <Row gutter={[20, 10]} style={{ display: "flex", alignItems: "start" }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Form.Item label="ชื่อลูกค้า" >
                            <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/>
                        </Form.Item>
                        <Radio.Group name="status" defaultValue={0} onChange={(e) => setStatus(e.target.value)}>
                            <Space>
                                <Radio value={0} >ใช้งาน</Radio>
                                <Radio value={-1} >ไม่ใช้งาน</Radio>
                            </Space>
                        </Radio.Group>
                    </Col>
                    <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end'}} >
                        <Button  icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30}}>
                            ค้นหา
                        </Button>
					</Col>
                </Row>
                    {/* <Row style={{
                        paddingTop: "10px",
                    }}>
                        <Col span={5} />
                        <Col span={5}>
                            <Radio.Group name="status" defaultValue={0}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <Space>
                                    <Radio value={0} >ใช้งาน</Radio>
                                    <Radio value={-1} >ไม่ใช้งาน</Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row style={{
                        paddingTop: "10px",
                    }}>
                        <Col span={5} />
                        <Col span={5}>
                            <Button type="primary" icon={<SearchOutlined />} onClick={() => search()}>
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Form.Item> */}
            
            </Form>
        </Section>

        <Section style={{ marginTop: 30, paddingTop: 30 }}>
            <Table
                pagination={{
                    pageSize: 10,
                    onChange(current) {
                        setPage(current);
                    },
                    showSizeChanger:false
                }}
                rowKey={(item: Customer) => item.id}
                columns={columns}
                dataSource={customers}
                size="small" />

        </Section>
        </div>
    )
}

export default ListCustomer;
