import { createRoot } from 'react-dom/client';
import App from "src/App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store";
import { ConfigProvider } from 'antd';
import "./index.css";
import dayjs from 'dayjs';

dayjs.locale("th");


const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<>
  <ReduxProvider store={store}>
    <ConfigProvider
        componentSize="large"
        locale={{ locale: "th"}}
        theme={{ token: {
          colorPrimaryText: "#0e6ac7",
          colorPrimaryTextActive: "#0e6ac7",
          colorPrimary: "#1589FF", //"#4338CA",
          colorLink: "#1589FF", //"#4338CA",
          colorLinkActive: "#1589FF", //"#4338CA",
          colorLinkHover: "#1589FF", //"#4338CA",
          borderRadius: 5,
          fontFamily: 'Anuphan'}}}>
        <App />
    </ConfigProvider>
        {/* <TestLayout /> */}
  </ReduxProvider>
</>);

// ReactDOM.render(
//   <>
//     <ReduxProvider store={store}>
//       <ConfigProvider
//           componentSize="large"
//           theme={{ token: {
//             colorPrimaryText: "#0e6ac7",
//             colorPrimaryTextActive: "#0e6ac7",
//             colorPrimary: "#1589FF", //"#4338CA",
//             colorLink: "#1589FF", //"#4338CA",
//             colorLinkActive: "#1589FF", //"#4338CA",
//             colorLinkHover: "#1589FF", //"#4338CA",
//             borderRadius: 5,
//             fontFamily: 'Anuphan'}}}>
//           <App />
//       </ConfigProvider>
//           {/* <TestLayout /> */}
//     </ReduxProvider>
//   </>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
