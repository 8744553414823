import { Col, Drawer, message, Table } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import filepriceZonePricelistServices from 'src/services/filepriceZonePricelistServices';
import ZonePricelistDrawer from '../drawer/ZonePricelistDrawer';

interface IProps {
    destZoneCode: string;
    filepriceId: any;
    fileCode: string;
    productTypeCode: string;
    regZoneCode: string;
    isEdit: boolean;
    setIsEditing: any;
}

const DestZonePriceTab: React.FC<IProps> = (props) => {
    const { destZoneCode, regZoneCode, filepriceId, fileCode, productTypeCode, isEdit, setIsEditing } = props;
    const [isLoad, setLoad] = useState(false);
    const [isLoadingStatus, setLoadingStatus] = useState(false);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [selectZonePrice, setSelectZonePrice] = useState<any>();
    const [zonePricelists, setZonePricelists] = useState<any[]>([]);
    const [temZonePricelists, setTemZonePricelists] = useState<any[]>([]);

    const columnSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);
    const zonePriceColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "ขนาด", dataIndex: "sizeCode", key: "sizeCode",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.sizeCode, b.sizeCode),
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'center' }}>
                    {!isEdit ? //item.priceAmt != null && item.priceAmt > 0 ?
                        <span>{value}</span>
                        :
                        <a onClick={() => handleEditZoneFileprice(item)}>{value}</a>
                    }
                </Col>
            )
        },
        {
            title: "ราคา", dataIndex: "priceAmt", key: "priceAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "การคำนวน", dataIndex: "typeCal", key: "typeCal",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคา FACTOR", dataIndex: "priceFactor", key: "priceFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคารับสินค้า", dataIndex: "priceSrcReceiveAmt", key: "priceSrcReceiveAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "รับสินค้า FACTOR", dataIndex: "priceSrcReceiveFactor", key: "priceSrcReceiveFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคากระจายสินค้า", dataIndex: "priceSmcAmt", key: "priceSmcAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "กระจายสินค้า FACTOR", dataIndex: "priceSmcFactor", key: "priceSmcFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคารับสินค้าเอง", dataIndex: "priceReceiveAmt", key: "priceReceiveAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "รับสินค้าเอง FACTOR", dataIndex: "priceReceiveFactor", key: "priceReceiveFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
    ];

    async function handleSearchZonePricelist() {
        const res = await filepriceZonePricelistServices.getZonePricelistAllsizing(filepriceId, productTypeCode, regZoneCode, destZoneCode);
        if (res.status === 200) {
            setTemZonePricelists(res.data)
        } else {
            message.error('Error status : ' + res.status + ' ' + res.statusText);
        }
        setLoadingStatus(false);
    }

    useEffect(() => {
        if (!isLoad) {
            setLoadingStatus(true);
            handleSearchZonePricelist();
            setLoad(true)
        }
    }, []);

    useEffect(() => {
        if (temZonePricelists.length > 0) {
            setZonePricelists(temZonePricelists.filter(item => item.id))
            console.log("zone price list : ", temZonePricelists.filter(item => item.id));
        }
    }, [temZonePricelists]);

    const onCloseDrawerWithRefresh = (val: boolean) => {
        setIsShowDrawer(false);
        setSelectZonePrice({})
        if (val === true) {
            handleSearchZonePricelist();
        }
    }

    function handleEditZoneFileprice(item: any) {
        setSelectZonePrice(item);
        setIsShowDrawer(true)
    }

    return (
        <div>
            <Table
                size='small'
                loading={isLoadingStatus}
                dataSource={isEdit ? temZonePricelists : zonePricelists}
                columns={zonePriceColumns}
                rowKey={(item: any) => item.sizeCode}
                pagination={false}
            />
            <Drawer
                title="แฟ้ม"
                width="500px"
                onClose={() => onCloseDrawerWithRefresh(false)}
                open={isShowDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <ZonePricelistDrawer
                    item={{
                        fileCode: fileCode,
                        destZoneCode: destZoneCode,
                        regZoneCode: regZoneCode,
                        filepriceId: filepriceId,
                        productTypeCode: productTypeCode,
                    }}
                    zonePrice={selectZonePrice}
                    saveComplete={() => onCloseDrawerWithRefresh(true)}
                    onCloseDrawer={() => onCloseDrawerWithRefresh(false)}
                />
            </Drawer>
        </div>
    )
}

export default DestZonePriceTab
