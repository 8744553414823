import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { StationArea } from '../../domain/stationAreaType';
import { Link } from 'react-router-dom';
import stationAreaServices from 'src/services/stationAreaServices';
import Section from '../../components/container/Section';

const ListStationArea = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [stationAreas, setStationAreas] = useState<StationArea[]>([]);
    const [searchName, setSearchName] = useState<any>("");
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            render: (value: number, item: StationArea, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "สาขา NIMSHOP",
            dataIndex: "stationName",
            key: "stationName",
            render: (value: string, item: StationArea, index: number) => <Link to={`/app/stationarea/edit/${item.stationCode}`}>{value}</Link>,
        },
        {
            title: "จำนวน Location",
            dataIndex: "locationCount",
            key: "locationCount",
        },

    ];

    useEffect(() => {
        stationAreaServices.getAll()
            .then((res) => {
                console.log(res.data)
                setStationAreas(res.data)
            })
    }, []);

    const search = async () => {
       await stationAreaServices.getByStationNameCount(searchName)
            .then((res) => {
                setStationAreas(res.data)
            })
    }

    return (
			<div>
				<PageHeader title="กำหนดข้อมูล Station Area" subTitle="เพิ่ม แก้ไข เปลี่ยนแปลงข้อมูล Station Area">
					<Button
						size="large"
						style={{ width: 120 }}
						type="primary"
						icon={<PlusCircleOutlined />}
						onClick={() => {
							history.push("/app/stationarea/add");
						}}
					>
						เพิ่ม
					</Button>
				</PageHeader>

				{/* <PageHeader title="กำหนดข้อมูล Station Area">
                <Space>
                    <Button
                        size="small"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/stationarea/add") }}
                    >เพิ่ม
                    </Button>
                </Space>
            </PageHeader> */}
				<Section>
					<Form layout="vertical">
						<Row>
							<Col span={12}>
								<Form.Item label="ชื่อ">
									<Input
										style={{
											borderRadius: "5px",
											width: "300px",
										}}
										name="searchName"
										onChange={(e) => setSearchName(e.target.value)}
									/>
								</Form.Item>
							</Col>
							<Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
								<Button icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30 }}>
									ค้นหา
								</Button>
							</Col>
						</Row>
					</Form>
				</Section>

                <Section style={{ marginTop: 30}}>
					<Table
						pagination={{
							pageSize: 10,
							onChange(current) {
								setPage(current);
							},
						}}
						rowKey={(item: StationArea) => item.id}
						columns={columns}
						dataSource={stationAreas}
						size="small"
					/>
				</Section>

			</div>
		);
}

export default ListStationArea;
