import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, message, Row, Space, Typography } from 'antd';
import locale from 'antd/es/date-picker/locale/th_TH';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filepriceServices from 'src/services/filepriceServices';
import { SessionUser } from 'src/store/authen/types';
import { formatDate } from 'src/utils/dateUtils';

interface IProps {
    fileprice: any;
    saveComplete?: any;
    onCloseDrawer?: any;
}

const FilepriceDrawer: React.FC<IProps> = (props) => {
    const { fileprice, saveComplete, onCloseDrawer } = props;
    const [isAddMode, setIsAddMode] = useState(true);
    const id = fileprice != undefined ? fileprice.id : 0;
    const [name, setName] = useState(fileprice ? fileprice.fileName : undefined);
    const [code, setCode] = useState(fileprice ? fileprice.fileCode : undefined);
    const [sDate, setSDate] = useState(fileprice && fileprice.startDate ? dayjs(fileprice.startDate) : undefined);
    const [eDate, setEDate] = useState(fileprice && fileprice.endDate ? dayjs(fileprice.endDate) : undefined);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    useEffect(() => {
        if (fileprice !== undefined) {
            setIsAddMode(false);
        }
    }, [fileprice]);

    const handleSave = async () => {
        if (!isValid()) {
            return;
        }
        const data = {
            id: id,
            fileCode: code,
            fileName: name,
            startDate: sDate ? formatDate(sDate) : formatDate(new Date()),
            endDate: eDate ? formatDate(eDate) : undefined,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username,
            savStation: sessionUser.stationCode,
        }
        await onSave(data);
    }

    const isValid = () => {
        if (!code) {
            message.warning('กรุณาระบุรหัสแฟ้ม');
            return false;
        }
        if (!name) {
            message.warning('กรุณาระบุชื่อแฟ้ม');
            return false;
        }
        if (!sDate) {
            message.warning('กรุณาระบุวันที่เริ่มใช้');
            return false;
        }
        if (!eDate) {
            message.warning('กรุณาระบุวันที่สิ้นสุด');
            return false;
        }
        return true;
    }

    async function onSave(data: any) {
        if (isAddMode) {
            try {
                await filepriceServices.addFileprice(data);
                console.log('addFileprice', data)
                saveComplete();
            } catch (e) {
                console.log('addFileprice filed')
                message.error('บันทึกข้อมูล ไม่สำเร็จ');
            }


        } else {
            try {
                await filepriceServices.updateFileprice(data);
                console.log('updateFileprice', data)
                saveComplete();
            } catch (e) {
                console.log('updateFileprice filed')
                message.error('อัพเดทข้อมูล ไม่สำเร็จ');
            }
        }
    }

    return (
        <>
            <Row gutter={[4, 32]} style={{ alignItems: 'baseline' }}>
                <Col span={6} style={{ textAlign: "right" }} >
                    <Typography.Text>เลขที่อ้างอิง :</Typography.Text>
                </Col>
                <Col span={16}>
                    <Input disabled value={fileprice != undefined ? fileprice.id : undefined} />
                </Col>
                <Col span={6} style={{ textAlign: "right" }} >
                    <Typography.Text>รหัสแฟ้ม :</Typography.Text>
                </Col>
                <Col span={16}>
                    <Input disabled={!isAddMode} defaultValue={code} onChange={(val) => setCode(val.target.value)} />
                </Col>
                <Col span={6} style={{ textAlign: "right" }} >
                    <Typography.Text>ชื่อแฟ้ม :</Typography.Text>
                </Col>
                <Col span={16}>
                    <Input defaultValue={name} onChange={(val) => setName(val.target.value)} />
                </Col>
                <Col span={6} style={{ textAlign: "right" }} >
                    <Typography.Text>วันที่เริ่มใช้ :</Typography.Text>
                </Col>
                <Col span={16}>
                    <DatePicker onChange={(value) => setSDate(value != undefined ? dayjs(value) : undefined)}
                        disabled={!isAddMode} style={{ display: 'flex' }} locale={locale} format='D/M/YYYY'
                        defaultValue={sDate}
                    />
                </Col>
                <Col span={6} style={{ textAlign: "right" }} >
                    <Typography.Text>วันที่สิ้นสุด :</Typography.Text>
                </Col>
                <Col span={16}>
                    <DatePicker onChange={(value) => setEDate(value != undefined ? dayjs(value) : undefined)}
                        style={{ display: 'flex' }} locale={locale} format="D/M/YYYY"
                        defaultValue={eDate}
                    />
                </Col>
            </Row>
            <br />
            <br />
            <Space size="large" style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={handleSave}
                    style={{ width: 120 }}
                >
                    บันทึก
                </Button>
                <Button
                    icon={<CloseCircleOutlined />}
                    onClick={onCloseDrawer}
                    style={{ width: 120 }}
                >
                    กลับ
                </Button>
            </Space >
        </>
    )
}

export default FilepriceDrawer
