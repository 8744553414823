import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Select, Row, Space, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FleetStation } from 'src/domain/fleetStation';
import stationServices  from 'src/services/stationServices';
import { Station } from 'src/domain/stationType';
import fleetStationServices from 'src/services/fleetStationServices';
import Section from "../../components/container/Section";

const statusOptions = [
    {
      value: '1',
      label: 'ทั้งหมด',
    },
    {
      value: '0',
      label: 'ใช้งาน',
    },
    {
      value: '-1',
      label: 'ยกเลิกใช้งาน',
    },
  ];

const ListFleetStation = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [list, setList] = useState<FleetStation[]>([]);
    const [stations , setStations] = useState<Station[]>([]);
    const [stationCode , setStationCode] = useState(String);
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(1);
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            render: (value: number, item: FleetStation, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัสรถ",
            dataIndex: "truckCode",
            key: "truckCode",
        },
        {
            title: "ทะเบียนรถ",
            dataIndex: "registId",
            key: "registId",
            render: (value: string, item: FleetStation, index: number) => <Link to={`/app/fleetstation/edit/${item.truckCode}`}>{value}</Link>,
        },
        {
            title: "พนักงานขับรถ",
            dataIndex: "driverName",
            key: "driverName",
        },
        {
            title: "สาขา",
            dataIndex: "stationName",
            key: "stationName",
        },
        {
            title: "สถานะ",
            dataIndex: "useFlg",
            key: "useFlg",
            render: (value: string, item: FleetStation, index: number) => 
            <>
                {item.useFlg === 0? "ใช้งาน": "ยกเลิกใช้งาน"}
            </>
            ,
        },
    ];

    useEffect(() => {
        loadStation();
        onSearch();
    }, []);

    const loadStation = async () => {
        await stationServices.getAll().then((res) => {
            setStations(res.data);
        });
    }

    const onSearch = async () => {
        let params = {
            truckUId : "",
            stationCode : stationCode,
            useFlg : status,
            truckRegistId : searchName
        }
        let resData: FleetStation[] = await fleetStationServices.getFleetStationByParam(params);
        setList(resData);
    }

    const onChangeStatus = (selectStatus : any) => {
       setStatus(selectStatus);
    };

    const onChangeStation = (stationcode : any) => {
        console.log(" temstation id ", stationcode);
        let tempStation = stations.filter((item) => item.stationCode == stationcode);
        console.log(" temstation ", tempStation);
        if (tempStation !== null) {
            setStationCode(tempStation[0]?.stationCode);
        }
        
    }

    return (
        <div>
            <PageHeader title="กำหนดสาขาให้รถ">
                <Space>
                    <Button
                        size="large"
                        style={{ width: 120 }}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/fleetstation/add") }}
                    >เพิ่ม
                    </Button>
                </Space>
            </PageHeader>
            <div>
                <Form>
                    <Section style={{ display: "flex", flexDirection: "column" }}>
                        <Row gutter={[20, 10]}>
                            <Col span={8}>
                                <Form.Item label="ทะเบียนรถ">
                                    <Input name="code" onChange={(e) => setSearchName(e.target.value)}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="สาขา">
                                    <Select
                                        onChange={onChangeStation}
                                        defaultValue={0}
                                        >
                                        <Select.Option key={0} value={0}>
                                            ทั้งหมด
                                        </Select.Option>
                                        {stations.map((item) =>
                                            <Select.Option value={item.stationCode} key={item.stationCode}
                                            >
                                            {item.stationName}
                                            </Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="สถานะ">
                                    <Select
                                        defaultValue={"1"}
                                        onChange={onChangeStatus}
                                        options={statusOptions}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    icon={<SearchOutlined />}
                                    onClick={() => onSearch()}
                                    style={{ width: 100}}
                                >
                                    ค้นหา
                                </Button>
                            </Col>
                        </Row>
                    </Section>
                </Form>
            </div>
            <Section style={{ marginTop: 30}}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                            setPage(current);
                        },
                        showSizeChanger:false
                    }}
                    rowKey={(item: FleetStation) => item.id}
                    columns={columns}
                    dataSource={list}
                    size="small" />

            </Section>
        </div>
    )
}

export default ListFleetStation;

