import { Modal, Input, Table, Layout, message, Button ,Row, Col} from 'antd';
import React, { useEffect, useState } from 'react'
import { Station } from 'src/domain/stationType';
import stationServices from 'src/services/stationServices';
import { showMessage } from 'src/utils';
import { SaveOutlined } from '@ant-design/icons';
import { SessionUser } from 'src/store/authen/types';
import { useSelector } from 'react-redux';
import { FleetDrv, FleetDrvStation } from 'src/domain/fleetDrvType';
import fleetDrvStationService from 'src/services/fleetDrvStationService';


interface StationCheckModalProps {
    isModalOpen: boolean;
    onOk: any;
    onCancel: any;
    // onRowDoubleClick: any;
    driverUId: any;
}

const StationCheckModal = (props: StationCheckModalProps) => {

    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const [driverUId, setDriverUId] = useState<string>()

    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [selects, setSelects] = useState<Station[]>([]);
    const [fleetDrv,setFleetDriver] = useState<FleetDrv>()


    // const   
    const { Content } = Layout;
    const [stations, setStations] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [page, setPage] = React.useState(1);

    const stationcolumns = [
        {
            title: "",
            key: "stationCode",
            render: (value: number, item: Station, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "สาขา",
            dataIndex: "stationName",
            key: "stationName",
        },
    ];

    useEffect(() => {
        console.log('on modal');
        setDriverUId(props.driverUId)

        stationServices.getAll()
            .then((res) => {
                console.log(res.data);
                setStations(res.data);
            })
    }, []);

    useEffect(() => {
        setIsModalOpen(props.isModalOpen)
    }, [props.isModalOpen])

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows:Station[]) => {
            console.log("rowSelection ",selectedRows,selectedRowKeys)
            setSelects(selectedRows);
        },
    };



    const changeSearchText = async (searchText: any) => {
        stationServices.getByParam("", searchText, "", "", 0)
            .then((res) => {
                setStations(res.data)
            })
    }

    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const saveStation = async () => {
        console.log("rowSelection onfinist ", selects)

        if (selects.length > 0) {
         
            let fleetStationEntity: FleetDrv = {
                id: 0,
                driverCode: driverUId === undefined?"": driverUId,
                driverName: "",
                stations : selects,
                useFlg: 0,
                savStation: sessionUser.stationCode,
                createUserid: sessionUser.userId,
                createUsername: sessionUser.username
              };
            setFleetDriver(fleetStationEntity);
            try {
                console.log("fleetDrv >> ",fleetDrv)
                await fleetDrvStationService.addFleetDrvStation(fleetStationEntity).then(res => {
                    // console.log("adddrv",res);
                    if(res.data){
                        message.success("save success", 1.5);
                        handleCancel();
                        // setIsModalOpen(false);
                    }else{
                        message.error(res.data.message, 1.5);
                    }
                    
                }).catch(err => {
                    showMessage(err);
                    console.log("error1",err);
                });
            } catch (err: any) {
                console.log("error2",err);
                showMessage(err);
            }
        }else{
            console.log("else")
            message.error("ไม่ได้เลือกข้อมูล", 1.5);
        }
    }


        return (
            <>

                <Modal
                    title="ข้อมูลสาขา"
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                   // width={400}
                    style={{ top: 30 ,height: 450 ,width : 400 }}
                >
                    <div>
                        <Input onChange={(e) => changeSearchText(e.target.value)} />

                        <Content className="app-page-content">
                            <Row >
                                <Col span={24}>
                                <Table
                                rowSelection={{
                                    type: selectionType,
                                    ...rowSelection,
                                }}
                                scroll={{ y: 300 }}
                                // pagination={{
                                //     pageSize: 10,
                                //     onChange(current) {
                                //         setPage(current);
                                //     }
                                // }}
                                pagination={false} 
                                rowKey={(item: Station) => item.id}
                                columns={stationcolumns}
                                dataSource={stations}
                                size="small"
                                style ={{height : 400 }}
                            />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                            <div style={{display:"flex", justifyContent:"flex-end"}}>
                            <Button
                                size="middle"
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() =>  saveStation()}
                            >
                                บันทึก
                            </Button>
                            </div>
                            </Col>
                            </Row>
                        </Content>
                    </div>
                </Modal>
            </>
        )
    }

    export default StationCheckModal;