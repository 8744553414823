import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import FormPrename from './FormPrename';
import { RouteComponentProps, useHistory } from 'react-router';

interface IParams {
    id: string;
  }

const EditPrename:React.FC<RouteComponentProps<IParams>> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();
    const id = Number(props.match.params.id);

    return (
        <>
           <PageHeader title="แก้ไขคำนำหน้าชื่อ">
               <Space>
           <Button
              // danger
              size="large"
                type="primary"
               icon={<SaveOutlined />}
               onClick={() => setTrigger(true)}
            >
              แก้ไข
            </Button>
            <Button
              size="large"
              // type="primary"
              icon={<CloseCircleOutlined />}
               onClick={() => history.goBack()}
            >
              กลับ
            </Button>
          </Space>

               </PageHeader>  
               <Content>
          <Row  >
            <Col span={24}>
                <FormPrename id={id} trigger={trigger} setTrigger={setTrigger} />
            </Col>
          </Row>
          </Content>    
        </>
    )
}

export default EditPrename;