import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Dashboard from "./Dashboard";

// NIMSHOP
import NimStationList from "./nimstation/NimStationList";
import AddNimStation from "./nimstation/AddNimStation";
import EditNimStation from "./nimstation/EditNimStation";

// station line
import ShopLocationList from "./stationline/ListStationLine";
import AddShopLocation from "./stationline/AddStationLine";
import EditShopLocation from "./stationline/EditStationLine";

//StationArea
import ListStationArea from "./stationarea/ListStationArea";
import AddStationArea from "./stationarea/AddStationArea";
import EditStationArea from "./stationarea/EditStationArea";

//prename
import ListPrename from "./prename/ListPrename";
import AddPrename from "./prename/AddPrename";
import EditPrename from "./prename/EditPrename";

//Account Bank
import ListAccountBank from "./accountbank/ListAccountBank";
import AddAccountBank from "./accountbank/AddAccountBank";
import EditAccountBank from "./accountbank/EditAccountBank";

//Bank
import ListBank from "./bank/ListBank";
import AddBank from "./bank/AddBank";
import EditBank from "./bank/EditBank";

//Class and ClassDetail
import ListClass from "./classanddetail/ListClass";
import AddClass from "./classanddetail/AddClass";
import EditClass from "./classanddetail/EditClass";
import FormClassAndDetail from "./classanddetail/FormClassAndDetail";

//Customer
import AddCustomer from "./customer/AddCustomer";
import EditCustomer  from "./customer/EditCustomer";
import ListCustomer from "./customer/ListCustomer";
import TestOrder from "./template/TestOrder";
import ListFranchisee from "./franchisee/ListFranchisee";
import AddFranchisee from "./franchisee/AddFranchisee";
import EditFranchisee from "./franchisee/EditFranchisee";

//fleetstation
import ListFleetStation from "./fleetstation/ListFleetStation";
import AddFleetStation from "./fleetstation/AddFleetStation";
import EditFleetStation from "./fleetstation/EditFleetStation";

//bookingTruck
import ListBookingTruck from "./bookingtruck/ListBookingTruck";
import AddBookingTruck from "./bookingtruck/AddBookingTruck";
import EditBookingTruck from "./bookingtruck/EditBookingTruck";
import ViewBookingTruck from "./bookingtruck/ViewBookingTruck";
import AuthGuard from "../components/container/AuthGuard";
import ReportSmcRelease from "./reportsmc/ReportSmcRelease";

//report
import SummaryCustomerReceive from "./report/SummaryCustomerReceive";
import SummaryDelivered from "./report/SummaryDelivered";
import FleetDriverList from "./fleetstation/driver/FleetDriverList";
import FleetDriverInfo from "./fleetstation/driver/FleetDriverInfo";
import FleetDriverForm from "./fleetstation/driver/FleetDriverForm";
import FleetDriverAdd from "./fleetstation/driver/FleetDriverAdd";
import FleetTruckList from "./fleetstation/truck/FleetTruckList";
import FleetTruckAdd from "./fleetstation/truck/FleetTruckAdd";
import FleetTruckForm from "./fleetstation/truck/FleetTruckForm";

//fileprice
import FilepriceList from "./fileprice/FilepriceList"
import FilepriceView from "./fileprice/FilepriceView"
import ImportFilepriceArea from "./fileprice/tab/ImportFilepriceArea"
import ImportFilepriceSize from "./fileprice/tab/ImportFilepriceSize"
import ImportFilepriceValue from "./fileprice/tab/ImportFilepriceValue"

const AppRoute: React.FC<RouteComponentProps> = (props) => {
  // console.log(props);
  return (
    <AuthGuard>
    <AppLayout>
      <Switch>
        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        {/*  station NIMSHOP */}
        <Route exact path="/app/station" component={NimStationList} />
        <Route exact path="/app/station/add" component={AddNimStation} />
        <Route exact path="/app/station/edit/:id" component={EditNimStation} />

         {/*  station  line  สายกระจาย */}
         <Route exact path="/app/stationline" component={ShopLocationList} />
        <Route exact path="/app/stationline/add" component={AddShopLocation} />
        <Route exact path="/app/stationline/edit/:id" component={EditShopLocation} />

        {/* route (station area) route */}
        <Route exact path="/app/stationarea" component={ListStationArea} />
        <Route exact path="/app/stationarea/add" component={AddStationArea} />
        <Route exact path="/app/stationarea/edit/:stationcode" component={EditStationArea} />
        <Route exact path="/app/stationarea/edititem/:id" component={EditStationArea} />

        {/* ข้อมูลหลัก */}
        <Route exact path="/app/class" component={ListClass} />
        <Route exact path="/app/class/add" component={AddClass} />
        <Route exact path="/app/class/edit/:id" component={EditClass} />
        <Route exact path="/app/class/main" component={FormClassAndDetail} />

        {/* //prename */}
        <Route exact path="/app/prename" component={ListPrename} />
        <Route exact path="/app/prename/add" component={AddPrename} />
        <Route exact path="/app/prename/edit/:id" component={EditPrename} />

        {/* bank */}
        <Route exact path="/app/bank" component={ListBank} />
        <Route exact path="/app/bank/add" component={AddBank} />
        <Route exact path="/app/bank/edit/:id" component={EditBank} />

        {/* account bank */}
        <Route exact path="/app/accountbank" component={ListAccountBank} />
        <Route exact path="/app/accountbank/add" component={AddAccountBank} />
        <Route exact path="/app/accountbank/edit/:id" component={EditAccountBank} />

        {/* Customer  */}
        <Route exact path="/app/customer" component={ListCustomer} />
        <Route exact path="/app/customer/add" component={AddCustomer} />
        <Route exact path="/app/customer/edit/:id" component={EditCustomer} />

        {/* Franchisee  */}
        <Route exact path="/app/franchisee" component={ListFranchisee} />
        <Route exact path="/app/franchisee/add" component={AddFranchisee} />
        <Route exact path="/app/franchisee/edit/:id" component={EditFranchisee} />


        {/* FleetStation  */}
        <Route exact path="/app/fleetstation" component={ListFleetStation} />
        <Route exact path="/app/fleetstation/add" component={AddFleetStation} />
        <Route exact path="/app/fleetstation/edit/:id" component={EditFleetStation} />

        <Route exact path="/app/fleetstation/driver" component={FleetDriverList} />
        <Route exact path="/app/fleetstation/driver/add" component={FleetDriverAdd} />
        <Route exact path="/app/fleetstation/driver/edit/:driverUId" component={FleetDriverForm} />


        <Route exact path="/app/fleetstation/truck" component={FleetTruckList} />
        <Route exact path="/app/fleetstation/truck/add" component={FleetTruckAdd} />
        <Route exact path="/app/fleetstation/truck/edit/:truckUId" component={FleetTruckForm} />

        <Route exact path="/app/bookingtruck" component={ListBookingTruck} />
        <Route exact path="/app/bookingtruck/add" component={AddBookingTruck} />
        <Route exact path="/app/bookingtruck/edit/:id" component={EditBookingTruck} />
        <Route exact path="/app/bookingtruck/view/:id" component={ViewBookingTruck} />

        {/* report  */}
        <Route exact path="/app/report/summaryCustomerReceive" component={SummaryCustomerReceive} />
        <Route exact path="/app/report/summaryDelivered" component={SummaryDelivered} />

        <Route exact path="/app/testorder" component={TestOrder} />

        {/*report*/}
        <Route exact path="/app/reportsmc" component={ReportSmcRelease} />

        {/* fileprice */}
        <Route exact path="/app/fileprice" component={FilepriceList} />
        {/* <Route exact path="/app/fileprice/add" component={AddNimStation} /> */}
        <Route exact path="/app/fileprice/view/:id" component={FilepriceView} />
        <Route exact path="/app/fileprice/import/area/:id" component={ImportFilepriceArea} />
        <Route exact path="/app/fileprice/import/size/:id" component={ImportFilepriceSize} />
        <Route exact path="/app/fileprice/import/value/:id" component={ImportFilepriceValue} />

        {/* other */}
        <Redirect path="/app" to="/app/dashboard" />



      </Switch>
    </AppLayout>
    </AuthGuard>
  );
};

export default AppRoute;
