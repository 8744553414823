import { Button, Table, } from 'antd';
import React, { useEffect, useState } from 'react'
import Section from 'src/components/container/Section';
import { PlusOutlined  } from '@ant-design/icons';
import { FleetDrvStation } from 'src/domain/fleetDrvType';
import fleetDrvStationService from 'src/services/fleetDrvStationService';
import StationCheckModal from './modal/StationCheckModal';
import { Station } from 'src/domain/stationType';
import { SessionUser } from 'src/store/authen/types';
import { useSelector } from 'react-redux';
import { showMessage } from 'src/utils';


interface IProps {
  driverUId: string;
  trigger: boolean;
  setTrigger: any;
}

const FleetDriverStationInfo: React.FC<IProps> = (props) => {

  const { driverUId, trigger, setTrigger } = props;
  const [page, setPage] = React.useState(1);
  const [fleetDrvStations, setFleetDrvStations] = useState<FleetDrvStation[]>([]);

  const [isStationModalOpen, setIsStationModalOpen] = useState<boolean>(false);
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [fleetStationList, setFleetStationList] = useState<FleetDrvStation[]>([]);
  const [selectDriverUId, setSelectDriverUId] = useState(String);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      render: (value: number, item: FleetDrvStation, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "สาขา",
      dataIndex: "stationCode",
      key: "stationCode",
    },
    {
      title: "ชื่อสาขา",
      dataIndex: "stationName",
      key: "stationName",
    },
    {
      title: "สถานะ",
      dataIndex: "useFlg",
      key: "useFlg",
      render: (value: string, item: FleetDrvStation, index: number) => 
      <>
          {item.useFlg === 0? "ใช้งาน": "ยกเลิกใช้งาน"}
      </>
    },
  ];

  useEffect(() => {

    // console.log("driverUId >>",driverUId)
    fleetDrvStationService.getbydriverCode(driverUId)
    .then((res) => {
        setFleetDrvStations(res.data)
        console.log(res.data)
    })
 }, []);

 useEffect(() => {

  console.log("driverUId >>",driverUId)
  fleetDrvStationService.getbydriverCode(driverUId)
  .then((res) => {
      setFleetDrvStations(res.data)
      console.log(res.data)
  })
}, [isStationModalOpen]);
  
 

const showModal = () => {
  console.log(' driverUId ++++', driverUId);
  setIsStationModalOpen(true);
};

  return (
    <>
      {/* <Section>
        <Button
          size="large"
          type="primary"
          icon={<SaveOutlined />}
        //  onClick={() => setTrigger(true)}
        >
          บันทึก
        </Button>

      </Section> */}

      <Section>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
      <Button 
          size="middle"
          type="primary"
          icon={<PlusOutlined  />}
           onClick={() => showModal()}
        >
          เพิ่ม
        </Button>
        </div>
        <Table 
          style={{ marginTop: 20 }}
          pagination={{
            pageSize: 10,
            onChange(current) {
              setPage(current);
            },
            showSizeChanger: false
          }}
          rowKey={(item: FleetDrvStation) => item.id}
          columns={columns}
          dataSource={fleetDrvStations}
          size="small" />

      </Section>
      <StationCheckModal
          isModalOpen={isStationModalOpen}
          onOk={null}
          onCancel={() => { setIsStationModalOpen(false) }}
          // onRowDoubleClick={(data: Station, index: number, evt: any) => {
          //   console.log("onRowDoubleClick: ", data, index, evt);
          //   onDoubleClickStation(data);
          // }}
          driverUId= {driverUId}
        />
    </>
  )
}

export default FleetDriverStationInfo