import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, {useState } from 'react'
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import { useHistory } from 'react-router';
import Form from './FormStationArea'


const Add = () => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();

    return (
			<>
				<PageHeader title="เพิ่ม STATION AREA" subTitle="ระบุรายละเอียด และ ขอบเขตการบริการของสาขา">
					<Space>
						{/* <Button type="primary" icon={<SaveOutlined />} onClick={() => setTrigger(true)}>
							บันทึก
						</Button> */}
						<Button icon={<CloseCircleOutlined />} onClick={() => history.goBack()}>
							กลับ
						</Button>
					</Space>
				</PageHeader>
				{/* <PageHeader title="เพิ่ม STATION AREA" >
          <Space>
            <Button
              size="small"
              icon={<CloseCircleOutlined />}
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Space>
        </PageHeader> */}

				<Form stationcode={""} trigger={trigger} setTrigger={setTrigger} />
			</>
		);
}

export default Add;
