import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Fleet } from 'src/domain/fleetStation';
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader';
import { Button, Space,Form, Row, Col,Input, Table, Select } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Section from 'src/components/container/Section';
import fleetStationServices from 'src/services/fleetStationServices';

const statusOptions = [
    {
      value: '1',
      label: 'ทั้งหมด',
    },
    {
      value: '0',
      label: 'ใช้งาน',
    },
    {
      value: '-1',
      label: 'ยกเลิกใช้งาน',
    },
  ];

const FleetTruckList = () => {

    const history = useHistory();
    const [searchName, setSearchName] = useState<any>("");
    const [status,setStatus] = useState(String);
    const [list, setList] = useState<Fleet[]>([]);
    const [page, setPage] = React.useState(1);

    const columns:any[] = [
        {
            title: "ลำดับ",
            key: "index",
             render: (value: number, item: Fleet , index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัสรถ",
            dataIndex: "truckCode",
            key: "truckCode",
        },
        {
            title: "ทะเบียนรถ",
            dataIndex: "registId",
            key: "registId",
            sorter: (a:any, b:any) => a.registId.localeCompare(b.registId),
            defaultSortOrder: 'ascend',
            render: (value: string, item: Fleet, index: number) => <Link to={`/app/fleetstation/truck/edit/${item.truckCode!}`}>{value}</Link>,
        },
        {
            title: "ชื่อพนักงานขับรถ",
            dataIndex: "driverName",
            key: "driverName",
            // sorter: (a, b) => a.age - b.age,
           
        },
       
        {
            title: "สถานะ",
            dataIndex: "useFlg",
            key: "useFlg",
            render: (value: string, item: Fleet, index: number) => 
            <>
                {item.useFlg === 0? "ใช้งาน": "ยกเลิกใช้งาน"}
            </>
            ,
        },
    ];

    useEffect(() => {
        // loadStation();
        onSearch();
    }, []);


    const onSearch = async () => {
        let params = {
            // stationCode : stationCode,
            useFlg : status,
            truckRegistId : searchName
        }
        let resData: Fleet[] = await fleetStationServices.getFleetStationByParam(params)
        setList(resData);
        console.log(resData);
    }


    const onChangeStatus = (selectStatus : any) => {
        setStatus(selectStatus);
     };
 


  return (
    <>
            <PageHeader title="ข้อมูลรถ">
                <Space>
                    <Button
                        size="large"
                        style={{ width: 120 }}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/fleetstation/truck/add") }}
                    >
                        เพิ่ม
                    </Button>
                    {/* <Button
                        size="large"
                        style={{ width: 120 }}
                        type="default"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/fleetstation/driver/edit/DV-44a769557dd8") }}
                    >
                        from
                    </Button> */}
                </Space>
            </PageHeader>
            <div>
                <Form 
                  layout="vertical"
                >
                    <Section style={{ display: "flex", flexDirection: "column"  }}>
                        <Row gutter={[20, 10]}>
                            <Col span={8}>
                                <Form.Item label="ทะเบียนรถ">
                                    <Input name="registId" onChange={(e) => setSearchName(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="สถานะ">
                                    <Select
                                        defaultValue={"1"}
                                        onChange={onChangeStatus}
                                        options={statusOptions}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={4}   style={{ display: 'flex', justifyContent: 'flex-end' ,alignItems:'center', marginTop: 10}}>
                                <Button
                                    icon={<SearchOutlined />}
                                     onClick={() => onSearch()}
                                    style={{ width: 100}}
                                >
                                    ค้นหา
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                           
                        </Row>
                    </Section>
                </Form>
            </div>
            <Section style={{ marginTop: 30}}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                          setPage(current);
                        },
                        showSizeChanger:false
                    }}
                    rowKey={(item: Fleet) => item.id}
                    columns={columns}
                    dataSource={list}
                    size="small" />

            </Section>
    
    </>
  )
}

export default FleetTruckList