import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, {useState } from 'react'
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import { useHistory } from 'react-router';
import FormBank from './FormBank'


const AddBank = () => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();

    return (
        <>
        <PageHeader title="เพิ่มข้อมูลธนาคาร" subTitle="กำหนดข้อมูลธนาคารแห่งประเทศไทย">
          <Space>
            <Button
              size="large"
                type="primary"
               icon={<SaveOutlined />}
               onClick={() => setTrigger(true)}
            >
              บันทึก
            </Button>
            <Button
              size="large"
              icon={<CloseCircleOutlined />}
              onClick={() => history.goBack()}
            >
              กลับ
            </Button>
          </Space>
        </PageHeader>
        <Content>
          <Row  >
            <Col span={24}>
                <FormBank id={0} trigger={trigger} setTrigger={setTrigger} />
            </Col>
          </Row>
        
        </Content>
      </>
    )
}

export default AddBank;
