import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'truckPartner';

const getAll = () => {
    return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (truckPartnerName: string, address: string, zipcode: string, truckPartnerTel: string, useFlg: number) => {
    const param = `truckPartnerName=${truckPartnerName}&address=${address}&zipcode=${zipcode}&truckPartnerTel=${truckPartnerTel}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);

};

// const addBookingTruck = (bookingTruck: any) => {
//     return http.post(url + "/add", bookingTruck);
// };

// const updateBookingTruck = (bookingTruck: any) => {
//     return http.put(url + "/update", bookingTruck);
// };


export default { getAll, getById, getByParam }
