import { blue } from '@ant-design/colors';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, MenuProps, Row, Space, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import filepriceZonePricelistServices from 'src/services/filepriceZonePricelistServices';
import productTypeServices from 'src/services/productTypeServices';
import zoneServices from 'src/services/zoneServices';
import { showMessage } from 'src/utils';
import DestZonePriceTab from './DestZonePriceTab';

interface IProps {
    filepriceId: any;
    fileCode: any;
    isEdit: boolean;
    setIsEditing: any;
}

const FilepriceValueView: React.FC<IProps> = (props) => {
    const { filepriceId, fileCode, isEdit, setIsEditing } = props;
    const [isLoad, setLoad] = useState(false);

    const [productTypeCode, setProductTypeCode] = useState('');
    const [productTypes, setProductTypes] = useState<any[]>([]);

    const [selectSrcZoneCode, setSelectSrcZoneCode] = useState('');
    const [srcZones, setSrcZones] = useState<any[]>([]);
    const [destZones, setDestZones] = useState<any[]>([]);
    const [notUsedDestZones, setNotUsedDestZones] = useState<any[]>([]);

    let items: MenuProps['items'] = notUsedDestZones.map((item: any) => {
        return {
            key: item.zoneCode,
            label: (
                <a onClick={() => { addDestZone(item) }}>
                    {`${item.zoneName} [${item.zoneCode}]`}
                </a>
            ),
        }
    })

    useEffect(() => {
        if (!isLoad) {
            handleSearchProductType('');
            handleSearchSrcZone();
            setLoad(true);
        }
    }, []);

    useEffect(() => {
        // if (isEdit === true) {
        //     // setTemSizings(sizings);
        // } else {
        //     // handleSearchProductType(selectProductType);
        // }

        if (!isEmpty(productTypeCode) && !isEmpty(selectSrcZoneCode)) {
            handleSearchDestZone(productTypeCode, selectSrcZoneCode)
            if (isEdit === true) {
                handleNotusedDestZone();
            }
        }
    }, [isEdit, productTypeCode, selectSrcZoneCode]);

    useEffect(() => {
        if (productTypes.length > 0) {
            setProductTypeCode(productTypes[0].productTypeCode);
        }
        if (srcZones.length > 0) {
            setSelectSrcZoneCode(srcZones[0].zoneCode);
        }
    }, [productTypes, srcZones]);

    useEffect(() => {
        if (isEdit === true) {
            handleNotusedDestZone();
        }
    }, [destZones]);

    function isEmpty(str: string) {
        return (!str || str.length === 0);
    }

    async function handleSearchProductType(val: string) {
        const res = await productTypeServices.getByParam('', '', 0)
        // console.log('sort', res.data)
        if (res.status === 200) {
            const sort = await res.data.sort(function (a: any, b: any) {
                let x = a.productTypeName.toLowerCase();
                let y = b.productTypeName.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            setProductTypes(await sort)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function handleSearchSrcZone() {
        const res = await zoneServices.getByAll();
        if (res.status === 200) {
            setSrcZones(await res.data)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function handleSearchDestZone(productCode: string, srcCode: string) {
        const res = await filepriceZonePricelistServices.getDestZoneByFilter(filepriceId, productCode, srcCode)
        if (res.status === 200) {
            setDestZones(res.data)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    function handleNotusedDestZone() {
        let zones = [...srcZones];
        destZones.forEach((item: any) => {
            zones.splice(zones.findIndex((a: any) => a.zoneCode === item.zoneCode), 1)
        })
        setNotUsedDestZones(zones);
    }

    function addDestZone(rec: any) {
        let isDuplicate = false;
        destZones.forEach((item: any) => {
            if (isDuplicate)
                return;
            if (item.zoneCode === rec.zoneCode)
                isDuplicate = true
        })
        if (!isDuplicate)
            setDestZones([...destZones, rec])
    }

    return (
        <>
            <Row>
                {/* product type */}
                <Col span={6}>
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Typography.Title level={5} style={{ margin: 0 }}>รายการ</Typography.Title>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Typography.Text>ประเภทสินค้า :</Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: 10 }}>
                            <Tabs tabPosition={'left'} size={'small'}
                                tabBarStyle={{ width: '100%', }}
                                onTabClick={(key: any) => {
                                    setProductTypeCode(key)
                                    if (isEdit) {
                                        setIsEditing(false);
                                    }
                                }}
                                items={productTypes.map((item: any) => {
                                    return {
                                        label: item.productTypeName,
                                        key: item.productTypeCode,
                                    };
                                })}
                            />
                        </Col>
                    </Row>
                    <br />

                    {/* reg zone */}
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Typography.Text>โซนต้นทาง :</Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: 10 }}>
                            <Tabs tabPosition={'left'} size={'small'}
                                tabBarStyle={{ width: '100%', }}
                                onTabClick={(key: any) => {
                                    setSelectSrcZoneCode(key)
                                    if (isEdit) {
                                        setIsEditing(false);
                                    }
                                }}
                                items={srcZones.map((item: any) => {
                                    return {
                                        label: `${item.zoneName} [${item.zoneCode}]`,
                                        key: item.zoneCode,
                                    };
                                })}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* dest zone */}
                <Col span={18}>
                    <Row style={{ alignItems: 'baseline', paddingBottom: 10 }}>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Space size={'large'} style={{ paddingLeft: 20 }}>
                                <Typography.Text>โซนปลายทาง :</Typography.Text>
                                {isEdit ?
                                    <Dropdown menu={{ items }} trigger={['click']}>
                                        <a onClick={(e) => e.preventDefault()}>
                                            <Button shape="circle" size='middle' icon={<PlusOutlined style={{ color: blue.primary }} />} onClick={() => { }} />
                                        </a>
                                    </Dropdown>
                                    :
                                    undefined
                                }
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Tabs type="card" size={'middle'}
                                // onTabClick={(key: any) => setSelectDestZoneCode(key)}
                                items={destZones.map((item: any, i) => {
                                    return {
                                        label: `${item.zoneName} [${item.zoneCode}]`,
                                        key: '' + item.zoneCode,
                                        children: <>
                                            <DestZonePriceTab
                                                destZoneCode={item.zoneCode}
                                                filepriceId={filepriceId}
                                                fileCode={fileCode}
                                                productTypeCode={productTypeCode}
                                                regZoneCode={selectSrcZoneCode}
                                                isEdit={isEdit}
                                                setIsEditing={setIsEditing}
                                            />
                                        </>,
                                    };
                                })}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FilepriceValueView
