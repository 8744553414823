import { blue, red } from '@ant-design/colors';
import { CloseCircleOutlined, DeleteFilled, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, message, Row, Space, Table, Tabs, Typography } from 'antd';
import type { TableProps } from 'antd/es/table';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import filepriceSizingServices from 'src/services/filepriceSizingServices';
import productTypeServices from 'src/services/productTypeServices';
import { SessionUser } from 'src/store/authen/types';
import { showMessage } from 'src/utils';

interface IProps {
    filepriceId: any;
    fileCode: any;
    isEdit: boolean;
    setIsEditing: any;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: any;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const FilepriceSizeView: React.FC<IProps> = (props) => {
    const { filepriceId, fileCode, isEdit, setIsEditing } = props;
    const [form] = Form.useForm();
    const [filterProductType, setFilterProductType] = useState<any[]>([]);
    const [sizings, setSizings] = useState<any[]>([]);
    const [trashs, setTrashs] = useState<any[]>([]);
    const [isLoad, setLoad] = useState(false);
    const [editingKey, setEditingKey] = useState('');
    // const [page, setPage] = useState<number>(1);
    // const pageSize = 10;
    // const [isAdding, setAdding] = useState(false);
    const [count, setCount] = useState<number>(-1);
    const [selectProductType, setSelectProductType] = useState('');

    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const columnSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    const handleDelete = (id: any) => {
        if (id > 0) {
            const trash = sizings.find((item) => (item.id === id && item.id > 0));
            setTrashs([...trashs, trash])
        }
        const newData = sizings.filter((item) => item.id !== id);
        setSizings(newData);
    };

    const sizingColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "ขนาด", dataIndex: "sizeCode", key: "sizeCode",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.sizeCode, b.sizeCode),
            render: (value: string, item: any, index: number) => {
                if (Number(item.id) < 0) {
                    return (
                        <Input placeholder='ขนาด'
                            defaultValue={value}
                            onChange={(v) => {
                                let chk = false;
                                for (let i = 0; i < sizings.length; i++) {
                                    if (sizings[i].sizeCode === v.target.value) {
                                        chk = true;
                                        message.warning(`ขนาด ${v.target.value} ซ้ำกับแถวที่ ${i + 1}`, 5)
                                    }
                                }
                                setSizings((prevData: any) => {
                                    const newData = [...prevData];
                                    const newItem = { ...newData[index] };
                                    newItem.sizeCode = v.target.value;
                                    newData[index] = newItem;
                                    return newData;
                                });
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                    )
                } else {
                    return (
                        <Col span={22} style={{ display: 'flex', justifyContent: 'center' }}>
                            <span>{value}</span>
                        </Col>
                    )
                }
            }
        },
        {
            title: "น้ำหนัก", dataIndex: ["weightMin", "weightMax"], key: "weight",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                if (Number(item.id) < 0) {
                    return <Col span={22} style={{ display: 'flex', alignItems: 'center' }}>
                        <InputNumber placeholder='min weight'
                            defaultValue={item.weightMin}
                            onChange={(v) => {
                                setSizings((prevData: any) => {
                                    const newData = [...prevData];
                                    const newItem = { ...newData[index] };
                                    newItem.weightMin = v;
                                    newData[index] = newItem;
                                    return newData;
                                });
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        -
                        <InputNumber placeholder='max weight'
                            defaultValue={item.weightMax}
                            onChange={(v) => {
                                setSizings((prevData: any) => {
                                    const newData = [...prevData];
                                    const newItem = { ...newData[index] };
                                    newItem.weightMax = v;
                                    newData[index] = newItem;
                                    return newData;
                                });
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                    </Col>
                } else {
                    return (
                        <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span>{`${item.weightMin} - ${item.weightMax}`}</span>
                        </Col>
                    )
                }
            }
        },
        {
            title: "ปริมาตร", dataIndex: ["volumeMin", "volumeMax"], key: "volume",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                if (Number(item.id) < 0) {
                    return <Col span={22} style={{ display: 'flex', alignItems: 'center' }}>
                        <InputNumber placeholder='min volume'
                            defaultValue={item.volumeMin}
                            onChange={(v) => {
                                setSizings((prevData: any) => {
                                    const newData = [...prevData];
                                    const newItem = { ...newData[index] };
                                    newItem.volumeMin = v;
                                    newData[index] = newItem;
                                    return newData;
                                });
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        -
                        <InputNumber placeholder='max volume'
                            defaultValue={item.volumeMax}
                            onChange={(v) => {
                                setSizings((prevData: any) => {
                                    const newData = [...prevData];
                                    const newItem = { ...newData[index] };
                                    newItem.volumeMax = v;
                                    newData[index] = newItem;
                                    return newData;
                                });
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                    </Col>
                } else {
                    return (
                        <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span>{`${item.volumeMin} - ${item.volumeMax}`}</span>
                        </Col>
                    )
                }
            }
        },
        {
            title: "น้ำหนัก Factor", dataIndex: "weightFactor", key: "weightFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                if (Number(item.id) < 0) {
                    return (
                        <Col span={22} style={{ display: 'flex', alignItems: 'center' }}>
                            <InputNumber placeholder='น้ำหนัก Factor'
                                defaultValue={item.weightFactor}
                                onChange={(v) => {
                                    setSizings((prevData: any) => {
                                        const newData = [...prevData];
                                        const newItem = { ...newData[index] };
                                        newItem.weightFactor = v;
                                        newData[index] = newItem;
                                        return newData;
                                    });
                                }}
                                onFocus={(event) => event.target.select()}
                            />
                        </Col>
                    )
                } else {
                    return (
                        <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span>{value}</span>
                        </Col>
                    )
                }
            }
        },
        {
            title: "ปริมาตร Factor", dataIndex: "volumeFactor", key: "volumeFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                if (Number(item.id) < 0) {
                    return (
                        <Col span={22} style={{ display: 'flex', alignItems: 'center' }}>
                            <InputNumber placeholder='ปริมาตร Factor'
                                defaultValue={item.volumeFactor}
                                onChange={(v) => {
                                    setSizings((prevData: any) => {
                                        const newData = [...prevData];
                                        const newItem = { ...newData[index] };
                                        newItem.volumeFactor = v;
                                        newData[index] = newItem;
                                        return newData;
                                    });
                                }}
                                onFocus={(event) => event.target.select()}
                            />
                        </Col>
                    )
                } else {
                    return (
                        <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span>{value}</span>
                        </Col>
                    )
                }
            }
        },
        {
            title: "Action", key: "action", align: "center" as AlignType, hidden: !isEdit,
            render: (value: string, item: any, index: number) => {
                if (isEdit && (Number(item.id) < 0 || !item.isUsed)) {
                    return (
                        <Col span={22} style={{ display: 'flex', alignItems: 'center' }}>
                            <Space size={'small'} >
                                <Button danger size='middle' color={red.primary} icon={<DeleteFilled />} onClick={() => handleDelete(item.id)} />
                            </Space>
                        </Col>
                    )
                }
            }
        }
    ].filter(item => !item.hidden);

    useEffect(() => {
        if (!isLoad) {
            handleSearchProductType('');
            setLoad(true);
        }
    }, []);

    useEffect(() => {
        if (isEdit === true) {
            // setTemSizings(sizings);
        } else {
            // setTemSizings([]);
            handleSearchProductType(selectProductType);
        }
        // setAdding(!isEdit)
    }, [isEdit]);

    useEffect(() => {
        if (filterProductType.length > 0) {
            setSelectProductType(filterProductType[0].productTypeCode);
            handleSearchSizing(filterProductType[0].productTypeCode);
        }
    }, [filterProductType]);

    async function handleCheckIsUsed(sizeCode: string) {
        const res = await filepriceSizingServices.isUsedSizing(filepriceId, selectProductType, sizeCode);
        if (res.status === 200) {
            return res.data;
        } else {
            return false;
        }
    }

    async function handleSearchProductType(val: string) {
        const res = await productTypeServices.getByParam('', '', 0)
        if (res.status === 200) {
            const sort = await res.data.sort(function (a: any, b: any) {
                let x = a.productTypeName.toLowerCase();
                let y = b.productTypeName.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            setFilterProductType(await sort)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function handleSearchSizing(productTypeCode: string) {
        const res = await filepriceSizingServices.getSizingsByFilter(filepriceId, productTypeCode, '', '')
        if (res.status === 200) {
            if (res.data.length > 0) {
                const data: any[] = [];
                for (let el of res.data) {
                    el.isUsed = await handleCheckIsUsed(el.sizeCode);
                    data.push(el);
                }
                setSizings(data);
            }
            setTrashs([]);
            setSelectProductType(productTypeCode);
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    const handleAddSizing = () => {
        // const lastPage = Math.ceil(sizings.length / pageSize);
        // setPage(lastPage);
        const newData = {
            //   key: count,
            id: count + '',
            sizeCode: '',
            weightMin: '',
            weightMax: '',
            volumeMin: '',
            volumeMax: '',
        };
        setSizings([...sizings, newData]);
        // setAdding(true);
        setCount(count - 1);
    };

    const defaultFooter = () => (
        <Space size={'large'} style={{ paddingLeft: 10 }}>
            <Button shape="circle" size='middle' icon={<PlusOutlined style={{ color: blue.primary }} />} onClick={handleAddSizing} />
        </Space>
    );

    interface DataType {
        key: number;
        index: number;
        sizeCode: string;
        weightMax: string;
        volumeMax: string;
        weightFactor: number;
        volumeFactor: number;
    }

    const tableProps: TableProps<DataType> = {
        footer: isEdit ? defaultFooter : undefined,
    };

    // const onCancelEditing = () => {
    //     setSizings(temSizings);
    //     setIsEditing(false);
    // }

    const handleSaveEdit = async () => {
        // console.log(trashs);return;
        if (!isValid(sizings)) {
            return;
        }
        let _save: any[] = [];
        const editDatas = sizings.filter((item: any) => item.id < 0)
        if (editDatas.length <= 0 && trashs.length <= 0) {
            message.warning('ไม่มีข้อมูลที่ต้องบันทึก', 2)
            return;
        }
        editDatas.forEach((item: any) => {
            _save.push({
                fileCode: fileCode,
                productTypeCode: selectProductType,
                weightMin: item.weightMin,
                weightMax: item.weightMax,
                volumeMin: item.volumeMin,
                volumeMax: item.volumeMax,
                sizeCode: item.sizeCode,
                weightFactor: item.weightFactor !== undefined ? item.weightFactor : null,
                volumeFactor: item.volumeFactor !== undefined ? item.volumeFactor : null,
                filepriceId: filepriceId,
                createUserid: sessionUser.userId,
                createUsername: sessionUser.username,
                savStation: sessionUser.stationCode,
            })
        })
        trashs.forEach((item: any) => { _save.push(item) })
        // console.log(_save);
        try {
            filepriceSizingServices.editFilepriceSizing(_save).then((res: any) => {
                if (res.status === 200)
                    message.success("บันทึกข้อมูลแล้ว");
                setIsEditing(false);
            }).catch((err: any) => {
                message.warning('Service : ' + err)
            });
        } catch (err) {
            message.warning('บันทึกข้อมูลไม่สำเร็จ')
        }
    }


    const isValid = (list: any) => {
        let inValid = false;
        list.map((item: any) => {
            return item.sizeCode
        }).forEach((element: any, index: any, arr: any) => {
            if (inValid) {
                return;
            }
            if (!element) {
                message.warning(`แถว ${index + 1} ไม่ได้ระบุขนาด`, 5)
                inValid = true;
            }
            if (arr.indexOf(element) !== index) {
                message.warning(`ขนาด ${element} ซ้ำกับแถวที่ ${arr.indexOf(element) + 1}`, 4)
                inValid = true;
            }
        });

        list.filter((item: any) => item.id < 0).forEach((element: any, index: any, arr: any) => {
            // console.log(element, arr)
            if (inValid) {
                return;
            }
            if (element.weightMin < 0) {
                message.warning(`ขนาด ${element.sizeCode} น้ำหนักต่ำสุดต้องมากกว่าหรือเท่ากับ 0`, 3)
                inValid = true;
            }
            if (element.weightMax <= 0) {
                message.warning(`ขนาด ${element.sizeCode} น้ำหนักสูงสุดต้องมากกว่า 0`, 3)
                inValid = true;
            }
            if (element.volumeMin < 0) {
                message.warning(`ขนาด ${element.sizeCode} ปริมาตรต่ำสุดต้องมากกว่าหรือเท่ากับ 0`, 3)
                inValid = true;
            }
            if (element.volumeMax <= 0) {
                message.warning(`ขนาด ${element.sizeCode} ปริมาตรสูงสุดต้องมากกว่า 0`, 3)
                inValid = true;
            }
            if (element.volumeMax <= element.volumeMin) {
                message.warning(`ขนาด ${element.sizeCode} ปริมาตรสูงสุดต้องมากกว่าปริมาตรต่ำสุด`, 3)
                inValid = true;
            }
            if (element.weightMax <= element.weightMin) {
                message.warning(`ขนาด ${element.sizeCode} น้ำหนักสูงสุดต้องมากกว่าน้ำหนักต่ำสุด`, 3)
                inValid = true;
            }
            list.forEach((element: any, index: any, arr: any) => {
                if (inValid) {
                    return;
                }
                if (element.id >= 0) {
                    return;
                }
                arr.forEach((e: any) => {
                    if (inValid || element.sizeCode === e.sizeCode) {
                        return;
                    }
                    // if (element.weightMin >= e.weightMin && element.weightMin <= e.weightMax) {
                    //     message.warning(`ขนาด ${element.sizeCode} น้ำหนักต่ำสุด อยู่ในช่วงน้ำหนักเดียวกับ ${e.sizeCode}`, 3)
                    //     inValid = true;
                    // }
                    // if (element.weightMax >= e.weightMin && element.weightMax <= e.weightMax) {
                    //     console.log()
                    //     message.warning(`ขนาด ${element.sizeCode} น้ำหนักสูงสุด อยู่ในช่วงน้ำหนักเดียวกับ ${e.sizeCode}`, 3)
                    //     inValid = true;
                    // }
                    // if (element.volumeMin >= e.volumeMin && element.volumeMin <= e.volumeMax) {
                    //     message.warning(`ขนาด ${element.sizeCode} ปริมาตรต่ำสุด อยู่ในช่วงปริมาตรเดียวกับ ${e.sizeCode}`, 3)
                    //     inValid = true;
                    // }
                    // if (element.volumeMax >= e.volumeMin && element.volumeMax <= e.volumeMax) {
                    //     message.warning(`ขนาด ${element.sizeCode} ปริมาตรสูงสุด อยู่ในช่วงปริมาตรเดียวกับ ${e.sizeCode}`, 3)
                    //     inValid = true;
                    // }
                    if ((element.weightMin >= e.weightMin && element.weightMin <= e.weightMax) ||
                        (element.weightMax >= e.weightMin && element.weightMax <= e.weightMax) ||
                        (e.weightMin >= element.weightMin && e.weightMin <= element.weightMax) ||
                        (e.weightMax >= element.weightMin && e.weightMax <= element.weightMax)) {
                        message.warning(`ช่วงน้ำหนัก ขนาด ${element.sizeCode} อยู่ในช่วงน้ำหนักเดียวกับ ${e.sizeCode}`, 3)
                        inValid = true;
                    }
                    if ((element.volumeMin >= e.volumeMin && element.volumeMin <= e.volumeMax) ||
                        (element.volumeMax >= e.volumeMin && element.volumeMax <= e.volumeMax) ||
                        (e.volumeMin >= element.volumeMin && e.volumeMin <= element.volumeMax) ||
                        (e.volumeMax >= element.volumeMin && e.volumeMax <= element.volumeMax)) {
                        message.warning(`ช่วงปริมาตร ขนาด ${element.sizeCode} อยู่ในช่วงปริมาตรเดียวกับ ${e.sizeCode}`, 3)
                        inValid = true;
                    }
                });

            });
        });
        if (inValid) {
            return false;
        }
        return true;
    }

    return (
        <>
            <Row>
                {/* product type */}
                <Col span={6}>
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Typography.Title level={5} style={{ margin: 0 }}>รายการ</Typography.Title>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={24} style={{ paddingLeft: "5px", }} >
                            <Typography.Text>ประเภทสินค้า :</Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: 10 }}>
                            <Tabs tabPosition={'left'} size={'small'}
                                defaultActiveKey={selectProductType}
                                tabBarStyle={{ width: '100%', }}
                                onTabClick={(key: any) => {
                                    handleSearchSizing(key)
                                    if (isEdit) {
                                        setIsEditing(false);
                                    }
                                }}
                                items={filterProductType.map((item: any, i) => {
                                    return {
                                        label: item.productTypeName,
                                        key: item.productTypeCode,
                                    };
                                })}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* sizing */}
                <Col span={18} style={{ paddingTop: 10, paddingLeft: 20 }}>
                    <Row>
                        <Col span={24} >
                            <Table
                                {...tableProps}
                                size='small'
                                dataSource={sizings}
                                columns={sizingColumns}
                                rowKey={(item: any) => item.id + ''}
                                pagination={false}
                            // pagination={{ pageSize: pageSize, current: page, onChange: setPage, showQuickJumper: false }}
                            />
                        </Col>
                    </Row>
                    {isEdit ?
                        <Row>
                            <Col span={24} style={{ paddingTop: 10 }}>
                                <Space size="large" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        onClick={handleSaveEdit}
                                        style={{ width: 120 }}
                                    >
                                        บันทึก
                                    </Button>
                                    <Button
                                        icon={<CloseCircleOutlined />}
                                        onClick={() => setIsEditing(false)}
                                        style={{ width: 120 }}
                                    >
                                        ยกเลิก
                                    </Button>
                                </Space >
                            </Col>
                        </Row>
                        :
                        <></>
                    }
                </Col>
            </Row>
        </>
    )
}

export default FilepriceSizeView
