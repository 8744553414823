import axios from 'axios';
import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'class';
const urlDtl = servicePort + 'classdetail';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (name : any , useFlg : number) => {
    const param = `name=${name}&useFlg=${useFlg}`;
    return http.get(url + "/byparam?" + param);
    
  };

const addClass = (Class: any) => {
    return http.post(url + "/add", Class);
};

const updateClass = ( Class: any) => {
    return http.put(url + "/update", Class);
};

// Class Detail 
const getClassDetailById = (id: number) => {
    return http.get(`${urlDtl}/byId/${id}`);
}

const getAllDetailByClassId = async (id : number) => {
    const param = `classId=${id}`;
    //return http.get(urlDtl + "/byClassIdAndStatus/?" + param);
    let url1 = `${urlDtl}/getAllDetailByClassId?` + param;
    let res = await axios.get(url1);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
    
  };
const getClassDetailByClassId = async (id : number) => {
    let url1 = `${urlDtl}/byClassId/${id}`;
    let res = await axios.get(url1);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}
const addClassDetail = (ClassDetail : any) => {
    return http.post(urlDtl + "/add", ClassDetail);
};
const updateClassDetail = (ClassDetail: any) => {
    return http.put(urlDtl + "/update", ClassDetail);
};

export default {
    getAll,
    getById,
    getByParam,
    addClass,
    updateClass,
    getClassDetailByClassId,
    addClassDetail,
    updateClassDetail,
    getClassDetailById,
    getAllDetailByClassId
}