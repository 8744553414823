import { Button, Col, Modal, Row, Table ,Input, message } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React, { useEffect, useState } from 'react'
import { Station } from 'src/domain/stationType'
import stationServices from 'src/services/stationServices';
import { SaveOutlined } from '@ant-design/icons';
import { Fleet } from 'src/domain/fleetStation';
import { SessionUser } from 'src/store/authen/types';
import { useSelector } from 'react-redux';
import { showMessage } from 'src/utils';
import fleetStationService from 'src/services/fleetStationServices';

interface FleetStationSelectModalIProps {
    isModalOpen: boolean;
    onOk: any;
    onCancel: any;
    // onRowDoubleClick: any;
    truckUId: any;
}
const FleetStationSelectModal  = (props: FleetStationSelectModalIProps) => {

    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
    
    const [page,setPage] = React.useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [truckUId,setTruckUId] =useState<string>();
    const [stations,setStations] = useState<Station[]>([]);
    const [selects, setSelects] = useState<Station[]>([]);
    const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
    const [fleetTruck,setFleetTruck] = useState<Fleet>();
    
    const stationcolumns = [
        {
            title: "",
            key: "stationCode",
            render: (value: number, item: Station, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "สาขา",
            dataIndex: "stationName",
            key: "stationName",
        },
    ];

    useEffect(() => {
        console.log('on modal');
        setTruckUId(props.truckUId)

        stationServices.getAll()
            .then((res) => {
                console.log(res.data);
                setStations(res.data);
            })
    }, []);

    useEffect(() => {
        setIsModalOpen(props.isModalOpen)
    }, [props.isModalOpen])

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows:Station[]) => {
            console.log("rowSelection ",selectedRows,selectedRowKeys)
            setSelects(selectedRows);
        },
    };


    const changeSearchText = async (searchText:any) =>{
      stationServices.getByParam("",searchText,"","",0)
      .then((res)=>{
          setStations(res.data);
      })
    }


    const saveStation = async () => {
        console.log("rowSelection onfinist ", selects)

        if (selects.length > 0) {
         
            let fleetStationEntity : Fleet = {
                id: 0,
                truckCode: truckUId === undefined?"": truckUId,
                driverName: "",
                driverUId : "",
                stations : selects,
                useFlg: 0,

                truckUId : truckUId === undefined?"": truckUId,
                registId : "",
                savStation: sessionUser.stationCode!,
                createUserid: sessionUser.userId!,
                createUsername: sessionUser.username!
              };
            setFleetTruck(fleetStationEntity);
            try {
                console.log("fleetDrv >> ",fleetTruck)
                await fleetStationService.addFleetTruckStation (fleetStationEntity).then(res => {
                    // console.log("adddrv",res);
                    if(res.data){
                        message.success("save success", 1.5);
                        handleCancel();
                        // setIsModalOpen(false);
                    }else{
                        message.error(res.data.message, 1.5);
                    }
                    
                }).catch((err:any) => {
                    showMessage(err);
                    console.log("error1",err);
                });
            } catch (err: any) {
                console.log("error2",err);
                showMessage(err);
            }
        }else{
            console.log("else")
            message.error("ไม่ได้เลือกข้อมูล", 1.5);
        }
    }



    const handleCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };
  return (
    <>
      <Modal
                    title="ข้อมูลสาขา"
                    visible={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                   // width={400}
                    style={{ top: 30 ,height: 450 ,width : 400 }}
                >
                    <div>
                        <Input onChange={(e) => changeSearchText(e.target.value)} />

                        <Content className="app-page-content">
                            <Row >
                                <Col span={24}>
                                <Table
                                rowSelection={{
                                    type: selectionType,
                                    ...rowSelection,
                                }}
                                scroll={{ y: 300 }}
                                // pagination={{
                                //     pageSize: 10,
                                //     onChange(current) {
                                //         setPage(current);
                                //     }
                                // }}
                                pagination={false} 
                                rowKey={(item: Station) => item.id}
                                columns={stationcolumns}
                                dataSource={stations}
                                size="small"
                                style ={{height : 400 }}
                            />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                            <div style={{display:"flex", justifyContent:"flex-end"}}>
                            <Button
                                size="middle"
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() =>  saveStation()}
                            >
                                บันทึก
                            </Button>
                            </div>
                            </Col>
                            </Row>
                        </Content>
                    </div>
                </Modal>
    </>
  )
}

export default FleetStationSelectModal