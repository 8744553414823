import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Form, Select, Input, Radio,Typography, message } from 'antd';
import { useHistory } from 'react-router';
import { Content } from 'antd/es/layout/layout';
import Section from 'src/components/container/Section';
import { FleetDrv } from 'src/domain/fleetDrvType';
import fleetDrvService from 'src/services/fleetDrvService';
import fleetRequestDriverService from 'src/services/fleetRequestDriverService';
import { DriverRequest } from 'src/domain/fleetRequestDriverType';
import { SessionUser } from 'src/store/authen/types';
import { useSelector } from 'react-redux';
import { showMessage } from 'src/utils';


const { Option } = Select;
const { Text } = Typography;

const FleetDriverAdd = () => {

  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [trigger, setTrigger] = useState<boolean>(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const [driverRequest, setDriverRequest] = useState<DriverRequest[]>([]);
  const [driverUId,setDriverUId] = useState("");
  const [driverFullName,setDriverFullName] = useState("");

  const [fleetDrv, setFleetDrv] = useState<FleetDrv>();


  useEffect(() => {
    fleetRequestDriverService.getAllFleetDriver().then((res)=>{
      setDriverRequest(res.data);
    })
  }, [])

  

  const selectDriverFleet = async (value: any) => {
    // console.log(value)
    const resprename = await fleetRequestDriverService.getDriverDataFromFleet(value);
    console.log("resprename ",resprename)
    setDriverUId(value);
    setDriverFullName(resprename.data.fullname);
  };


  const onFinish = async (values: any) => {

      // console.log("values ",values)

       const _fleetDrv: FleetDrv = {

        id: 0,
        driverCode: driverUId,
        driverName: driverFullName,
        useFlg: 0,
       
        savStation: sessionUser.stationCode,
        createUserid: sessionUser.userId,
        createUsername: sessionUser.username

      };

      console.log(_fleetDrv)

      try {
        await  await fleetDrvService.addFleetDrv(_fleetDrv).then(res => {
            console.log("addFleetDrv",res);
            if(res.data.status){
                message.success("save success", 1.5);
                history.replace("/app/fleetstation/driver")
            }else{
                message.error(res.data.message, 1.5);
            }
            
        }).catch(err => {
            showMessage(err);
            console.log("error1",err);
        });
    } catch (err: any) {
        console.log("error2",err);
        showMessage(err);
    }
    }

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
    // setTrigger(false);
  };

  return (
    <>
      <PageHeader title="เพิ่มข้อมูลพนักงาน" subTitle="เพิ่มพนักงานขับรถจากFleet ปิด/เปิด การใช้งาน">
        <Space>
          <Button
            size="large"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => {form.submit()}}
          >
            บันทึก
          </Button>
          <Button
            size="large"
            icon={<CloseCircleOutlined />}
            onClick={() => history.goBack()}
          >
            กลับ
          </Button>
        </Space>
      </PageHeader>

      <Content>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Section>
            <Row gutter={[20, 10]} style={{ display: 'flex', }}>
              <Col span={6}></Col>
              <Col span={8}>
                <Form.Item
                  label="พนักงานขับรถ"
                  name="driverName"
                  rules={[
                    { required: true, message: "กรุณาเลือกพนักงานขับรถ" },
                  ]}
                >
                  <Select
                    showSearch
                    size="middle"
                    optionFilterProp="children"
                    filterOption={true}
                  // onSearch={onSearch}
                   onSelect={selectDriverFleet}
                  >
                    {driverRequest.map((value: any) => {
                      return (
                        <Option value={value.driverUId} key={value.driverUId}>
                          {value.fullname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}></Col>
              <Col span={8}>
                <Form.Item label="DRIVERUID" name="driverUId">
                <Text style={{fontSize:"large"}}>{driverUId}</Text>
                </Form.Item>
                {/* <FormFleetStation truckUId={"0"} trigger={trigger} setTrigger={setTrigger} /> */}
              </Col>
            </Row>
            <Row>
              <Col span={6}></Col>
              <Col span={8}>
                <Form.Item label="สถานะ" name="status">
                  <Radio.Group
                    //   onChange={onChange} 
                    defaultValue={0}
                  >
                    <Space direction="horizontal">
                      <Radio value={0}>ใช้งาน</Radio>
                      <Radio value={1}>ไม่ใช้งาน</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {/* <FormFleetStation truckUId={"0"} trigger={trigger} setTrigger={setTrigger} /> */}
              </Col>
            </Row>
          </Section>
        </Form>
        <Row>

        </Row>

      </Content>
    </>
  )
}

export default FleetDriverAdd