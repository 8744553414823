import axios from 'axios';
import { TruckRequest } from 'src/domain/fleetRequestTruckType';
import http from '../lib/http';
import { fleetPort } from './configServices';

const url = fleetPort + 'fleetrequesttruck';
// const url = "http://localhost:8085/fleetrequesttruck";

	
const getTruckDataFromFleet = async (truckUId: any) => {
    console.log(url + "/truck/gettruck/" + truckUId)
    return http.get(url + "/truck/gettruck/" + truckUId);
}  


//addfleettruckstation

export default {
    getTruckDataFromFleet
}