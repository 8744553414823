import { Console } from "console";
import http from "../../lib/http";

const url = process.env.REACT_APP_AUTHURL + "/authen/login";

const login = async (username: string, password: string) => {
    const resp =  await http.post(
        `${url}`,
        {username,password}
    );

    return resp.data;
  }

  export default {
    login,
  };