import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import FormStationArea from './FormStationArea';
import { RouteComponentProps, useHistory } from 'react-router';

interface IParams {
  stationcode : string;
  }

const Edit:React.FC<RouteComponentProps<IParams>> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();
    const stationcode = props.match.params.stationcode;

    return (
			<>
				<PageHeader title="แก้ไข Station Area" subTitle="แก้ไขระบุรายละเอียด และ ขอบเขตการบริการของสาขา">
					<Space>
						{/* <Button
              // danger
              size="small"
                type="primary"
               icon={<SaveOutlined />}
               onClick={() => setTrigger(true)}
            >
              update
            </Button> */}
						<Button
							// type="primary"
							icon={<CloseCircleOutlined />}
							onClick={() => history.goBack()}
						>
							Cancel
						</Button>
					</Space>
				</PageHeader>

				<FormStationArea stationcode={stationcode} trigger={trigger} setTrigger={setTrigger} />
			</>
		);
}

export default Edit;