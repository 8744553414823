import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'stationline';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (shopname : any, name : any , useFlg : number) => {
    const param = `shop=${shopname}&name=${name}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
    
  };

const addStationLine = (StationLine: any) => {
    return http.post(url + "/add", StationLine);
};

const updateStationLine = (StationLine: any) => {
    return http.put(url + "/update", StationLine);
};

export default {
    getAll,
    getById,
    getByParam,
    addStationLine,
    updateStationLine,
}