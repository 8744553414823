import { CloseCircleOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader';
import FormBookingTruck from './FormBookingTruck';

interface IParams {
    id: string;
}

const EditBookingTruck: React.FC<RouteComponentProps<IParams>> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const [cancleTrigger, setCancleTrigger] = useState<boolean>(false);
    const history = useHistory();
    const id = Number(props.match.params.id);

    return (
			<>
				<PageHeader title="แก้ไขข้อมูลตั้งรถส่วนกลาง (ขนย้าย)" subTitle="แก้ไขการตั้งรถส่วนกลาง">
					<Space>
						<Button
							type="primary"
							danger
							icon={<DeleteOutlined />}
							onClick={() => setCancleTrigger(true)}
							style={{ width: 120 }}
						>
							ยกเลิก
						</Button>
						<span style={{ paddingLeft: 50 }}></span>
						<Button type="primary" icon={<SaveOutlined />} onClick={() => setTrigger(true)} style={{ width: 120 }}>
							แก้ไข
						</Button>
						<Button icon={<CloseCircleOutlined />} onClick={() => history.goBack()} style={{ width: 120 }}>
							กลับ
						</Button>
					</Space>
				</PageHeader>

				<FormBookingTruck
					id={id}
					trigger={trigger}
					setTrigger={setTrigger}
					cancleTrigger={cancleTrigger}
					setCancleTrigger={setCancleTrigger}
				/>
			</>
		);
}

export default EditBookingTruck;
