import { message } from "antd";

export function showMessage(content: string) {
  message.error({
    content: content,
    duration: 1.8,
    style: {
      maxWidth: "450px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  });
}

export const round2DecimalPoint = (value: number) =>
(Math.round(value * 100) / 100).toFixed(2);
  
export const generateOrderNumber = () =>
  Math.floor(100000 + Math.random() * 900000).toString();


export   const handleEnter = (event : any) => {
  if (event.key.toLowerCase() === "enter") {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

