import React, { useEffect, useState } from 'react'
import { Input, Modal, Layout, Table } from 'antd';
import { showMessage, handleEnter } from "src/utils";
import { Station } from 'src/domain/stationType';
import stationServices from 'src/services/stationServices';
import { FunctionDeclaration } from 'typescript';

const stationcolumns = [
    {
        title: "สาขา",
        dataIndex: "stationName",
        key: "stationName",
    },
  ];
interface StationModalProps{
    isModalOpen: boolean;
    onOk: any;
    onCancel: any;
    onRowDoubleClick: any;
}
const StationModal = (props: StationModalProps) => {
    const { Content } = Layout;
    const [stations, setStations] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        console.log('on modal');
        stationServices.getAll()
            .then((res) => {
                console.log(res.data);
                setStations(res.data);
            })
    }, []);

    useEffect(() => {
        setIsModalOpen(props.isModalOpen)
    }, [props.isModalOpen])

    const changeSearchText = async (searchText: any) => {
        stationServices.getByParam("", searchText, "", "", 0)
            .then((res) => {
                setStations(res.data)
            })
    }

    const handleCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    return (
        <>
            <Modal 
                title="ข้อมูลสาขา" 
                visible={isModalOpen} 
                onCancel={handleCancel}
                footer={null}
            >
                <div>
                    <Input onChange={(e) => changeSearchText(e.target.value)}/>

                    <Content className="app-page-content">
                        <Table
                            pagination={{
                                pageSize: 10,
                                onChange(current) {
                                    setPage(current);
                                }
                            }}
                            rowKey={(item: Station) => item.id}
                            columns={stationcolumns}
                            dataSource={stations}
                            size="small"
                            onRow={(data, index) => {
                                return {
                                    onDoubleClick: (event: any) => {
                                        console.log("double click: ", index, data);
                                        if(props.onRowDoubleClick){
                                            props.onRowDoubleClick(data, index, event);
                                            handleCancel();
                                        }
                                    }
                                }
                            }}
                        />

                    </Content>
                </div>
            </Modal>
        </>
    );
}
export default StationModal;