import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { AccountBank } from '../../domain/accountBankType';
import { Link } from 'react-router-dom';
import accountBankServices from 'src/services/accountBankServices';
import Section from "../../components/container/Section";

const ListAccountBank = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [list, setList] = useState<AccountBank[]>([]);
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [code, setCode] = useState("");
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            align: "center" as "center",
            render: (value: number, item: AccountBank, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัสบัญชี",
            dataIndex: "accountCode",
            align: "center" as "center",
            key: "accountCode",
        },
        {
            title: "เลขบัญชี",
            dataIndex: "accountNo",
            //align: "center" as "center",
            key: "accountNo",
        },
        {
            title: "ชื่อบัญชี",
            dataIndex: "accountName",
            key: "accountName",
            //align: "center" as "center",
            render: (value: string, item: AccountBank, index: number) => <Link to={`/app/accountbank/edit/${item.id}`}>{value}</Link>,
        },
        {
            title: "ธนาคาร",
            dataIndex: "bankName",
            //align: "center" as "center",
            key: "bankName",
        },
        {
            title: "NIMSHOP",
            dataIndex: "accountStation",
            align: "center" as "center",
            key: "accountStation",
        },
    ];

    useEffect(() => {
        accountBankServices.getByParam("", "", 0)
            .then((res) => {
                console.log(res.data)
                setList(res.data);
            })
    }, []);

    const search = () => {
        accountBankServices.getByParam(searchName, code, status)
            .then((res) => {
                setList(res.data)
            })
    }

    return (
        <div>
            <PageHeader title="กำหนดบัญชีธนาคาร" subTitle="กำหนดข้อมูลบัญชีธนาคารของแต่ละสาขา">
                <Space>
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/accountbank/add") }}
                    >เพิ่ม
                    </Button>
                </Space>
            </PageHeader>

            <Section style={{ display: "flex", flexDirection: "column" }}>
                <Form layout="vertical">
                    <Row gutter={[20, 10]} style={{ display: "flex", alignItems: "start" }}>
                        <Col span={8}>
                            <Form.Item label="ชื่อบัญชี" name="searchName">
                                <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/>
                            </Form.Item>
                            <Radio.Group name="status" defaultValue={0} onChange={(e) => setStatus(e.target.value)}>
                            <Space>
                                <Radio value={0} >ใช้งาน</Radio>
                                <Radio value={-1} >ไม่ใช้งาน</Radio>
                            </Space>
                        </Radio.Group>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end'}} >
                            <Button  icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30}}>
                                ค้นหา
                            </Button>
					    </Col>
                    </Row>
                </Form>
            </Section>

           <Section style={{ marginTop: 30, paddingTop: 30 }}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                            setPage(current);
                        },
                        showSizeChanger: false
                    }}
                    rowKey={(item: AccountBank) => item.id}
                    columns={columns}
                    dataSource={list}
                    size="small" />

            </Section>
        </div>
    )
}

export default ListAccountBank;
