import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'franchisee';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (name : any, tel : any , useFlg : number) => {
    const param = `name=${name}&tel=${tel}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
};

const addFranchisee = (Franchisee: any) => {
    return http.post(url + "/add", Franchisee);
};

const updateFranchisee = (Franchisee : any) => {
    return http.put(url + "/update", Franchisee);
};

export default {
    getAll,
    getById,
    getByParam,
    addFranchisee,
    updateFranchisee,
}