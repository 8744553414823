import { Col, Form, Input, Radio, Row, Select, Typography, Layout, message,AutoComplete } from "antd";
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Station } from "src/domain/stationType";
import stationLineServices from "src/services/stationLineServices";
import stationServices from "src/services/stationServices";
import { SessionUser } from "src/store/authen/types";
import { showMessage, handleEnter } from "src/utils";
import { StationLine } from "../../domain/stationLineType";

interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FormStationLine: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  console.log(id)
  const [stationLine, setStationLine] = useState<StationLine>({} as StationLine);
  const [station, setStation] = useState<Station>({} as Station);
  const history = useHistory();
  const [form] = Form.useForm();
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [stationId, setStationId] = useState(Number);

  useEffect(() => {
    if (id != 0) {
      stationLineServices.getById(Number(id))
        .then((res) => {
          setStationLine(res.data)
        })
    }
  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);


  useEffect(() => {
    if (id === 0) {

    } else {
      console.log(stationLine);
      form.setFieldsValue({
        id: stationLine.id,
        stationName: stationLine.stationName,
        lineCode: stationLine.lineCode,
        lineName: stationLine.lineName
      });
    }

  }, [stationLine]);

  const [options, setOptions] = useState<Array<{ value: string, objectvalue: Station }>>([]);

  const onSearch = (searchText: string) => {
    console.log(" searchText " + searchText);
    stationServices.getByParam("",searchText, "","", 1)
      .then((res) => {
        let stationList: Array<Station> = res.data;
        let optionsTmp: Array<{ value: string, objectvalue: Station }> = [];
        stationList.forEach((station) => {
          let newOption = { value: station.stationName, objectvalue: station }
          optionsTmp.push(newOption);
        })
        setOptions(optionsTmp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSelect = (data: any) => {
    let dataStation = options.filter((val: any) => val.value === data)
    const selectedStation = dataStation[0].objectvalue;
    setStation(selectedStation);
    changeValue("stationId", selectedStation.id);
  };

  const changeValue = (input: string, value: any) => {
    setStationId(value);
    console.log(stationId);
  }

  const onFinish = async (values: any) => {
    const stationLineObj: StationLine = {
      id: id,
      stationId: stationId,
      stationName: values.stationName,
      lineCode: values.lineCode,
      lineName: values.lineName,
      useFlg: values.useFlg,
      savStation: sessionUser.username
    };

    if (id === 0) {
      try {
        stationLineServices.addStationLine(stationLineObj).then(res => {
          history.replace("/app/stationline");
          message.success("Save Complete", 1.5);
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        showMessage(''+err);
      }
    }

    if (id !== 0) {
      stationLineServices.updateStationLine(stationLineObj).then(res => {
        history.replace("/app/stationline");
        message.success("Update Complete", 1.5);
      }).catch(err => {
        console.log(err)
      });

    }
    setTrigger(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
      <Layout.Content className="app-page-content">
        <Form

          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

          <Row style={{ paddingTop: "15px", }}>
            <Col span={12}>
              <Form.Item
                name="lineCode"
                label="รหัส"
              >
                <Input onKeyDown={handleEnter}/>
              </Form.Item>
            </Col>

          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="NIMSHOP"
                name="stationName"
                rules={[
                  { required: true, message: "กรุณาระบุ NIMSHOP" },
                ]}
              >
                <AutoComplete
                  id="stationId"
                  options={options}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  placeholder="เลือกด้วยชื่อ NIMSHOP"
                />
              </Form.Item>

            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label="ชื่อสาย"
                name="lineName"
                rules={[
                  { required: true, message: "กรุณาระบุชื่อสาย" },
                ]}
              >
                <Input onKeyDown={handleEnter}/>
              </Form.Item>

            </Col>
          </Row>

          <Row >
            <Col span={12} style={{ textAlign: 'left', }}>
              <Form.Item name="useFlg" label="สถานะ">
                <Radio.Group defaultValue={1} >
                  <Radio value={1} >ใช้งาน</Radio>
                  <Radio value={0}>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

        </Form>
      </Layout.Content>
    </>
  )
}

export default FormStationLine;