import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'filepriceAreaMarkup';

const addFilepriceAreaMarkup = (list: any) => {
    return http.post(url + "/add", list);
};

const isDuplicate = (filepriceId: number, stationCode: string, stationAreaCode: string) => {
    const param = `filepriceId=${filepriceId}&stationCode=${stationCode}&stationAreaCode=${stationAreaCode}`;
    return http.get(url + "/isDuplicate/?" + param);
};

const updateFilepriceAreaMarkup = (entity: any) => {
    return http.put(url + "/update", entity);
};

export default { addFilepriceAreaMarkup, isDuplicate, updateFilepriceAreaMarkup }