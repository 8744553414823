import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  setSessionUser,
  getSessionUserFromLocal,
} from "src/store/authen/action";
import "./Login.css";
import Section from "../components/container/Section";
import Title from "antd/es/typography/Title";
import DFlex from "../components/container/DFlex";
import preval from "preval.macro";
import moment from "moment";
import loginService from "../services/security/loginService";

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
const tmp = moment(dateTimeStamp.trim(), "M/D/YYYY, H:mm:ss a") // 7/11/2022, 5:10:35 PM

const timeSplit = dateTimeStamp.split(' ');
const year = tmp.year();
const month = tmp.month();
const day = tmp.date();
const hour =  timeSplit[2] === "PM" && tmp.hour() <= 11 ?  tmp.hour() + 12 : tmp.hour();
const minute = tmp.minute();
const second = tmp.second();
console.log(year, month, day, hour, minute, second);
const buildDate = moment({ year, month, day, hour, minute, second});

const buildLabel = process.env.REACT_APP_BUILD_LABEL ? process.env.REACT_APP_BUILD_LABEL : "Build" + " : ";



const LoginForm = () => {
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sessionUser } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // dispatch(getSessionUserFromLocal());
    const jsonSessionUser = localStorage.getItem("sessionUser");

    console.log("json sessionUser ", jsonSessionUser, localStorage.length);

    const sessionUser = jsonSessionUser && JSON.parse(jsonSessionUser);

    setLoading(false);
    if (sessionUser) {
      dispatch(setSessionUser(sessionUser))
      history.replace("/app");
    }
  }, []);

  // function validate(username: string, password: string): string {
  //   console.log("validate form");

  //   if (!(username === "admin" && password === "adminadmin")) {
  //     return "Incorrect username or password";
  //   }

  //   return "";
  // }

  const onFinish = (values: any) => {
    setError("")
    setLoading(true);

    const { username, password } = values;

    loginService.login(username, password).then((data) => {

      console.log("data ", data);

      if (!data.status) {
        setError(data.message);
        setLoading(false);
        return;
      }

			if (!error) {
        dispatch(setSessionUser({
          username: data.body.username,
          email: data.body.email,
          userId: data.body.userId,
          stationCode: data.body.stationCode,
        }))

				history.replace("/app");
			}
		});

  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
    console.log("error x", errorInfo);
    setLoading(false);
  };

  return (
		<Row justify="center" align="middle" style={{ height: "100vh", backgroundColor: "#EAEBEC" }}>
			<Col xs={24} sm={18} md={14} lg={12} xl={10}>
				<Section style={{ padding: 20, minWidth: 350 }}>
					<Title level={3} style={{ marginTop: 0, marginBottom: 5 }}>
						Login
					</Title>
					<Typography.Text type="secondary" style={{ fontSize: 12 }}>
						log in to Renger Franchise Management
					</Typography.Text>
					<br />
					<br />
					{error && (
						<Alert
							message="Error"
							description={error}
							type="error"
							closable
							// onClose={onClose}
						/>
					)}
					<br />
					<Form
						name="login"
						layout="vertical"
						initialValues={{ remember: true }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
            onChange={() => {
              setError("");
              setLoading(false);
            }}
					>
						<Form.Item
							label="Username"
							name="username"
							rules={[{ required: true, message: "Please input your username!" }]}
						>
							<Input  />
						</Form.Item>

						<Form.Item
							label="Password"
							name="password"
							rules={[{ required: true, message: "Please input your password!" }]}
						>
							<Input.Password />
						</Form.Item>

						{/* <Form.Item name="remember" valuePropName="checked">
							<Checkbox>Remember me</Checkbox>
						</Form.Item> */}

						<DFlex type="row" justifyContent="flex-end">
							<Button type="primary" htmlType="submit" loading={loading}>
								Submit
							</Button>
						</DFlex>

            <br />
					</Form>
				</Section>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>{`${buildLabel} : ${buildDate.format("YYYYMMDDHHmmss")}`}</Typography.Text>
			</Col>
		</Row>
	);
};

export default LoginForm;
