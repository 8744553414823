import { Col, Form, Input, Radio, Row, Select, Typography, Layout, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Franchisee } from "src/domain/franchiseeType";
import { LocationView } from "src/domain/locationViewType";
import franchiseeServices from "src/services/franchiseeServices";
import locationServices from "src/services/locationServices";
import stationServices from "src/services/stationServices";
import { SessionUser } from "src/store/authen/types";
import { showMessage, handleEnter } from "src/utils";
import Section from "../../components/container/Section";
import { Station } from "../../domain/stationType";
const { Option } = Select;

interface IProps {
	id: number;
	trigger: boolean;
	setTrigger: any;
}

const FormNimStation: React.FC<IProps> = (props) => {
	const { id, trigger, setTrigger } = props;
	console.log(" init ", id);
	const [station, setStation] = useState<Station>({} as Station);
	const history = useHistory();
	const { Title } = Typography;
	const [form] = Form.useForm();
	const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
	const [locationAddrPath, setLocationAddrPath] = useState(String);
	const [zipcode, setZipCode] = useState(String);
	const [validateCode, setValidateCode] = useState('');
	const [action, setAction] = useState(false);

	useEffect(() => {
		if (id !== 0) {
			setAction(true);
			getStation();
		}
		getAllLocations();
    	onSearchFranchisee(" ");
		
	}, []);

	const getStation = async () => {
		const res = await stationServices.getById(Number(id));
		console.log(" res ", res.data);
		setStation(res.data);
		setFranchiseeId(res.data.franchiseeId);

		const locationview = await locationServices.getByTambonCode(res.data.locationCode);
		setLocationCode(res.data.locationCode);
		setLocationAddrPath(locationview.data.addrPath);
		setZipCode(locationview.data.zipcode);
		console.log(" locationview.data.addrPath ", locationview.data.addrPath);
	};

	useEffect(() => {
		if (trigger) {
			form.submit();
		}
	}, [trigger]);

	useEffect(() => {
		if (id !== 0) {
			console.log(station);
			form.setFieldsValue({
				//id: station.id,
				stationCode: station.stationCode,
				stationName: station.stationName,
				stationNameEn: station.stationNameEn,
				zone: station.zone,
				address: station.address,
				addrPath: locationAddrPath,
				//zipcode  : station.zipcode,
				lat: station.lat,
				lng: station.lng,
				type: station.type,
				useFlg: station.useFlg,
				tel: station.tel,
				franchiseename: station.franchiseeName,
			});
		}
	}, [station, locationAddrPath]);

	const getAllLocations = async () => {
		const res = await locationServices.getAll();
		setLocationOptions(res.data);
	};
	// Select Frienchisee
	const [optionFranchisee, setOptionFranchisee] = useState<Array<Franchisee>>([]);
	const [franchiseeId, setFranchiseeId] = useState(Number);

	const onSearchFranchisee = async (searchText: string) => {
		console.log(" searchText " + searchText);
		const res = await franchiseeServices.getByParam(searchText, "", 0);
		setOptionFranchisee(res.data);
	};

	const onSelectFranchisee = (data: any) => {
		setFranchiseeId(data);
	};

	//location code
	const [locationCode, setLocationCode] = useState(String);
	const [locationOptions, setLocationOptions] = useState<Array<LocationView>>([]);
	const [locationFilterOptions, setLocationFilterOptions] = useState<Array<LocationView>>([]);

	const onSearchLocation = async (searchText: string) => {
		//const res = await locationServices.find(searchText);
		let search = locationOptions.filter((item) => item.addrPath.includes(searchText));
		setLocationFilterOptions(search);
	};

	const onSelectLocation = async (locationCode: any) => {
		if (locationCode !== null && locationCode.length > 0) {
			console.log(" selected locationcode ", locationCode);
			const res = await locationServices.getByTambonCode(locationCode);
			setLocationCode(res.data.tambonCode);
			setZipCode(res.data.zipcode);
		}
	};

	const onChangeCode = async () => {
		if (validateCode !== null && validateCode.length > 0) {
			const res = await stationServices.getByCode(validateCode);
			console.log(" 12 validateCode ", res.data)
			if (res.data !== null && res.data.id !== null) {
				showMessage(" รหัสสาขาซ้ำ " + validateCode);
				form.setFieldsValue({
					stationCode: "",
				});
			}
		}
	}

	const onFinish = async (values: any) => {
		const station_: Station = {
			id: id,
			stationCode: values.stationCode,
			stationName: values.stationName,
			stationNameEn: values.stationNameEn,
			zone: values.zone === undefined ? "" : values.zone,
			address: values.address,
			locationCode: locationCode,
			zipcode: zipcode,
			lat: values.lat === undefined ? "" : values.lat,
			lng: values.lng === undefined ? "" : values.lng,
			type: values.type,
			useFlg: values.useFlg === undefined ? 0 : values.useFlg,
			savStation: sessionUser.stationCode,
			tel: values.tel,
			franchiseeId: franchiseeId,
			franchiseeName: "",
			createUserid : sessionUser.userId,
			createUsername : sessionUser.username
		};
		console.log(" station_ ", station_);
		if (id === 0) {
			try {
				stationServices
					.addStation(station_)
					.then((res) => {
						history.replace("/app/station");
						message.success("Save Complete", 1.5);
					})
					.catch((err) => {
						console.log(err);
					});
			} catch (err) {
				showMessage("" + err);
			}
		}

		if (id !== 0) {
			stationServices
				.updateStation(station_)
				.then((res) => {
					history.replace("/app/station");
					message.success("Update Complete", 1.5);
				})
				.catch((err) => {
					console.log(err);
				});
		}
		setTrigger(false);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
		setTrigger(false);
	};

	return (
		<>
			<Form
				form={form}
				layout="vertical"
				name="basic"
				// initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 10]}>
					<Col span={16}>
						<Section>
							<Row gutter={[20, 0]}>
								<Col span={24}>
									<Form.Item
										label="ผู้ซื้อแฟรนไชส์"
										name="franchiseename"
										rules={[{ required: true, message: "กรุณาระบุสาขา ผู้ซื้อแฟรนไชส์" }]}
									>
										<Select
											filterOption={true}
											placeholder="ค้นหาด้วยชื่อ"
											optionFilterProp="children"
											onSearch={onSearchFranchisee}
											onSelect={onSelectFranchisee}
											showSearch
										>
											{optionFranchisee.map((obj: any) => {
												return <Option value={obj.id}>{obj.franchiseeName}</Option>;
											})}
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="ประเภท" name="type">
										<Radio.Group defaultValue={"DC"}>
											<Radio value={"DC"} checked>
												DC
											</Radio>
											<Radio value={"HUB"}>HUB</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col span={12}></Col>
								<Col span={12}>
									<Form.Item label="โซน" name="zone">
										<Input />
									</Form.Item>
								</Col>
							</Row>
						</Section>

						{/* <hr style={hrstyle} /> */}

						<Section style={{ marginTop: 20 }}>
							<Row gutter={[20, 0]}>
								<Col span={12}>
									<Form.Item name="stationCode" label="รหัสสาขา"
									rules={[{ required: true, message: "กรุณาระบุรหัสสาขา" }]}
									>
										<Input maxLength={5} 
											onKeyDown={handleEnter} 
											onChange={(e) => setValidateCode(e.target.value)} 
											onPressEnter={onChangeCode}
											disabled={action}
											onBlur={onChangeCode}
										/>
									</Form.Item>
								</Col>
								<Col span={12}></Col>

								<Col span={12}>
									<Form.Item
										label="ชื่อ NIM SHOP"
										name="stationName"
										rules={[{ required: true, message: "กรุณาระบุชื่อ NIM SHOP" }]}
									>
										<Input onKeyDown={handleEnter} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="ชื่อ NIM SHOP (English)"
										name="stationNameEn"
										rules={[{ required: true, message: "กรุณาระบุชื่อ NIM SHOP ภาษาอังกฤษ " }]}
									>
										<Input onKeyDown={handleEnter} />
									</Form.Item>
								</Col>
							</Row>
						</Section>

						<Section style={{ marginTop: 20 }}>
							<Row>
								<Col span={12}>
									<Form.Item
										label="เลขที่"
										name="address"
										rules={[
											{ required: true, message: "กรุณาระบุเลขที่" },
											{
												max: 255,
												message: "max 255",
											},
										]}
									>
										<Input maxLength={255} onKeyDown={handleEnter} />
									</Form.Item>
								</Col>
								<Col span={12}></Col>
							</Row>
							<Form.Item
								label="ที่อยู่"
								name="addrPath"
								rules={[{ required: true, message: "กรุณาค้นหา ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์" }]}
							>
								<Select
									placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
									optionFilterProp="children"
									filterOption={true}
									onSearch={onSearchLocation}
									onSelect={onSelectLocation}
									showSearch
								>
									{locationOptions.map((obj: any) => {
										return <Option value={obj.tambonCode}>{obj.addrPath}</Option>;
									})}
								</Select>
							</Form.Item>
							<Form.Item
								label="เบอร์โทร"
								name="tel"
								rules={[
									{ required: true, message: "กรุณาระบุเบอร์โทรศัพท์" },
									{
										max: 10,
										message: "max 10",
									},
								]}
							>
								<Input maxLength={10} onKeyDown={handleEnter} />
							</Form.Item>
						</Section>
					</Col>

					<Col span={8}>
						<Section>
							<Form.Item name="useFlg" label="สถานะ" initialValue={0}>
								<Radio.Group>
									<Radio value={0}>ใช้งาน</Radio>
									<Radio value={-1}>ไม่ใช้งาน</Radio>
								</Radio.Group>
							</Form.Item>
						</Section>

						<Section style={{ marginTop: 20 }}>
							<Form.Item name="lat" label="ละติจูด">
								<Input type="number" onKeyDown={handleEnter} />
							</Form.Item>

							<Form.Item name="lng" label="ลองติจูด">
								<Input type="number" onKeyDown={handleEnter} />
							</Form.Item>
						</Section>
					</Col>
				</Row>
			</Form>
		</>
	);
};

export default FormNimStation;
