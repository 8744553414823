import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader';
import FormBookingTruck from './FormBookingTruck';

const AddBookingTruck = () => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();

    return (
        <>
            <PageHeader title="เพิ่มข้อมูลตั้งรถส่วนกลาง (ขนย้าย)" subTitle='เพิ่มการตั้งรถส่วนกลาง'>
                <Space>
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={() => setTrigger(true)}
                        style={{ width: 120}}
                    >
                        บันทึก
                    </Button>
                    <Button

                        icon={<CloseCircleOutlined />}
                        onClick={() => history.goBack()}
                        style={{ width: 120}}
                    >
                        กลับ
                    </Button>
                </Space>
            </PageHeader>
            <Content>
                <Row>
                    <Col span={24}>
                        <FormBookingTruck id={0} trigger={trigger} setTrigger={setTrigger} />
                    </Col>
                </Row>

            </Content>
        </>
    )
}

export default AddBookingTruck;
