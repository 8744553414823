import React, {useState } from 'react'
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import FormFranchisee from './FormFranchisee';
import { RouteComponentProps, useHistory } from 'react-router';
import Section from '../../components/container/Section';

interface IParams {
    id: string;
  }

const EditFranchisee : React.FC<RouteComponentProps<IParams>> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();
    const id = Number(props.match.params.id);

    return (
			<>
				<PageHeader title="แก้ไข ผู้ซื้อแฟรนไชส์">
					<Space>
						<Button type="primary" icon={<SaveOutlined />} onClick={() => setTrigger(true)}>
							บันทึก
						</Button>
						<Button icon={<CloseCircleOutlined />} onClick={() => history.goBack()}>
							กลับ
						</Button>
					</Space>
				</PageHeader>
				<Row>
					<Col xs={24} sm={24} md={24} lg={18} xl={16} style={{ maxWidth: 800}}>
						<Section>
							<FormFranchisee id={id} trigger={trigger} setTrigger={setTrigger} />
						</Section>
					</Col>
				</Row>
			</>
		);
}

export default EditFranchisee;