import { red } from '@ant-design/colors';
import { CloseCircleOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Col, InputNumber, message, Row, Space, Table, Typography, Upload } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import filepriceServices from 'src/services/filepriceServices';
import filepriceZonePricelistServices from 'src/services/filepriceZonePricelistServices';
import productTypeServices from 'src/services/productTypeServices';
import templateExcelServices from 'src/services/templateExcelServices';
import { SessionUser } from 'src/store/authen/types';
import { showMessage } from 'src/utils';
import { currDate } from 'src/utils/dateUtils';
import { read, utils } from 'xlsx';
import Section from '../../../components/container/Section';
import { Fileprice } from '../../../domain/filepriceType';
import FilepriceViewHead from '../FilepriceViewHead';

interface IParams {
    id: string;
}

const ImportFilepriceValue: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id = Number(props.match.params.id);
    const history = useHistory();
    const [fileprice, setFileprice] = useState<Fileprice>({} as Fileprice)
    const [importFile, setImportFile] = useState([] as any)
    const [startRow, setStartRow] = useState<any>();
    const [endRow, setEndRow] = useState<any>();
    const [zonePricelists, setZonePricelists] = useState<any[]>([]);
    const [errorMsg, setErrorMsg] = useState<any[]>([]);
    const [isValid, setValid] = useState(false);
    const [isLoadingStatus, setLoadingStatus] = useState(false);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const importColumns = ['productTypeCode', 'regZoneCode', 'destZoneCode', 'sizeCode', 'priceAmt', 'typeCal', 'priceFactor', 'priceSrcReceiveAmt', 'priceSrcReceiveFactor', 'priceSmcAmt', 'priceSmcFactor', 'priceReceiveAmt', 'priceReceiveFactor'];

    const zonePricelistColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "ประเภทสินค้า", dataIndex: "productTypeCode", key: "productTypeCode",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "โซนต้นทาง", dataIndex: "regZoneCode", key: "regZoneCode",
            align: "center" as AlignType,
        },
        {
            title: "โซนปลายทาง", dataIndex: "destZoneCode", key: "destZoneCode",
            align: "center" as AlignType,
        },
        {
            title: "ขนาด", dataIndex: "sizeCode", key: "sizeCode",
            align: "center" as AlignType,
        },
        {
            title: "ราคา", dataIndex: "priceAmt", key: "priceAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "การคำนวน", dataIndex: "typeCal", key: "typeCal",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคา FACTOR", dataIndex: "priceFactor", key: "priceFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคารับสินค้า", dataIndex: "priceSrcReceiveAmt", key: "priceSrcReceiveAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "รับสินค้า FACTOR", dataIndex: "priceSrcReceiveFactor", key: "priceSrcReceiveFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคากระจายสินค้า", dataIndex: "priceSmcAmt", key: "priceSmcAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "กระจายสินค้า FACTOR", dataIndex: "priceSmcFactor", key: "priceSmcFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ราคารับสินค้าเอง", dataIndex: "priceReceiveAmt", key: "priceReceiveAmt",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "รับสินค้าเอง FACTOR", dataIndex: "priceReceiveFactor", key: "priceReceiveFactor",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>{value}</span>
                </Col>
            )
        },
    ];

    useEffect(() => {
        if (id !== undefined && id !== 0) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        const res = await filepriceServices.getById(id);
        setFileprice(res.data)
    }

    async function getProductType(val: string) {
        const res = await productTypeServices.getByCode(val)
        if (res.status === 200) {
            return await res.data;
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }
    async function isDuplicateZonePrice(productTypeCode: string, sizeCode: string, regZoneCode: string, destZoneCode: string) {
        const res = await filepriceZonePricelistServices.isDuplicate(id, productTypeCode, sizeCode, regZoneCode, destZoneCode);
        if (res.status === 200) {
            return res.data
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }
    const getColumnHeader = async (name: string) => {
        const res = await templateExcelServices.getByHeadNameAndEffDate(name, currDate())
        if (res.status === 200) {
            return await res.data;
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }

    const importExcel = (file: any) => {
        const fileReader = new FileReader();
        fileReader.onload = async (event) => {
            try {
                const result = event.target?.result;
                const workbook = read(result, { type: "binary" });
                const sheetName = workbook.SheetNames[0];// ชีทแรก ที่ index 0
                const ws = workbook.Sheets[sheetName];
                const r = ws['!ref'] ? ws['!ref'] : null;
                let ranges = undefined;
                if (r != null && (startRow || endRow)) {
                    ranges = await calRange(utils.decode_range(r));
                }
                if (workbook.Sheets.hasOwnProperty(sheetName)) {
                    const data = utils.sheet_to_json(ws, { header: importColumns, range: ranges })
                    console.log(data);
                    await generateList(data);
                }
                message.success("upload success!");
            } catch (e) {
                message.error("file type is incorrect!");
            }
            setLoadingStatus(false);
        };
        fileReader.readAsBinaryString(file);
    };

    async function calRange(sheetRange: any) {
        const sRow = startRow ? startRow : sheetRange.s.r;
        const eRow = endRow ? endRow : sheetRange.e.r;
        let headSize = 12; // // colum index 0 - 12;
        const templateExcelDetails = await getColumnHeader('fileprice_zone_pricelist');
        if (templateExcelDetails !== null && templateExcelDetails.lenght > 0) {
            headSize = templateExcelDetails.lenght - 1;
        }
        return { s: { c: 0, r: sRow }, e: { c: headSize, r: eRow } }
    }

    async function generateList(data: any) {
        setZonePricelists([]);
        setErrorMsg([]);
        if (data.length > 0) {
            let isSave = true;
            let map: any[] = [];
            let err: any[] = [];
            for (let i = 0; i < data.length; i++) {
                const chk = await isDuplicateZonePrice(data[i].productTypeCode, data[i].sizeCode, data[i].regZoneCode, data[i].destZoneCode)
                if (chk && chk !== false) {
                    err.push(`แถว ${i + 1} รหัสสินค้า ${data[i].productTypeCode} ขนาด ${data[i].sizeCode} ต้นทาง ${data[i].regZoneCode} ปลายทาง ${data[i].destZoneCode} มีในฐานข้อมูลแล้ว`);
                    isSave = false;
                }
                const productType = await getProductType(data[i].productTypeCode)
                if (productType && productType.productTypeName != null) {
                    data[i].productTypeName = productType.productTypeName;
                } else {
                    err.push(`แถว ${i + 1} ประเภทสินค้า	 ${data[i].productTypeCode} ไม่พบใน Product Type`);
                    isSave = false;
                }
                for (let j = 0; j < data.length; j++) {
                    if (data[j].productTypeCode === data[i].productTypeCode && data[j].sizeCode === data[i].sizeCode
                        && data[j].regZoneCode === data[i].regZoneCode && data[j].destZoneCode === data[i].destZoneCode
                        && i !== j) {
                        data[i].isDuplicate = true;
                        if (i < j) {
                            err.push(`แถว ${i + 1} มีข้อมูลซ้ำกับแถว ${j + 1}`);
                            isSave = false;
                        }
                    }
                }
                map.push(data[i]);
            }
            setValid(isSave);
            setErrorMsg(err);
            setZonePricelists(map);
        }
    }


    const uploadProps: UploadProps = {
        onRemove: () => {
            onRemoveImport();
            return false;
        },
        beforeUpload: () => {
            return false;
        },
    };
    function onFileChange(file: any) {
        setLoadingStatus(true);
        setImportFile(file.fileList);
        importExcel(file.file);
    }
    function onRemoveImport() {
        setImportFile([] as any);
        setZonePricelists([]);
        setErrorMsg([]);
        setValid(false)
    }
    const handleSetStartRow = (val: number | null) => {
        if (val != null && val > 0) {
            setStartRow(val - 1);
        } else {
            setStartRow(undefined);
        }
    }
    const handleSetEndRow = (val: number | null) => {
        if (val != null && val > 0) {
            setEndRow(val - 1);
        } else {
            setEndRow(undefined);
        }
    }

    const handleSave = () => {
        onSaveArea();
    }

    function onSaveArea() {
        let _save: any[] = [];
        zonePricelists.forEach((item: any) => {
            _save.push({
                fileCode: fileprice.fileCode,
                productTypeCode: item.productTypeCode,
                regZoneCode: item.regZoneCode,
                destZoneCode: item.destZoneCode,
                sizeCode: item.sizeCode,
                priceAmt: item.priceAmt,
                typeCal: item.typeCal,
                priceFactor: item.priceFactor !== undefined ? item.priceFactor : null,
                priceSrcReceiveAmt: item.priceSrcReceiveAmt,
                priceSrcReceiveFactor: item.priceSrcReceiveFactor !== undefined ? item.priceSrcReceiveFactor : null,
                priceSmcAmt: item.priceSmcAmt,
                priceSmcFactor: item.priceSmcFactor !== undefined ? item.priceSmcFactor : null,
                priceReceiveAmt: item.priceReceiveAmt,
                priceReceiveFactor: item.priceReceiveFactor !== undefined ? item.priceReceiveFactor : null,
                filepriceId: id,
                createUserid: sessionUser.userId,
                createUsername: sessionUser.username,
                savStation: sessionUser.stationCode,
            })
        })

        // console.log('_save', _save)
        try {
            filepriceZonePricelistServices.addFilepriceZonePricelist(_save).then((res: any) => {
                if (res.status === 200) {
                    onRemoveImport();
                    message.success("บันทึกข้อมูลแล้ว");
                }
            }).catch((err: any) => {
                message.warning('Service : ' + err)
            });
        } catch (err) {
            message.warning('บันทึกข้อมูลไม่สำเร็จ')
        }
    }

    return (
        <>
            <PageHeader title="นำเข้าข้อมูล - ราคาสินค้า" >
                <Space>
                    <Button
                        size="large"
                        style={{ width: 120 }}
                        icon={<CloseCircleOutlined />}
                        onClick={() => history.goBack()}
                    >
                        กลับ
                    </Button>
                </Space>
            </PageHeader>

            <Section>
                <Row>
                    <Col span={24}>
                        <FilepriceViewHead fileprice={fileprice}></FilepriceViewHead>
                    </Col>
                </Row>
            </Section>
            <br />
            <Row gutter={24}>
                <Col span={6}>
                    <Section>
                        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={24} style={{ paddingLeft: "5px", }} >
                                    <Typography.Title level={5} style={{ margin: 0 }}>จำนวนแถว</Typography.Title>
                                </Col>
                            </Row>

                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                                    <Typography.Text>เริ่ม :</Typography.Text>
                                </Col>
                                <Col span={18} style={{ display: 'flex' }}>
                                    <InputNumber style={{ flex: 1 }} onChange={handleSetStartRow} />
                                </Col>
                            </Row>

                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                                    <Typography.Text>ถึง :</Typography.Text>
                                </Col>
                                <Col span={18} style={{ display: 'flex' }}>
                                    <InputNumber style={{ flex: 1 }} onChange={handleSetEndRow} />
                                </Col>
                            </Row>

                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={24}  >
                                    <Upload {...uploadProps} fileList={importFile} onChange={onFileChange} maxCount={1} accept=".cvs, .xlsx, .xls" >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                </Col>
                            </Row>
                        </Space>
                    </Section>
                </Col>
                <Col span={18}>
                    <Section>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Row>
                                <Col span={24} style={{ paddingTop: 10 }}>
                                    {errorMsg.length <= 0 ? <></> :
                                        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                            {errorMsg.map((item: any) => (
                                                <>
                                                    <Typography.Text type={'danger'} >{item}</Typography.Text>
                                                </>
                                            ))}
                                        </Space>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Table
                                        size='small'
                                        dataSource={zonePricelists}
                                        loading={isLoadingStatus}
                                        columns={zonePricelistColumns}
                                        rowKey={(item: any) => item.id}
                                        pagination={false}
                                        // pagination={{ pageSize: 10, defaultCurrent: 1 }}
                                        // scroll={{ x: '100vw', y:'40vh' }}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                style: { background: record.isDuplicate === true ? red[3] : 'white' }
                                            };
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} style={{ display: "flex", justifyContent: "flex-end", alignItems: 'flex-start' }}>
                                    <Space size="large" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            onClick={() => handleSave()}
                                            style={{ width: 120 }}
                                            disabled={!isValid}
                                        >
                                            บันทึก
                                        </Button>
                                        <Button
                                            onClick={() => onRemoveImport()}
                                            style={{ width: 120 }}
                                        >
                                            ยกเลิก
                                        </Button>
                                    </Space >
                                </Col>
                            </Row>
                        </Space>
                    </Section>
                </Col>
            </Row>
        </>
    )
}

export default ImportFilepriceValue
