import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'bookingTruck';

const getAll = () => {
    return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (sDate: any, eDate: any, truckCode: any, registId: any, bookingType: any, driverName: any, useFlg: any) => {
    const param = `sDate=${sDate}&eDate=${eDate}&truckCode=${truckCode}&registId=${registId}&bookingType=${bookingType}&driverName=${driverName}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
};

const addBookingTruck = (bookingTruck: any) => {
    return http.post(url + "/add", bookingTruck);
};

const updateBookingTruck = (bookingTruck: any) => {
    return http.put(url + "/update", bookingTruck);
};

const updateBookingTruckStatus = (id: number, useFlg: number, createUserid: number, createUsername: string, savStation: string) => {
    const param = `id=${id}&useFlg=${useFlg}&createUserid=${createUserid}&createUsername=${createUsername}&savStation=${savStation}`;
    return http.put(url + "/updateBookingTruckStatus/?" + param);
}
const bookingTruckServies = { getAll, getById, getByParam, addBookingTruck, updateBookingTruck, updateBookingTruckStatus, };
export default bookingTruckServies;
