import http from '../lib/http';
import axios from 'axios';
import { servicePort } from './configServices';

const url = servicePort + 'reportsmcrelease';


const getReportSmcReleaseCenter = async (params: any) => {
    let url1 = `${url}/byparam`;
    let res = await axios.post(url1, params);
    console.log(res.data)
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}

export default { getReportSmcReleaseCenter, }