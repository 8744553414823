import { Col, Form, Input, Radio, Row, Layout, message } from "antd";
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import bankServices from "src/services/bankServices";
import { SessionUser } from "src/store/authen/types";
import { showMessage, handleEnter } from "src/utils";
import { Bank } from "../../domain/bankType";
import Section from "../../components/container/Section";

interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FormBank: React.FC<IProps> = (props) => {
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser)
  const { id, trigger, setTrigger } = props;
  console.log(id)
  const [bank, setBank] = useState<Bank>({} as Bank);
  const history = useHistory();
  const [form] = Form.useForm();
  const [action, setAction] = useState(false);

  useEffect(() => {
    console.log(id)
    bankServices.getById(Number(id))
      .then((res) => {
        setBank(res.data)
      });
  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    console.log(bank)
      if(id === 0){
  
      }else {
      setAction(true);
      form.setFieldsValue({
        id: bank.id,
        bankCode : bank.bankCode,
        bankName : bank.bankName,
        useFlg : bank.useFlg,
      });
    }
  }, [bank]);


  const onFinish = async (values: any) => {
    console.log("Success:", values);
    const bank_: Bank = {
      id: id,
      bankCode: values.bankCode,
      bankName: values.bankName,
      useFlg: values.useFlg,
      savStation: sessionUser.stationCode,
      createUserid: sessionUser.userId,
      createUsername: sessionUser.username
    };

    if (id === 0) {
      try {
        bankServices.addBank(bank_).then(res => {
          history.replace("/app/bank");
          message.success("Save Bank Complete", 1.5);
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        showMessage(''+err);
      }
    }

    if (id !== 0) {
      bankServices.updateBank(bank_).then(res => {
        console.log("update bank ", res.data);
        history.replace("/app/bank");
        message.success("Update Complete", 1.5);
      }).catch(err => {
        console.log(err)
      });

    }
    setTrigger(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
        <Form

          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
        
       <Row gutter={[20, 10]}>
						<Col span={16}>
            <Section>
                <Form.Item name="bankCode" label="รหัสธนาคาร"
                  rules={[
                    { required: true, message: "กรุณาระบุรหัสธนาคาร" },
                  ]}
                >
                  <Input  onKeyDown={handleEnter} maxLength={5} disabled={action}/>
                </Form.Item>
                <Form.Item  label="ชื่อธนาคาร" name="bankName"
                  rules={[
                    { required: true, message: "กรุณาระบุชื่อธนาคาร" },
                  ]}
                >
                  <Input onKeyDown={handleEnter} maxLength={150} />
                </Form.Item>
                </Section>
            </Col>
            <Col span={8}>
              <Section>
                <Form.Item name="useFlg" label="สถานะ">
                  <Radio.Group defaultValue={0} >
                    <Radio value={0} >ใช้งาน</Radio>
                    <Radio value={-1}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Section>
            </Col>
          </Row>
     </Form>
    </>
  )
}

export default FormBank;