import { Col, Form, Input, Radio, Row, Select, Layout, message } from "antd";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router";
import { Bank } from "src/domain/bankType";
import { AccountBank } from "../../domain/accountBankType";
import accountBankServices from "src/services/accountBankServices";
import bankServices from "src/services/bankServices";
import { showMessage, handleEnter } from "src/utils";
import { SessionUser } from "src/store/authen/types";
import { useSelector } from "react-redux";
import stationServices from "src/services/stationServices";
import { Station } from "src/domain/stationType";
import Section from "../../components/container/Section";

const { Option } = Select;
interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FormAccountBank: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  console.log(id)
  const [accountBank, setAccountBank] = useState<AccountBank>({} as AccountBank);
  const history = useHistory();
  const [form] = Form.useForm();
  // const [bank, setBank] = useState<Bank>({} as Bank);
  const [bankId, setBankId] = useState(Number);
  const [action, setAction] = useState(false);
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

  useEffect(() => {
    if (id != 0) {
      accountBankServices.getById(Number(id))
        .then((res) => {
          setAccountBank(res.data);
          setBankId(res.data.bankId);
          setStationCode(res.data.accountStation);
        });
        
    }
    getAllBanks();
    getAllStation();
  }, []);


  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);


  useEffect(() => {
    if (id === 0) {

    } else {
      setAction(true);
      console.log("", accountBank);
      form.setFieldsValue({
        id: accountBank.id,
        accountNo: accountBank.accountNo,
        accountName: accountBank.accountName,
        bankName : accountBank.bankName,
        branchBank: accountBank.branchBank,
        accountStation: accountBank.accountStationName,
        accountCode : accountBank.accountCode,
        useFlg : accountBank.useFlg
      });
    }

  }, [accountBank]);

  const getAllBanks = async () => {
    const res = await bankServices.getAll();
    setOptions(res.data);
  }

  const getAllStation = async () => {
    const res = await stationServices.getAll();
    setOptionStation(res.data);
  }

  const [options, setOptions] = useState<Array<{ value: string, objectvalue: Bank }>>([]);

  const onSearch = async (searchText: string) => {
    console.log(" searchText " + searchText);
    const res = await bankServices.getByParam(searchText, 1)
    setOptions(res.data);
  };
  const onSelect = (selectedId: any) => {
    setBankId(selectedId);
    console.log(bankId);
  };

  // Search Station
  const [optionStation, setOptionStation] = useState<Array<Station>>([]);
  const [stationCode, setStationCode] = useState(String);

  const onSearchStation = async (searchText: string) => {
    console.log(" searchText " + searchText);
    const res = await stationServices.getByParam("", searchText, "", "", 0);
    setOptionStation(res.data);
  };
  const onSelectStation = (data: any) => {
    setStationCode(data);
  };

  const onFinish = async (values: any) => {
    console.log(id)

    const accountBank_: AccountBank = {

      id: id,
      bankId: bankId,
      bankName: values.bankName,
      accountNo: values.accountNo,
      accountName: values.accountName,
      branchBank: values.branchBank,
      accountCode: values.accountCode,
      accountStation: stationCode,
      useFlg: values.useFlg,
      savStation: sessionUser.stationCode, 
      accountStationName : "",
      createUserid: sessionUser.userId,
      createUsername: sessionUser.username
    };

    if (id === 0) {
      try {
        console.log("",accountBank_);
        accountBankServices.addAccountBank(accountBank_).then(res => {
          history.replace("/app/accountbank");
          message.success("Save Complete", 1.5);
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        showMessage(''+err);
      }
    }

    if (id !== 0) {
      accountBankServices.updateAccountBank(accountBank_).then(res => {
        history.replace("/app/accountbank");
        message.success("Update Complete", 1.5);
      }).catch(err => {
        console.log(err)
      });

    }
    setTrigger(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
        <Form

          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >

        <Row gutter={[20, 10]}>
						<Col span={16}>
              <Section>
                  <Form.Item name="accountCode" label="Code" 
                      rules={[
                        { required: true, message: "กรุณาระบุรหัส" },
                      ]}
                  >
                    <Input onKeyDown={handleEnter} maxLength={15}/>
                  </Form.Item>
                  <Form.Item label="ธนาคาร" name="bankName"
                    rules={[
                      { required: true, message: "กรุณาเลือกธนาคาร" },
                    ]}
                  >
                    <Select
                        showSearch
                        size="middle"
                        placeholder="ค้นหาด้วยชื่อธนาคาร"
                        optionFilterProp="children"
                        filterOption={true}
                        onSearch={onSearch}
                        onSelect={onSelect}
                      >
                        {options.map((obj: any) => {
                          return (
                            <Option value={obj.id}>
                              {obj.bankName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="สาขาธนาคาร"
                    name="branchBank"
                    rules={[
                      { required: true, message: "กรุณาระบุสาขาธนาคาร" },
                    ]}
                  >
                    <Input onKeyDown={handleEnter}/>
                  </Form.Item>
                  <Form.Item
                    label="ชื่อบัญชี"
                    name="accountName"
                    rules={[
                      { required: true, message: "กรุณาระบุชื่อบัญชี" },
                    ]}
                  >
                    <Input onKeyDown={handleEnter}/>
                  </Form.Item>
                  <Form.Item
                      label="เลขที่บัญชี"
                      name="accountNo"
                      rules={[
                        { required: true, message: "กรุณาระบุเลขที่บัญชี" },
                      ]}
                    >
                      <Input onKeyDown={handleEnter}/>
                    </Form.Item>
                    <Form.Item
                        label="สาขา (NIMSHOP)"
                        name="accountStation"
                        rules={[
                          { required: true, message: "กรุณาระบุสาขา NIMSHOP" },
                        ]}
                      >
                        <Select
                            showSearch
                            size="middle"
                            placeholder="ค้นหาด้วยชื่อ NIMSHOP"
                            optionFilterProp="children"
                            filterOption={true}
                            onSearch={onSearchStation}
                            onSelect={onSelectStation}
                          >
                            {optionStation.map((obj: any) => {
                              return (
                                <Option value={obj.stationCode}>
                                  {obj.stationName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                </Section>
            </Col>
            <Col span={8}>
              <Section>
                <Form.Item name="useFlg" label="สถานะ" initialValue={0}>
                    <Radio.Group>
                      <Radio value={0} >ใช้งาน</Radio>
                      <Radio value={-1}>ไม่ใช้งาน</Radio>
                    </Radio.Group>
                  </Form.Item>
              </Section>
            </Col>
        </Row>
        </Form>
    </>
  )
}

export default FormAccountBank;