import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'location';

const find = (text : string) => {
    return http.get(`${url}/find/${text}`);
}

const getByTambonCode = (tambonCode : any) => {
    const param = `tambonCode=${tambonCode}`;
    return http.get(url + "/getByTambonCode/?" + param);
};

const getLocations = () => {
    return http.get(url + "/getLocations");
}

const getAll = () => {
    return http.get(url + "/all");
}

export default {
    find,
    getByTambonCode,
    getLocations,
    getAll,
}