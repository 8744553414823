import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'filepriceZonePricelist';

const getZonePricelistByFilter = (filepriceId: number, productTypeCode: string, fileCode: string, regZoneCode: string, destZoneCode: string, sizeCode: string) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&fileCode=${fileCode}&regZoneCode=${regZoneCode}&destZoneCode=${destZoneCode}&sizeCode=${sizeCode}`;
    return http.get(url + "/byParam/?" + param);
};
const getDestZoneByFilter = (filepriceId: any, productTypeCode: any, regZoneCode: any) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&regZoneCode=${regZoneCode}`;
    return http.get(url + "/getDestZoneByFilter/?" + param);
};
const isDuplicate = (filepriceId: number, productTypeCode: string, sizeCode: string, regZoneCode: string, destZoneCode: string) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&sizeCode=${sizeCode}&regZoneCode=${regZoneCode}&destZoneCode=${destZoneCode}`;
    return http.get(url + "/isDuplicate/?" + param);
};
const addFilepriceZonePricelist = (list: any) => {
    return http.post(url + "/add", list);
};
const getZonePricelistAllsizing = (filepriceId: number, productTypeCode: string, regZoneCode: string, destZoneCode: string,) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&regZoneCode=${regZoneCode}&destZoneCode=${destZoneCode}`;
    return http.get(url + "/getlistsAllsizing/?" + param);
}
const updateFilepriceZonePricelist = (list: any) => {
    return http.post(url + "/update", list);
};

export default { getZonePricelistByFilter, getDestZoneByFilter, isDuplicate, addFilepriceZonePricelist, getZonePricelistAllsizing, updateFilepriceZonePricelist }