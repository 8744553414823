import {
  Col, Row, Form, Input, Select, Radio, Image, Typography, Switch
} from 'antd'
import React, { useEffect, useState } from 'react'
import Section from 'src/components/container/Section'
import { DriverLicenseRequest, DriverRequest } from 'src/domain/fleetRequestDriverType';
import fleetRequestDriverService from 'src/services/fleetRequestDriverService';

interface IProps {
  driverUId: string;
  trigger: boolean;
  setTrigger: any;
}

const { Text } = Typography;

const FleetDriverInfo: React.FC<IProps> = (props) => {

  const { driverUId, trigger, setTrigger } = props;
  const [form] = Form.useForm();
  const [driver,setDriver] = useState<DriverRequest>();
  const [driverLincend,setDriverLincend] = useState<DriverLicenseRequest>();

  const [addsPathData, setAddsPathData] = useState<any>();
  const [locationCode, setLocationCode] = useState<any>();
  const [zipCodeData, setzipCodeData] = useState<any>();

  useEffect(() => {

    console.log("driverUId >>",driverUId)

    fleetRequestDriverService.getDriverDataFromFleet(driverUId)
    .then((res) => {
        setDriver(res.data)
        console.log(res.data)
    })

    fleetRequestDriverService.getDriverlicenseFromFleet(driverUId)
    .then((res) => {
      setDriverLincend(res.data)
        console.log(res.data)
    })


 }, []);

//  useEffect(() => {

//   if (driverUId === "") {
//         console.log(driverUId)
//   } else {
     
//       setAddsPathData(driver?.addrPathStr);
//       setLocationCode(driver?.locationCode);
//       setzipCodeData(driver?.zipcode);

//       console.log(addsPathData)
    

//       console.log(driver);
//   }

// }, [driver]);


  return (
    <>
      <Form

        // {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        layout="vertical"
      >

        <Row gutter={[20, 10]}>
          
          <Col span={24}>
            <Section>
              <Form.Item name="address" label="ที่อยู่">
                <Text style={{fontSize:"large"}}>{(driver?.address && driver?.address)} </Text>
                <Text style={{fontSize:"large"}}>{(driver?.addrPathStr && driver?.addrPathStr)}</Text>
              </Form.Item>
              <Form.Item  name="tel" label="เบอร์โทร">
                <Text style={{fontSize:"large"}}>{driver?.tel && driver?.tel }</Text>
              </Form.Item>
              <Form.Item
                label="เลขที่ใบขับขี่"
                name="drvLicense"
              >
                 <Text style={{fontSize:"large"}}>{driverLincend?.drvLicenseId && driverLincend?.drvLicenseId}</Text>
              </Form.Item>
              <Form.Item
                label="วันที่อนุญาต"
                name="issueDate"
              >
                 <Text style={{fontSize:"large"}}>{driverLincend?.issueDate && driverLincend?.issueDate }</Text>
              </Form.Item>
              <Form.Item
                label="วันที่หมดอายุ"
                name="expireDate"
              >
               <Text style={{fontSize:"large"}}>{driverLincend?.expireDate && driverLincend?.expireDate}</Text>
              </Form.Item>
            </Section>
          </Col>

        </Row>
      </Form>
    </>
  )
}

export default FleetDriverInfo