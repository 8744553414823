import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Station } from '../../domain/stationType';
import { Link } from 'react-router-dom';
import stationServices from 'src/services/stationServices';
import Section from '../../components/container/Section';

const flg = [
    { value: '', name: 'ทั้งหมด' },
    { value: -1, name: 'ไม่ใช้งาน' },
    { value: 0, name: 'ใช้งาน' },
    { value: 1, name: 'ใช้งานแล้ว' }
];

const NimStationList = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [stations, setStations] = useState([])

    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            render: (value: number, item: Station, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "stationCode",
            key: "stationCode",
        },
        {
            title: "ชื่อ NIM SHOP",
            dataIndex: "stationName",
            key: "stationName",
            render: (value: string, item: Station, index: number) => <Link to={`/app/station/edit/${item.id}`}>{value}</Link>,
        },
        {
            title: "สถานะ", dataIndex: "useFlg", key: "useFlg",
            render: (value: string, item: any, index: number) => {
                const name = flg.find((item) => (item.value === value))
                return <span>{name?.name}</span>;
            },
        },

    ];

    useEffect(() => {
        stationServices.getAll()
            .then((res) => {
                console.log(res.data);
                setStations(res.data);
            })

    }, []);

    const changeSearchText = (searchText: any) => {
        setSearchName(searchText);
        //search(searchName, status);
    }
    const changeUseFlg = (status: any) => {
        setStatus(status);
        //search(searchName, status);
    }

    const search = () => {
        stationServices.getByParam("", searchName, "", "", status)
            .then((res) => {
                setStations(res.data)
            })
    }

    return (
			<div>
				<PageHeader title="กำหนดสาขา NIM SHOP" subTitle="เพิ่ม แก้ไข เปลี่ยนแปลงข้อมุล Shop">
					<Button
						size="large"
						style={{ width: 120 }}
						type="primary"
						icon={<PlusCircleOutlined />}
						onClick={() => {
							history.push("/app/station/add");
						}}
					>
						เพิ่ม
					</Button>
				</PageHeader>

				<Section>
					<Form layout="vertical">
						<Row style={{ display: 'flex', alignItems: 'flex-start' }}>
							<Col span={9}>
								<Form.Item label="ชื่อ">
									<Input
										style={{
											borderRadius: "5px",
											width: "300px",
										}}
										name="searchName"
										onChange={(e) => changeSearchText(e.target.value)}
									/>
								</Form.Item>
                            </Col>
                            <Col span={9}>
								<Radio.Group
                                    name="status"
                                    defaultValue={0}
                                    onChange={(e) => changeUseFlg(e.target.value)}
                                    style={{ marginTop: 30}}>
									<Space>
										<Radio value={0}>ใช้งาน</Radio>
										<Radio value={-1}>ไม่ใช้งาน</Radio>
									</Space>
								</Radio.Group>
							</Col>
							<Col span={6} style={{ display: 'flex', justifyContent: 'flex-end'}}>
								<Button
                                    icon={<SearchOutlined />}
                                    onClick={() => search()}
                                    style={{ marginTop: 30 }}>
									ค้นหา
								</Button>
							</Col>
						</Row>
					</Form>
				</Section>

				<Section style={{ marginTop: 30 }}>
					<Table
                        columns={columns}
                        dataSource={stations}
						pagination={{
							pageSize: 20,
							onChange(current) {
								setPage(current);
							},
						}}
						rowKey={(item: Station) => item.id}
                        size="small"
					/>
				</Section>
			</div>
		);
}

export default NimStationList;
