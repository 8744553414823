import { blue, red } from '@ant-design/colors';
import { CloseOutlined, EditFilled } from '@ant-design/icons';
import { Button, ConfigProvider, message, Tabs, TabsProps } from 'antd';
import React, { useState } from 'react';
import filepriceServices from 'src/services/filepriceServices';
import Section from '../../components/container/Section';
import FilepriceAreaView from './tab/FilepriceAreaView';
import FilepriceSizeView from './tab/FilepriceSizeView';
import FilepriceValueView from './tab/FilepriceValueView';

interface IProps {
    filepriceId: any;
    fileCode: any;
}

const FilepriceViewDetail: React.FC<IProps> = (props) => {
    const { filepriceId, fileCode } = props;
    const [activeTab, setActiveTab] = useState('1');
    const [isEditing, setIsEditing] = useState(false);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'เปอร์เซ็นต์ AREA',
            children: <FilepriceAreaView filepriceId={filepriceId} isEdit={isEditing} setIsEditing={setIsEditing} />,
        },
        {
            key: '2',
            label: 'ขนาดสินค้า',
            children: <FilepriceSizeView filepriceId={filepriceId} fileCode={fileCode} isEdit={isEditing} setIsEditing={setIsEditing} />,
        },
        {
            key: '3',
            label: 'ราคาสินค้า',
            children: <FilepriceValueView filepriceId={filepriceId} fileCode={fileCode} isEdit={isEditing} setIsEditing={setIsEditing} />,
        },
    ];

    async function fetchData() {
        const res = await filepriceServices.isUsedFileprice(filepriceId);
        if (res.status === 200) {
            return await res.data;
        } else {
            return null;
        }
    }

    const handleCheckIsUsed = async () => {
        const isUsed = await fetchData();
        if (isUsed === false) {
            setIsEditing(true)
        } else {
            if ('2' !== activeTab) {
                message.warning('แฟ้มนี้ถูกใช้งานไปแล้ว')
            } else {
                setIsEditing(true)
            }
        }
    }

    return (
        <>
            <Section style={{ justifyContent: 'space-between' }}>
                <Tabs defaultActiveKey="1" items={items} type="card"
                    onTabClick={(key) => {
                        setActiveTab(key);
                        setIsEditing(false);
                    }}
                    tabBarExtraContent={!isEditing ?
                        <Button shape="circle" size='middle' icon={<EditFilled style={{ color: blue.primary }} />} onClick={() => handleCheckIsUsed()} />
                        :
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: red.primary,
                                    colorPrimaryHover: red.primary,
                                },
                            }}
                        >
                            <Button shape="circle" size='middle' icon={<CloseOutlined style={{ color: red.primary }} />} onClick={() => setIsEditing(false)} />
                        </ConfigProvider>
                    }
                />
            </Section>
        </>
    )
}

export default FilepriceViewDetail
