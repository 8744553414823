import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort+'customer';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (name : any , useFlg : number) => {
    const param = `name=${name}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
    
  };

const addCustomer = (Customer: any) => {
    return http.post(url + "/add", Customer);
};

const updateCustomer = (Customer: any) => {
    return http.put(url + "/update", Customer);
};

// CustomerTel
const getCustomerTelByCustomerId = (id : number) => {
    return http.get(`${url}/getCustomerAndTel/${id}`);
}
const addCustomerTel = (CustomerTel : any) => {
    return http.post(url + "/customertel/add", CustomerTel);
};

const deleteCustomerTel= (id: number) => {
    return http.put(url + "/deletecusttel?telId="+ id);
};

export default {
    getAll,
    getById,
    getByParam,
    addCustomer,
    updateCustomer,
    getCustomerTelByCustomerId,
    addCustomerTel,
    deleteCustomerTel
}