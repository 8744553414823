import { Checkbox, Col, Form, Select, Radio, Row, Button, Typography, Layout, message, Input, Table, Space } from "antd";
import React, { useEffect, useState } from 'react'
import { Station } from "src/domain/stationType";
import stationAreaServices from "src/services/stationAreaServices";
import stationServices from "src/services/stationServices";
import { showMessage, handleEnter } from "src/utils";
import { StationArea } from "../../domain/stationAreaType";
import { PlusCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { LocationView } from "src/domain/locationViewType";
import locationServices from "src/services/locationServices";
import Section from "../../components/container/Section";
import { SessionUser } from "src/store/authen/types";
import { useSelector } from "react-redux";
import classCss from "../bookingtruck/ListBookingTruck.module.css";

const { Option } = Select;

interface IProps {
  stationcode: string;
  trigger: boolean;
  setTrigger: any;
}

const hrstyle = {
  borderColor: "#ccc",
  borderStyle: "solid",
  borderBottom: 0,
};

const FormStationArea: React.FC<IProps> = (props) => {
  const { stationcode, trigger, setTrigger } = props;
  const [stationArea, setStationArea] = useState<StationArea>({} as StationArea);
  const [form] = Form.useForm();
  const [stationAreas, setStationAreas] = useState<StationArea[]>([]);
  const [selectId, setSelectId] = useState(Number);
  const [page, setPage] = React.useState(1);
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [action, setAction] = useState(false);
  const [itemAction, setItemAction] = useState(false);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: number, item: StationArea, index: number) => (<>{(page - 1) * 20 + index + 1}</>)
    },
    {
      title: "Line Code",
      dataIndex: "lineCode",
      key: "lineCode",
    // align: "center" as "center",
    },
    {
      title: "Location",
      dataIndex: "locationFullName",
      key: "locationFullName",
      render: (value: string, item: StationArea, index: number) => {
        return (
          <div onClick={() => {
            console.log('edit item')
            onEditItem(item.id)

          }} style={{ cursor: "pointer" }}>
            {value}
          </div>
        )
      },
    },
    {
      title: "ส่งสินค้า",
      align: "center" as "center",
      dataIndex: "serviceHome",
      key: "serviceHome",
      render: (value: any, item: StationArea, index: number) => {
        if (item.serviceHome === 1) {
          return <span><CheckOutlined /></span>;
        } else {
          return <span><CloseOutlined /></span>;
        }
      },
    },
    {
      title: "รับที่สาขา",
      align: "center" as "center",
      dataIndex: "serviceStation",
      key: "serviceStation",
      render: (value: any, item: StationArea, index: number) => {
        if (item.serviceStation === 1) {
          return <span><CheckOutlined /></span>;
        } else {
          return <span><CloseOutlined /></span>;
        }
      },
    },
  ];

  useEffect(() => {
    getAllLocations();
    console.log(' station code ', stationcode)
    if (stationcode != "") {
      setAction(true);
      setSelectId(0);
      getListStationArea();
    } else {
      setAction(false);
      getAllStation();
    }

  }, []);

  const getListStationArea = () => {
    console.log(" station code " + stationcode);
    if (stationcode != "") {
      stationAreaServices.getByStationCode(stationcode)
        .then((res) => {
          setStationAreas(res.data);
          setStationArea(res.data[0]);
          setStationCode(stationcode);
        })
    }
  }

  const getAllLocations = async () => {
    const res = await locationServices.getAll();
    setLocationOptions(res.data);
  }

  const getAllStation = async () => {
    const res = await stationServices.getAll();
    setOptionStation(res.data);
  }

  const onEditItem = async (selectedId: number) => {
    setItemAction(true);
    const res = await stationAreaServices.getById(selectedId)
      .then((res) => {
        setStationArea(res.data);
      })
    setSelectId(selectedId);
    form.setFieldValue('locationFilter', "");
  }

  // Search Station
  const [optionStation, setOptionStation] = useState<Array<Station>>([]);
  const [stationCode, setStationCode] = useState(String);

  const onSearchStation = async (searchText: string) => {
    console.log(" searchText " + searchText);
    const res = await stationServices.getByParam("",searchText, "", "", 0)
    setOptionStation(res.data);
  };
  const onSelectStation = (data: any) => {
    setStationCode(data);
  };

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    if (selectId === 0) {
      form.setFieldsValue({
        accountStation: stationArea.stationName,
      });
    } else {
      form.setFieldsValue({
        accountStation: stationArea.stationName,
        locationFullName: stationArea.locationFullName,
        serviceHome: stationArea.serviceHome === 1 ? true : false,
        serviceStation: stationArea.serviceStation === 1 ? true : false,
        useFlg: stationArea.useFlg,
        lineCode : stationArea.lineCode,
        lineName : stationArea.lineName,
        remark : stationArea.remark === null ? "" : stationArea.remark
      });
      setStationCode(stationArea.stationCode);
      setLocationCode(stationArea.locationCode);
    }

  }, [stationArea]);

  //location code
  const [locationCode, setLocationCode] = useState(String);
  const [locationOptions, setLocationOptions] = useState<Array<LocationView>>([]);
  const [locationFilterOptions, setLocationFilterOptions] = useState<Array<LocationView>>([]);

  const onSearchLocation = async (searchText: string) => {
    //const res = await locationServices.find(searchText);
    let search = locationOptions.filter((item) => item.addrPath.includes(searchText));
    setLocationFilterOptions(search);
  };

  const onSelectLocation = async (locationCode: any) => {
    console.log(" selected locationcode ", locationCode)
    const res = await locationServices.getByTambonCode(locationCode);
    setLocationCode(locationCode);
  };

  const onSearchLocationInArea = async (searchText: string) => {
    console.log(' search ', searchText)
    if (searchText !== null && searchText.length > 0) {
      let search = stationAreas.filter((item) => item.locationFullName.includes(searchText));
      setStationAreas(search);
    } else {
      getListStationArea();
    }
  };
  

  const addLocation = () => {
    setTrigger(true);
  }

  const onFinish = async (values: any) => {

    if (stationcode != null) {
      const stationArea_: StationArea = {
        id: selectId,
        stationCode: stationCode,
        locationCode: locationCode,
        stationName: "",
        locationFullName: "",
        serviceHome: values.serviceHome === true ? 1 : 0,
        serviceStation: values.serviceStation === true ? 1 : 0,
        useFlg: values.useFlg,
        locationCount: 0,
        lineCode : values.lineCode,
        lineName : values.lineName,
        savStation : sessionUser.stationCode,
        createUserid : sessionUser.userId,
        createUsername : sessionUser.username,
        remark : values.remark === undefined ? "" : values.remark
      }
      console.log(" stationArea_  ", stationArea_);
      try {
        let chkDuplicateObj = stationAreas.find((val) => { return val.locationCode === locationCode && val.useFlg === 0})
          console.log('locationCode on finish ===',chkDuplicateObj)
          if (chkDuplicateObj !== undefined && selectId === 0) {
            showMessage(" ข้อมูลซ้ำ กรุณาเลือกที่อยู่ใหม่");
            setTrigger(false);
            return;
          }
          if (selectId === 0) {
            await stationAreaServices.addStationArea(stationArea_)
            getListStationArea();
            message.success("บันทึกข้อมูลเรียบร้อย", 1.5);
          
          } else {
            await stationAreaServices.updateStationArea(stationArea_)
            getListStationArea();
            setSelectId(0);
            message.success("แก้ไขข้อมูลเรียบร้อย", 1.5);
          }
        setItemAction(false);
        clearForm();

      } catch (err) {
        showMessage(''+err);
      }
    }

    setTrigger(false);
  };

  const clearForm = () => {
    form.setFieldsValue({
      accountStation: stationArea.stationName,
      locationFullName: "",
      serviceHome: false,
      serviceStation: false,
      useFlg: 0,
      lineCode : "",
      lineName : "",
      remark : ""
    });
    setLocationCode("");
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
		<>
			<Form
				form={form}
				initialValues={{ remember: true }}
				layout="vertical"
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Row>
					<Col span={12}>
          <Section>
          <Typography.Title level={4} style={{ marginTop: 0}}>สาขา (NIMSHOP)</Typography.Title>
						<Form.Item
							label=""
							name="accountStation"
							rules={[{ required: true, message: "กรุณาระบุสาขา NIMSHOP" }]}
						>
							<Select
								showSearch
								placeholder="ค้นหาด้วยชื่อ NIMSHOP"
								optionFilterProp="children"
								filterOption={true}
								onSearch={onSearchStation}
								onSelect={onSelectStation}
                disabled={action}
							>
								{optionStation.map((obj: any) => {
									return <Option value={obj.stationCode}>{obj.stationName}</Option>;
								})}
							</Select>
						</Form.Item>
          </Section>
					</Col>
				</Row>

				<Row gutter={[20, 10]}>
					<Col span={12}>
						<Section style={{ marginTop: 30}}>
							<Typography.Title level={4} style={{ marginTop: 0}}>LOCATION</Typography.Title>

							<Form.Item
								label="ที่อยู่"
								name="locationFullName"
								rules={[{ required: true, message: "กรุณาระบุที่อยู่" }]}
							>
								<Select
									filterOption={true}
									placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
									optionFilterProp="children"
									onSearch={onSearchLocation}
									onSelect={onSelectLocation}
									showSearch
                  disabled={itemAction}
								>
									{locationOptions.map((obj: any) => {
										return <Option value={obj.tambonCode}>{obj.addrPath}</Option>;
									})}
								</Select>
							</Form.Item>

							<Space>
								<Form.Item name="serviceHome" valuePropName="checked" initialValue={true}>
									<Checkbox value="checked" onChange={(e) => e.target.checked}>
										ส่งสินค้า
									</Checkbox>
								</Form.Item>
								<Form.Item name="serviceStation" initialValue={false} valuePropName="checked">
									<Checkbox value="checked" onChange={(e) => e.target.checked}>
										รับที่สาขา
									</Checkbox>
								</Form.Item>
							</Space>

							<Form.Item name="lineCode" label="Line Code"
                rules={[{ required: true, message: "กรุณาระบุ Line Code" }]}
              >
								<Input onKeyDown={handleEnter} disabled={itemAction}/>
							</Form.Item>

							<Form.Item name="lineName" label="Line Name"
                rules={[{ required: true, message: "กรุณาระบุ Line Name" }]}
              >
								<Input onKeyDown={handleEnter} />
							</Form.Item>

              <Form.Item name="remark" label="หมายเหตุ(คำนวณราคา)">
								<Input onKeyDown={handleEnter} />
							</Form.Item>

							<Form.Item name="useFlg" label="สถานะ" initialValue={0}>
								<Radio.Group>
									<Radio value={0}>ใช้งาน</Radio>
									<Radio value={-1}>ไม่ใช้งาน</Radio>
								</Radio.Group>
							</Form.Item>
							<Space style={{ display: 'flex', justifyContent: 'flex-end'}}>
								<>
									{selectId === 0 ? (
										<Button
											type="primary"
											onClick={() => addLocation()}
											style={{ marginLeft: 100, width: 120 }}
											icon={<PlusCircleOutlined />}

										>
											เพิ่ม
										</Button>
									) : (
										<Button
											type="primary"
											onClick={() => addLocation()}
											style={{ marginLeft: 100, width: 120 }}
											icon={<EditOutlined />}
										>
											บันทึก
										</Button>
									)}
								</>
							</Space>
						</Section>
					</Col>
					<Col span={12}>
						<Section style={{ marginTop: 30}}>
              <Form.Item name="locationFilter" label="ค้นหา">
								<Input onChange={(e) => onSearchLocationInArea(e.target.value)}/>
							</Form.Item>
							<Table
                rowKey={(item: StationArea) => item.id}
                rowClassName={(record, index) => (record.useFlg === -1 ? classCss.red : classCss.black)}
                columns={columns}
                dataSource={stationAreas} size="small"
                pagination={{
                  pageSize: 20,
                  onChange(current) {
                    setPage(current);
                  },
                  showSizeChanger:false
                }} />
						</Section>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default FormStationArea;

