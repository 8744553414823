import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined ,SearchOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import franchiseeServices from 'src/services/franchiseeServices';
import { Franchisee } from 'src/domain/franchiseeType';
import Section from '../../components/container/Section';

const ListFranchisee = () => {
    const history = useHistory();
    const [franchisees, setFranchisees] = useState<Franchisee[]>([]);

    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [tel, setTel] = useState("");
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            render: (value: number, item: Franchisee, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "ชื่อ",
            //align: "center" as "center",
            dataIndex: "franchiseeName",
            key: "franchiseeName",
            render: (value: string, item: Franchisee, index: number) => <Link to={`/app/franchisee/edit/${item.id}`}>{value}</Link>,
        },
        {
            title: "เบอร์โทร",
            align: "center" as "center",
            dataIndex: "franchiseeTel",
            key: "franchiseeTel",
        },
    ];
    useEffect(() => {

        franchiseeServices.getAll()
            .then((res) => {
                console.log(res.data)
                setFranchisees(res.data)
            })

    }, []);

    const search = () => {
        console.log(searchName, tel, status);

        franchiseeServices.getByParam(searchName, tel, status)
            .then((res) => {
                setFranchisees(res.data)
            })
    }

    return (
			<div>
				<PageHeader title="กำหนดข้อมูลแฟรนไชส์" subTitle="เพิ่ม แก้ไข เปลี่ยนแปลงข้อมุล แฟรนไชส์">
					<Button
						size="large"
						style={{ width: 120 }}
						type="primary"
						icon={<PlusCircleOutlined />}
						onClick={() => {
							history.push("/app/franchisee/add");
						}}
					>
						เพิ่ม
					</Button>
				</PageHeader>

				<Section>
					<Form layout="vertical">
						<Row gutter={[20, 10]} style={{ display: "flex", alignItems: "start" }}>
							<Col xs={24} sm={24} md={12} lg={8} xl={8}>
								<Form.Item label="ชื่อ">
									<Input name="searchName" onChange={(e) => setSearchName(e.target.value)} />
								</Form.Item>
								<Radio.Group name="status" defaultValue={0} onChange={(e) => setStatus(e.target.value)}>
									<Space>
										<Radio value={0}>ใช้งาน</Radio>
										<Radio value={-1}>ยกเลิก</Radio>
									</Space>
								</Radio.Group>
							</Col>

							<Col xs={24} sm={24} md={12} lg={8} xl={8}>
								<Form.Item label="รหัส">
									<Input name="code" onChange={(e) => setTel(e.target.value)} />
								</Form.Item>
							</Col>

							<Col xs={24} sm={24} md={12} lg={8} xl={8} style={{ display: 'flex', justifyContent: 'flex-end'}} >

									<Button  icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30}}>
										Search
									</Button>

							</Col>
						</Row>
					</Form>
				</Section>

				<Section style={{ marginTop: 30, paddingTop: 30 }}>
					<Table
						pagination={{
							pageSize: 10,
							onChange(current) {
								setPage(current);
							},
						}}
						rowKey={(item: Franchisee) => item.id}
						columns={columns}
						dataSource={franchisees}
						size="small"
					/>
				</Section>
			</div>
		);
}

export default ListFranchisee;
