import { Col, Form, Input, Radio, Row, Select, message } from "antd";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router";
import { PreName } from "src/domain/preNameType";
import prenameServices from "src/services/prenameServices";
import { showMessage, handleEnter } from "src/utils";
import { SessionUser } from "src/store/authen/types";
import { useSelector } from "react-redux";
import franchiseeServices from "src/services/franchiseeServices";
import locationServices from "src/services/locationServices";
import { Franchisee } from "src/domain/franchiseeType";
import { LocationView } from "src/domain/locationViewType";
import Section from "../../components/container/Section";

const { Option } = Select;

interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const FormFranchisee: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const [franchisee, setFranchisee] = useState<Franchisee>({} as Franchisee);
  const [prenameId, setPrenameId] = useState(Number);
  const [franchiseePrename, setFranchiseePrename] = useState(String);
  const [zipcode, setZipCode] = useState(String);
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [locationAddrPath , setLocationAddrPath] = useState(String);

  useEffect(() => {
    getAllLocations();
    if (id === 0) {
        getAllPrenames();
    } else {
      getFranchisee();
    }
  }, []);

  useEffect(() => {
      console.log("set data ", franchisee)
      if (id !== 0) {
        form.setFieldsValue({
          id: id,
          prename : franchiseePrename,
          franchiseeName : franchisee.franchiseeName,
          address: franchisee.address,
          addrPath: locationAddrPath,
          locationCode: franchisee.locationCode,
          zipcode: franchisee.zipcode,
          taxId : franchisee.taxId,
          franchiseeTel : franchisee.franchiseeTel,
          useFlg: franchisee.useFlg,
        });
      }
  }, [franchisee, prenameId, franchiseePrename, locationAddrPath]);


  const getFranchisee = async () => {
    const res = await franchiseeServices.getById(Number(id));
    console.log(" res ",res.data);
    setFranchisee(await res.data);
    setPrenameId(res.data.prenameId);
    setLocationCode(res.data.locationCode);

    const resprename = await prenameServices.getById(Number(res.data.prenameId));
    setFranchiseePrename(resprename.data.prename);

    const locationview = await locationServices.getByTambonCode(res.data.locationCode);
    setLocationAddrPath(locationview.data.addrPath);
  }

  const getAllLocations = async () => {
    const res = await locationServices.getAll();
    setLocationOptions(res.data);
  }

  const getAllPrenames = async () => {
    const res = await prenameServices.getAll();
    setPrenameOptions(res.data);
  }

  const [prenameOptions, setPrenameOptions] = useState<Array<PreName>>([]);

  const onSearch = async (searchText: string) => {
    console.log(" searchText " + searchText);
    const res = await prenameServices.getByParam(searchText, 0);

    setPrenameOptions(res.data);
  };
  const onSelect = async (selectedId: any) => {
    console.log(" prename data id ", selectedId)
    const resprename = await prenameServices.getById(Number(selectedId));
    setPrenameId(selectedId);
    setFranchiseePrename(resprename.data.prename);
  };

  //location code
  const [locationCode, setLocationCode] = useState(String);
  const [locationOptions, setLocationOptions] = useState<Array<LocationView>>([]);
  const [locationFilterOptions, setLocationFilterOptions] = useState<Array<LocationView>>([]);

  const onSearchLocation = async (searchText: string) => {
    //const res = await locationServices.find(searchText);
    let search = locationOptions.filter((item) => item.addrPath.includes(searchText));
    setLocationFilterOptions(search);
  };

  const onSelectLocation = async (locationCode : any) => {
    console.log(" selected locationcode ", locationCode)
    const res = await locationServices.getByTambonCode(locationCode);
    setLocationCode(locationCode);
    setZipCode(res.data.zipcode);
  };

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = async (values: any) => {
    console.log(id)
    console.log(values);

    let addrPath = "";
    if (values.addrPath != null) {
      addrPath = locationCode.substring(0,2) + "/" + locationCode.substring(0,4) + "/" + locationCode;
      console.log("addr path", addrPath);
    }

    const franchisee_ : Franchisee = {
      id : id,
      prenameId : prenameId,
      franchiseeName : values.franchiseeName,
      address : values.address,
      addrPath : addrPath,
      locationCode : locationCode,
      zipcode : zipcode,
      taxId : values.taxId,
      franchiseeTel : values.franchiseeTel,
      useFlg : values.useFlg === undefined ? 0 : values.useFlg,
      savStation: sessionUser.stationCode,
      createUserid: sessionUser.userId,
      createUsername: sessionUser.username
    };

    console.log("franchisee_ ", franchisee_);

    if (id === 0) {
      try {

        franchiseeServices.addFranchisee(franchisee_).then(res => {
          history.replace("/app/franchisee");
          message.success("Save Complete", 1.5);
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        showMessage(''+err);
      }
    }

    if (id !== 0) {
      franchiseeServices.updateFranchisee(franchisee_).then(res => {
        console.log("franchisee ", res.data);
        history.replace("/app/franchisee");
        message.success("Update Complete", 1.5);
      }).catch(err => {
        console.log(err)
      });

    }
    setTrigger(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };


  return (
		<>
			<Form
				form={form}
				initialValues={{ remember: true }}
				layout="vertical"
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Row gutter={[20, 20]} style={{ paddingTop: "15px" }}>
					<Col span={16} xs={24} sm={24} md={24} lg={16} xl={16}>
						<Section>
							<Form.Item label="คำนำหน้า" name="prename" rules={[{ required: true, message: "Required : คำนำหน้า" }]}>
								<Select
									showSearch
									placeholder="ระบุคำนำหน้า"
									optionFilterProp="children"
									filterOption={true}
									onSearch={onSearch}
									onSelect={onSelect}
								>
									{prenameOptions.map((obj: any) => {
										return <Option value={obj.id}>{obj.prename}</Option>;
									})}
								</Select>
							</Form.Item>

							<Form.Item name="franchiseeName" label="ชื่อ">
								<Input onKeyDown={handleEnter} />
							</Form.Item>

							<Form.Item label="เลขที่" name="address" rules={[{ required: true, message: "ระบุบ้าน ซอย ถนน" }]}>
								<Input placeholder="บ้าน ซอย ถนน" onKeyDown={handleEnter} />
							</Form.Item>

							<Form.Item
								label="ที่อยู่"
								name="addrPath"
								rules={[{ required: true, message: "กรุณาค้นหาตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์" }]}
							>
								<Select
									showSearch
									placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
									optionFilterProp="children"
									filterOption={true}
									onSearch={onSearchLocation}
									onSelect={onSelectLocation}
									onKeyDown={handleEnter}
								>
									{locationOptions.map((obj: any) => {
										return <Option value={obj.tambonCode}>{obj.addrPath}</Option>;
									})}
								</Select>
							</Form.Item>

							<Form.Item
								label="เบอร์โทรศัพท์"
								name="franchiseeTel"
								rules={[{ required: true, message: "กรุณาระบุเบอร์โทรศัพท์" }]}
							>
								<Input placeholder="กรุณาระบุเบอร์โทรศัพท์" maxLength={10} onKeyDown={handleEnter} />
							</Form.Item>

							<Form.Item
								label="เลขประจำตัวผู้เสียภาษี"
								name="taxId"
								rules={[{ required: true, message: "กรุณาระบุเลขประจำตัวผู้เสียภาษี" }]}
							>
								<Input placeholder="กรุณาระบุเลขประจำตัวผู้เสียภาษี" maxLength={13} />
							</Form.Item>
						</Section>
					</Col>

					<Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} >
						<Section>
							<Form.Item name="useFlg" label="สถานะ">
								<Radio.Group defaultValue={0}>
									<Radio value={0}>ใช้งาน</Radio>
									<Radio value={-1}>ไม่ใช้งาน</Radio>
								</Radio.Group>
							</Form.Item>
						</Section>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default FormFranchisee;