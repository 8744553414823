import React, { useState } from 'react'
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import { RouteComponentProps, useHistory } from 'react-router';
import FormFleetStation from './FormFleetStation';

interface IParams {
    id: string;
  }

const EditFleetStation : React.FC<RouteComponentProps<IParams>> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();
    const id = String(props.match.params.id);

    return (
        <>
           <PageHeader title="แก้ไข การกำหนดสาขาให้รถ" subTitle="เพิ่มหรือแก้ไขสาขาให้กับรถ">
               <Space>
           <Button
              size="large"
                type="primary"
               icon={<SaveOutlined />}
               onClick={() => setTrigger(true)}
            >
              แก้ไข
            </Button>
            <Button
              size="large"
              icon={<CloseCircleOutlined />}
               onClick={() => history.goBack()}
            >
              กลับ
            </Button>
          </Space>

        </PageHeader>  
        <Content>
          <Row>
            <Col span={24}>
                <FormFleetStation truckUId={id} trigger={trigger} setTrigger={setTrigger} />
            </Col>
          </Row>
          </Content>    
        </>
    )
}

export default EditFleetStation;