import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined  } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { StationLine } from '../../domain/stationLineType';
import { Link } from 'react-router-dom';
import stationLineServices from 'src/services/stationLineServices';

let i = 1;
const columns = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        //key: `${i}`,
        key: "id",
    },
    {
        title: "NIMSHOP",
        dataIndex: "stationName",
        key: "stationName",
    },
    {
        title: "รหัส",
        dataIndex: "lineCode",
        key: "lineName",
    },
    {
        title: "ชื่อสายกระจาย",
        dataIndex: "lineName",
        key: "lineName",
        render: (value: string,item:StationLine,index:number) => <Link to={`/app/stationline/edit/${item.id}`}>{value}</Link>,
    },

];


const ListStationLine = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [stationLine,setStationLine] = useState([])

    const[searchName,setSearchName] =useState<any>("");
    const[status,setStatus] =useState<any>(0);
    const[shopName, setShopName]=useState("");

    useEffect(() => {

        stationLineServices.getAll()
        .then((res)=> {
            console.log(res.data)
            setStationLine(res.data)
        })
        
    },[]);

    const changeSearchNimShop = (searchText : any) =>{
        setShopName(searchText);
        search(shopName, searchName, status);
    }

    const changeSearchText = (searchText : any) =>{
        setSearchName(searchText);
        search(shopName, searchName, status);
    }
    const changeUseFlg = (status : any) =>{
        setStatus(status);
        search(shopName, searchName, status);
    }

    const search = (shopName : any , searchText : any, status : any) => {
        stationLineServices.getByParam(shopName, searchText, status)
            .then((res)=> {
            setStationLine(res.data)
        })
    }

    return (
        <div>
        <PageHeader title="กำหนดสายกระจาย">
                <Space>
                    <Button
                        size="small"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/stationline/add") }}
                    >
                    </Button>
                </Space>
        </PageHeader>
        <Form>
       

         <Form.Item  style={{
            borderRadius: "2px solid #b78496",
            backgroundColor: "#f6f1f3",
             paddingTop:"20px",
            //  padding : "20px,20px,20px,20px",
             height: "100px",
          }}>
         <Row >

         <Col span={5} style={{textAlign: 'right',fontSize : "15px" ,paddingRight:"10px",  }}>
                NIMSHOP : 
            </Col>
            <Col span={5}>
                 <Input style={{
                     borderRadius: "5px", 
                     width: "200px" ,
                    //  border: "2px solid #b78496"
                     }}
                     
                     name= "shopName"
                     onChange ={(e)=> changeSearchNimShop(e.target.value)}
                     
                     ></Input>
            </Col>
            <Col span={4}/>

            <Col span={5} style={{textAlign: 'right',
                fontSize : "15px" ,
                paddingRight:"10px",  }}>
                ชื่อสายกระจาย : 
            </Col>
            
            <Col span={5}>
                <Input style={{
                    borderRadius: "5px",
                     width: "200px" ,
                     
                     }}
                     
                     name= "searchName"
                     onChange ={(e)=> changeSearchText(e.target.value)}
                     
                     ></Input>
            </Col>
            
        </Row>
        <Row style={{
                paddingTop:"10px",  }}>
            <Col span={5}/>
            <Col  span={5}>
                <Radio.Group name="status" defaultValue={1}
                 onChange={(e) => changeUseFlg(e.target.value)} 
                >
                    <Space>
                    <Radio value={0} >ใช้งาน</Radio>
                    <Radio value={-1} >ไม่ใช้งาน</Radio>
                    </Space>
                </Radio.Group>
            </Col>
        </Row>
        </Form.Item>
        
        </Form>
         
        <Content className="app-page-content">
                <Table 
                rowKey={(item:StationLine) => item.id}
                columns={columns}
                dataSource={stationLine} 
                size="small" />
             
            </Content>
    </div>
    )
}

export default ListStationLine;
