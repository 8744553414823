import React, { useEffect, useState } from 'react'
import { Modal, Radio, RadioChangeEvent,Button } from 'antd';
import { showMessage, handleEnter } from "src/utils";
import { FleetStation } from 'src/domain/fleetStation';
import fleetStationServices from 'src/services/fleetStationServices';
import { SessionUser } from 'src/store/authen/types';
import { useSelector } from 'react-redux';

interface StatusModalProps {
    isModalOpen: boolean;
    onOk: any;
    onCancel: any;
    fleetStation : FleetStation;
}

const StatusModal = (props: StatusModalProps) => {
    const sessionUser : SessionUser = useSelector((state: any) => state.auth.sessionUser);
    const [selectedStatus , setSelectedStatus] = useState(Number);
    const [fleetStation , setFleetStation] = useState(props.fleetStation);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onChangeRadio = (e : RadioChangeEvent) => {
        console.log(' selected change value ', e.target.value)
        setSelectedStatus(e.target.value);
    }
    const handleOk = async () => {
        console.log(' handleOk selectedStatus' , selectedStatus)
        //setIsStatusModalOpen(false);
        if (props.fleetStation != null) {
            //fleetStation.id = props
            props.fleetStation.useFlg = selectedStatus;
            props.fleetStation.createUsername = sessionUser.username
            //await fleetStationServices.updateFleetStation(fleetStation);
            props.onOk(props.fleetStation);
            handleCancel();
        }
      };
    const handleCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }
    };

    useEffect(() => {
        setIsModalOpen(props.isModalOpen);
        console.log(' props.fleetStation ', props.fleetStation)
        if (props.fleetStation != null) {
            setSelectedStatus(props.fleetStation.useFlg);
        } else {
            setSelectedStatus(0);
        }
    }, [props.isModalOpen])

    return (
        <>
            <Modal 
                title="สาขาใช้งาน" 
                visible={isModalOpen} 
                //onCancel={handleCancel}
                //onOk={handleOk}
                width={400}
                centered
                closable={false}
                footer={
                    [
                    <div style={{textAlign : "center"}}><Button key="submit" type="primary" onClick={handleOk}>บันทึก</Button>
                    <Button key="back" onClick={handleCancel}>ยกเลิก</Button>
                    </div>
                    ]
                }

            >
                <Radio.Group defaultValue={selectedStatus} onChange={onChangeRadio}>
                  <Radio value={0} >ใช้งาน</Radio>
                  <Radio value={-1}>ยกเลิกใช้งาน</Radio>
                </Radio.Group>
            </Modal>
        </>
    );

}
export default StatusModal;