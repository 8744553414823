import { Space, Table, Form, Row, Col, Input, Radio, Button, Checkbox, Select, DatePicker, message, Image } from 'antd'
import { PrinterOutlined, SearchOutlined ,FileExcelFilled  } from '@ant-design/icons';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState, useRef } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import Section from "../../components/container/Section";
import { currDate, formatDate, showDate } from "../../utils/dateUtils";
import { firstDateOfMonth, lastDateOfMonth, toDayjs } from 'src/utils/dateUtils';
import numberUtils from '../../utils/numberUtils';
import { useDownloadExcel } from 'react-export-table-to-excel';
import stationServices from 'src/services/stationServices';
import { showMessage } from 'src/utils';
import reportSmcReleaseService from 'src/services/reportSmcReleaseService';
import fleetDrvStationService from 'src/services/fleetDrvStationService';
import fleetStationServices from 'src/services/fleetStationServices';
import excelImg from "src/views/images/excel.png";
import { ReportSmcReleaseData } from 'src/domain/reportSmcReleaseType';
import fleetRequestService from 'src/services/fleetRequestService';
import { fleetRequest } from 'src/domain/fleetRequestType';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ReportSmcRelease = () => {

    const [form] = Form.useForm();
    const tableRef = useRef(null);
    const [stations, setStations] = useState([{ stationCode: '', stationName: 'ทั้งหมด' }])
    const [fleetdrvStations, setFleetDrvStations] = useState([])
    const [fleetTrucks, setFleetTrucks] = useState([])
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');
    const [stationCode, setStationCode] = useState('');
    const [driverCode, setDriverCode] = useState('');
    const [truckRegist, setTruckRegist] = useState('');
    const [smcShipment, setSmcShipment] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadList, setIsLoadList] = useState(false);
    // const [listData, setListData] = useState([]);
    const [listData, setListData] = useState<ReportSmcReleaseData[]>([]);
    const [page, setPage] = React.useState(1);
    const [isLoading ,setIsloading] = useState(false);

    // const [list, setList] = useState<FleetStation[]>([]);
     
    const columns = [
        {
            title: "รายงานการปล่อยรถกระจาย",
            children: [
                {
                    title: 'วันที่ปล่อยรถ ' + showDate(sDate) + ' ถึง ' + showDate(eDate),
                    children: [
                        {
                            title: "ลำดับ", dataIndex: "seq", key: "seq", //fixed: 'left' as AlignType,
                            align: "center" as AlignType, width: 50,
                            // render: (value: number, item: any, index: number) => (<>{index + 1}</>)
                        },
                        {
                            title: "วันที่ปล่อยรถ", dataIndex: "smcShipmentDate", key: "smcShipmentDate", //fixed: 'left' as AlignType,
                            align: "center" as AlignType, width: 150,
                            // render: (value: any, item: any, index: number) => {
                            //     return <span>{value && value !== 'null' ? showDate(value) : '-'}</span>;
                            // },
                        },
                        {
                            title: "สาขา", dataIndex: "stationName", key: "stationName",
                             align: "center" as AlignType, width: 150,
                           
                        },
                        {
                            title: "ทะเบียนรถ", dataIndex: "registId", key: "registId",
                             align: "center" as AlignType, width: 150,
                            // render: (value: any, item: any, index: number) => {
                            //     return <span>{value && value !== 'null' ? showDate(value) : '-'}</span>;
                            // },
                        },
                        {
                            title: "ชื่อพนักงานขับรถ", dataIndex: "driverName", key: "driverName",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "เลขที่ใบรายการ", dataIndex: "smcShipmentNo", key: "smcShipmentNo",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "เลขที่บิล", dataIndex: "billNo", key: "billNo",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "เลขที่tracking", dataIndex: "trackingNo", key: "trackingNo",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "สถานะการส่ง", dataIndex: "status", key: "status",
                             width: 150,
                        },
                        {
                            title: "ผู้รับ", dataIndex: "consigneeName", key: "consigneeName",
                            width: 250,
                        },
                        {
                            title: "ที่อยู่เบอร์โทร", dataIndex: "address", key: "address",
                            width: 250,
                        },
                        {
                            title: "รายการสินค้า", dataIndex: "itemName", key: "itemName",
                            width: 200,
                        },
                        {
                            title: "จำนวนชิ้น", dataIndex: "shipQty", key: "shipQty",
                            align: "right" as AlignType, width: 100,
                            render: (value: number, item: any, index: number) => (
                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {value}
                                </Col>
                            )
                        },
                        {
                            title: "หน่วย", dataIndex: "unitName", key: "unitName",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "ราคารวม", dataIndex: "totalAmt", key: "totalAmt",
                            align: "right" as AlignType, width: 150, fixed: 'right' as AlignType,
                            render: (value: number, item: any, index: number) => (
                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {numberUtils.numberWithCommas(value)}
                                </Col>
                            )
                        },
                        {
                            title: "การชำระเงิน", dataIndex: "paymentBy", key: "paymentBy",
                            align: "center" as AlignType, width: 100,
                        },
                        {
                            title: "สาย", dataIndex: "lineName", key: "lineName",
                            align: "center" as AlignType, width: 100,
                        },
                    ]
                }
            ]
        },
    ];

    useEffect(() => {
        if (!isLoaded) {
            setStations([{ stationCode: '', stationName: 'ทั้งหมด' }]);
            renderStation();

            setFleetDrvStations([]);
            renderFleetDrvStation();

            setFleetTrucks([]);
            renderTruckRegist();

            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        console.log("check >>>",isLoadList)
        if (isLoadList) {
            onDownload();
            setIsLoadList(false);
        }
    }, [isLoadList])

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'SmcRelease_'  + sDate + eDate,
        sheet: 'report'
    })

    const renderStation = async () => {
        // const res = await stationServices.getAll();
        const res = await stationServices.getByParam('', '', '', '', 0)
        if (res.status === 200) {
            const data = await res.data;
            setStations(stations => stations.concat(data));
        } else {
            showMessage('error status:' + res.status + '' + res.statusText);
        }
    }

    const renderFleetDrvStation = async () => {
        const res = await fleetDrvStationService.getbystationCode('');
        if (res.status === 200) {
            const data = await res.data;
            setFleetDrvStations(fleetdrvStations => fleetdrvStations.concat(data));
        } else {
            showMessage('error status:' + res.status + '' + res.statusText);
        }
    }

    const renderTruckRegist = async () => {
        const res = await fleetStationServices.getAllFleetTruck();
        if (res.status === 200) {
            const data = await res.data;
            console.log(data)
            setFleetTrucks(fleetTrucks => fleetTrucks.concat(data));
        } else {
            showMessage('error status:' + res.status + '' + res.statusText);
        }
    }



    function onSearch(values: any) {

        setIsloading(true);

        if (values) {
            console.log("search param", values, values.rangeDate.toString());
        }

        let sDate: string = currDate();
        let eDate: string = currDate();

        if (values !== null && values.rangeDate !== undefined) {
            sDate = formatDate(values.rangeDate[0])
            eDate = formatDate(values.rangeDate[1])
        }
        setSDate(sDate);
        setEDate(eDate);
        setStationCode(form.getFieldValue('stationSrcCode'));
        setDriverCode(form.getFieldValue('driverSrcCode'));
        setTruckRegist(form.getFieldValue('truckRegidtSrcCode'));
        setSmcShipment(form.getFieldValue('smcShipmentNo'));

        console.log("data >>>>", sDate, eDate, stationCode, driverCode, truckRegist, smcShipment);

        (async () => {
            try {
                // const res = await reportSmcReleaseService.getReportSmcReleaseCenter(
                //     sDate,
                //     eDate,
                //     form.getFieldValue('stationSrcCode'),
                //     form.getFieldValue('driverSrcCode'),
                //     form.getFieldValue('smcShipmentNo'),
                //     form.getFieldValue('truckRegidtSrcCode')
                // );

                let params = {
                    sDate: sDate,
                    eDate: eDate,
                    stationCode: form.getFieldValue('stationSrcCode'),
                    driverCode: form.getFieldValue('driverSrcCode'),
                    truckUUId: form.getFieldValue('truckRegidtSrcCode'),
                    smcShipmentNo: form.getFieldValue('smcShipmentNo'),
                }


               
                 let resData: ReportSmcReleaseData[] = await reportSmcReleaseService.getReportSmcReleaseCenter(params);
                    console.log(resData); 
                 
                 type Req ={
                     billNo : string,
                     trackingNo: string,
                     date :string,
                     smcShipmentNo :string,
                 }
                 
                 let smcReq: Req[] = resData.reduce((acc: Req[],curr)=>{
                    acc.push({
                        billNo :curr.billNo,
                        trackingNo: curr.trackingNo,
                        date : curr.date === undefined ? "" : curr.date ,
                        smcShipmentNo : curr.smcShipmentData === undefined ? "" : curr.smcShipmentData,
                        
                    })
                    
                    return acc;
                 },[])

                 console.log(smcReq)
                 
                 let smcStatusDate: fleetRequest[] = await fleetRequestService.getDeliveryStatus(smcReq);

                 resData.forEach((value)=>{
                    // console.log("value ", value.status);
                    if(!value.trackingNo) return;

                    if(smcStatusDate.length === 0) return;

                    const _item = smcStatusDate.filter(item => {
                        if(!item) return false;

                        // return value.trackingNo === item.trackingNo  &&  value.date === item.date ;
                        return  value.trackingNo === item.trackingNo &&  value.smcShipmentData === item.smcShipmentNo ;
                    })

                    if(_item.length > 0){
                         value.status = _item[0].status;
                    }
                 });
                    
                 if (resData.length > 0) {
                    // for (let i = 0; i < res.data.length; i++) {
                    //     res.data[i].index = i + 1;
                    // }

                    setListData(resData);
                    setIsLoadList(true);  
                    setIsloading(false);                  
                } else {
                    showMessage("ไม่พบข้อมูล!2");
                    setIsloading(false);
                }
               
            } catch (err) {
                showMessage("ไม่พบข้อมูล!3");
                setIsloading(false);
                console.log(err)
            }
        })();
    }

    const onFinishFailed = (values: any) => {
        message.info("ไม่พบข้อมูล");
    }

    return (
        <div> <PageHeader title="รายงานปล่อยรถกระจาย">
            <Space>
                <Button loading={isLoading} icon={<FileExcelFilled style={{color:"#1D6F42"}}/> }
                                onClick={() => form.submit()}>
                   ส่งออก           
                 </Button>
            </Space>
        </PageHeader>

            <Section >
                <Form
                    form={form}
                    name="basic"
                    initialValues={{
                        rangeDate: [toDayjs(firstDateOfMonth()), toDayjs(lastDateOfMonth())],
                    }}
                    layout="vertical"
                    onFinish={onSearch}
                    onFinishFailed={onFinishFailed}   >
                    <Row gutter={[20, 10]}  >
                        <Col span={12} >
                            {/* <Space> */}
                                <Form.Item label="วันที่ปล่อยรถ" name="rangeDate">
                                    <RangePicker format={"DD/MM/YYYY"} />
                                </Form.Item>
                            {/* </Space> */}

                        </Col>
                        <Col span={12}  >
                            <Form.Item label="สาขา" name="stationSrcCode" >
                                <Select 
                                  filterOption={true} 
                                  placeholder="เลือกสาขา" 
                                  optionFilterProp="children" 
                                  showSearch>
                                    {stations.map((item: any) => (
                                        <Option value={item.value} key={item.stationCode}>
                                            {item.stationName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={[20, 10]}>
                        <Col span={12}  >
                            <Form.Item label="พนักงานขับรถ" name="driverSrcCode">
                                {/* <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/> */}
                                <Select
                                    filterOption={true}
                                    placeholder="เลือกพนักงานขับรถ"
                                    optionFilterProp="children"
                                    // onSelect={(item: any) => setPartnerId(item)}
                                    showSearch
                                    allowClear
                                >
                                    {fleetdrvStations.map((item: any) => (
                                        <Option value={item.value} key={item.driverCode}>
                                            {item.driverName}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}  >
                            <Form.Item label="ทะเบียนรถ" name="truckRegidtSrcCode">
                                <Select
                                    filterOption={true}
                                    placeholder="เลือกทะเบียนรถ"
                                    optionFilterProp="children"
                                    // onSelect={(item: any) => setPartnerId(item)}
                                    showSearch
                                    allowClear
                                >
                                    {fleetTrucks.map((item: any) => (
                                        //   <Option value={item.value} key={item.truckUId}>
                                        //       {item.registId}

                                        //   </Option>
                                        <Option value={item.truckUId} key={item.truckUId}>
                                            {item.registId}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 10]}>
                        <Col span={12} >
                            <Form.Item label="ใบรายการ" name="smcShipmentNo">
                                {/* <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/> */}
                                <Input ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                   

                    <Form.Item name="table" style={{ display: "none" }}  >
                    {/* style={{ display: "none" }} */}
                        <Table
                            columns={columns}
                            dataSource={listData}
                            pagination={false}
                            size="small"
                            ref={tableRef}
                            scroll={{ x: 1500, y: 300 }}
                            summary={(pageData) => {
                                let sumTotalAmt = 0;
                                let sumQtyAmt = 0;

                                pageData.forEach(({ totalAmt , shipQty}) => {
                                    sumTotalAmt += totalAmt;
                                    sumQtyAmt+= shipQty;
                                });

                                

                                return (
                                    <Table.Summary fixed>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={12} align={"right"}>
                                                รวมรายได้
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={12}>
                                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <text style={{ textDecorationLine: "underline" }}>{numberUtils.numberWithCommas(sumQtyAmt)}</text>
                                                </Col>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={13}>
                                                <Col></Col>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={14}>
                                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <text style={{ textDecorationLine: "underline" }}>{numberUtils.numberWithCommas(sumTotalAmt)}</text>
                                                </Col>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                );
                            }}
                        />
                    </Form.Item>
                </Form>
            </Section>

            {/* <Section style={{ marginTop: 30}}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                            setPage(current);
                        },
                        showSizeChanger:false
                    }}
                    // rowKey={(item: FleetStation) => item.id}
                    columns={columns}
                    dataSource={listData}
                    size="small" />

            </Section> */}
            
        </div>
    )
}

export default ReportSmcRelease