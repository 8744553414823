import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, message, Row, Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classServices from 'src/services/classServices';
import filepriceZonePricelistServices from 'src/services/filepriceZonePricelistServices';
import { SessionUser } from 'src/store/authen/types';

interface IProps {
    item: any;
    zonePrice: any;
    saveComplete?: any;
    onCloseDrawer?: any;
}

const ZonePricelistDrawer: React.FC<IProps> = (props) => {
    const { item, zonePrice, saveComplete, onCloseDrawer } = props;
    const [classDetails, setClassDetails] = useState<any[]>([]);
    const [isAddMode, setIsAddMode] = useState(true);
    const id = zonePrice.id != undefined ? zonePrice.id : 0;
    const [priceAmt, setPriceAmt] = useState<number>(zonePrice.priceAmt);
    const [typeCal, setTypeCal] = useState(!zonePrice.typeCal ? 'NORMAL' : zonePrice.typeCal);
    const [priceFactor, setPriceFactor] = useState<number>(zonePrice.priceFactor);
    const [priceSrcReceiveAmt, setPriceSrcReceiveAmt] = useState<number>(zonePrice.priceSrcReceiveAmt);
    const [priceSrcReceiveFactor, setPriceSrcReceiveFactor] = useState<number>(zonePrice.priceSrcReceiveFactor);
    const [priceSmcAmt, setPriceSmcAmt] = useState<number>(zonePrice.priceSmcAmt);
    const [priceSmcFactor, setPriceSmcFactor] = useState<number>(zonePrice.priceSmcFactor);
    const [priceReceiveAmt, setPriceReceiveAmt] = useState<number>(zonePrice.priceReceiveAmt);
    const [priceReceiveFactor, setPriceReceiveFactor] = useState<number>(zonePrice.priceReceiveFactor);
    const [isLoad, setLoad] = useState(false);

    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const option = classDetails.map(rec => {
        return {
            value: rec.classDetailName,
            label: rec.classDetailName
        }
    })

    useEffect(() => {
        if (!isLoad) {
            getTypeCals(15);
            setLoad(true);
        }
    }, []);

    useEffect(() => {
        if (zonePrice.id !== undefined) {
            setIsAddMode(false);
        }
    }, [zonePrice]);

    const getTypeCals = async (classId: number) => {
        const resp = await classServices.getClassDetailByClassId(classId);
        setClassDetails(resp);
    }

    const handleSave = async () => {
        if (!isValid()) {
            return;
        }
        const data = [{
            id: id,
            fileCode: item.fileCode,
            productTypeCode: item.productTypeCode,
            regZoneCode: item.regZoneCode,
            destZoneCode: item.destZoneCode,
            sizeCode: zonePrice.sizeCode,
            priceAmt: priceAmt,
            typeCal: typeCal,
            priceFactor: !priceFactor ? null : priceFactor,
            priceSrcReceiveAmt: priceSrcReceiveAmt,
            priceSrcReceiveFactor: !priceSrcReceiveFactor ? null : priceSrcReceiveFactor,
            priceSmcAmt: priceSmcAmt,
            priceSmcFactor: !priceSmcFactor ? null : priceSmcFactor,
            priceReceiveAmt: priceReceiveAmt,
            priceReceiveFactor: !priceReceiveFactor ? null : priceReceiveFactor,
            filepriceId: item.filepriceId,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username,
            savStation: sessionUser.stationCode,
        }]
        await onSave(data);
    }

    const isValid = () => {
        if (!priceAmt || priceAmt <= 0) {
            message.warning('กรุณาระบุราคา');
            return false;
        }
        if (!priceSrcReceiveAmt || priceSrcReceiveAmt <= 0) {
            message.warning('กรุณาระบุราคา รับสินค้า');
            return false;
        }
        if (!priceSmcAmt || priceSmcAmt <= 0) {
            message.warning('กรุณาระบุราคา กระจายสินค้า');
            return false;
        }
        if (!priceReceiveAmt || priceReceiveAmt <= 0) {
            message.warning('กรุณาระบุราคา รับสินค้าเอง');
            return false;
        }
        if (priceFactor && priceFactor < 0) {
            message.warning('ราคา FACTOR ต้องมีค่ามากกว่า 0');
            return false;
        }
        if (priceSrcReceiveFactor && priceSrcReceiveFactor < 0) {
            message.warning('รับสินค้า FACTOR ต้องมีค่ามากกว่า 0');
            return false;
        }
        if (priceSmcFactor && priceSmcFactor < 0) {
            message.warning('กระจายสินค้า FACTOR ต้องมีค่ามากกว่า 0');
            return false;
        }
        if (priceReceiveFactor && priceReceiveFactor < 0) {
            message.warning('รับสินค้าเอง FACTOR ต้องมีค่ามากกว่า 0');
            return false;
        }
        return true;
    }

    async function onSave(data: any) {
        if (isAddMode) {
            try {
                await filepriceZonePricelistServices.addFilepriceZonePricelist(data);
                console.log('addFilepriceZonePricelist', data)
                saveComplete();
            } catch (e) {
                console.log('addFilepriceZonePricelist filed')
                message.error('บันทึกข้อมูล ไม่สำเร็จ');
            }
        } else {
            try {
                await filepriceZonePricelistServices.addFilepriceZonePricelist(data);
                console.log('updateFilepriceZonePricelist', data)
                saveComplete();
            } catch (e) {
                console.log('updateFilepriceZonePricelist filed')
                message.error('อัพเดทข้อมูล ไม่สำเร็จ');
            }
        }
    }

    return (
        <Space direction="vertical" size="large" >
            <Row gutter={[4, 24]} style={{ alignItems: 'center' }}>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>ขนาด :</Typography.Text>
                </Col>
                <Col span={12}>
                    <Input disabled value={zonePrice.sizeCode} />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>ราคา :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='ราคา'
                        defaultValue={priceAmt} onChange={(val: any) => setPriceAmt(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>การคำนวน :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <Select
                        defaultValue={typeCal}
                        style={{ flex: 1 }}
                        onSelect={setTypeCal}
                        options={option}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>ราคา FACTOR :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='ราคา FACTOR'
                        defaultValue={priceFactor} onChange={(val: any) => setPriceFactor(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>ราคา รับสินค้า :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='ราคา รับสินค้า'
                        defaultValue={priceSrcReceiveAmt} onChange={(val: any) => setPriceSrcReceiveAmt(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>รับสินค้า FACTOR :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='รับสินค้า FACTOR'
                        defaultValue={priceSrcReceiveFactor} onChange={(val: any) => setPriceSrcReceiveFactor(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>ราคา กระจายสินค้า :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='ราคา กระจายสินค้า'
                        defaultValue={priceSmcAmt} onChange={(val: any) => setPriceSmcAmt(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>กระจายสินค้า FACTOR :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='กระจายสินค้า FACTOR'
                        defaultValue={priceSmcFactor} onChange={(val: any) => setPriceSmcFactor(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>ราคา รับสินค้าเอง :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='ราคา รับสินค้าเอง'
                        defaultValue={priceReceiveAmt} onChange={(val: any) => setPriceReceiveAmt(val)}
                    />
                </Col>
                <Col span={12} style={{ textAlign: "right" }} >
                    <Typography.Text>รับสินค้าเอง FACTOR :</Typography.Text>
                </Col>
                <Col span={12} style={{ display: 'flex' }}>
                    <InputNumber style={{ flex: 1 }} placeholder='รับสินค้าเอง FACTOR'
                        defaultValue={priceReceiveFactor} onChange={(val: any) => setPriceReceiveFactor(val)}
                    />
                </Col>
            </Row>

            <Space size="large" style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={handleSave}
                    style={{ width: 120 }}
                >
                    บันทึก
                </Button>
                <Button
                    icon={<CloseCircleOutlined />}
                    onClick={onCloseDrawer}
                    style={{ width: 120 }}
                >
                    กลับ
                </Button>
            </Space >
        </Space>
    )
}

export default ZonePricelistDrawer
