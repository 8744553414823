import React, { CSSProperties, FC } from 'react'

interface SectionProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

const Section: FC<SectionProps> = (props) => {

  const { children, style } = props;

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        padding: "20px 20px",
        borderRadius: "8px",
        // boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 2px -1px, 0 1px 3px 0 #0000001a,0 1px 2px -1px #0000001a',
        boxShadow: '0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a',
        ...style
      }}
    >
      {children}
    </div>
  )
}

export default Section