import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'templateExcel';

const getByHeadNameAndEffDate = (name: string, effDate: string) => {
    const param = `name=${name}&effDate=${effDate}`;
    return http.get(url + "/byHeadNameAndEffDate/?" + param);

}

export default { getByHeadNameAndEffDate };