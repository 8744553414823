import React from "react";
import {
  BrowserRouter ,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "src/views/Home";
import LoginForm from "src/views/LoginForm";
import AppRoute from "./views/AppRoute"


const App: React.FC = () => {

  if (process.env.REACT_APP_SHOWLOG !== 'TRUE') {
    console.log = () => {}
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home}    />
        <Route exact path="/login" component={LoginForm} />
        <Route path="/app" component={AppRoute} />
        <Redirect to="/login" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
