import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort+'accountbank';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (name : any , code : any, useFlg : number) => {
    const param = `name=${name}&code=${code}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
    
  };

const addAccountBank = (AccountBank: any) => {
    return http.post(url + "/add", AccountBank);
};

const updateAccountBank = (AccountBank: any) => {
    return http.put(url + "/update", AccountBank);
};

export default {
    getAll,
    getById,
    getByParam,
    addAccountBank,
    updateAccountBank,
}