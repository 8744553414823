/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteTwoTone, ExclamationCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Layout, message, Modal, Row, Select, Table, TimePicker } from "antd";
import dayjs from 'dayjs';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ExBookingRoute } from 'src/domain/exBookingRouteType';
import { ExBookingTruck } from "src/domain/exBookingTruckType";
import { Station } from "src/domain/stationType";
import { TruckPartner } from "src/domain/truckPartnerType";
import bookingTruckServies from 'src/services/bookingTruckServies';
import stationServices from "src/services/stationServices";
import truckPartnerServies from "src/services/truckPartnerServices";
import { SessionUser } from 'src/store/authen/types';
import { showMessage } from "src/utils";
import Section from '../../components/container/Section';
import { currDate, formatDate, formatTime, toDayjs } from '../../utils/dateUtils';

const { Option } = Select;
const { TextArea } = Input;

interface IProps {
    id: number;
    trigger: boolean;
    setTrigger: any;
    cancleTrigger?: boolean;
    setCancleTrigger?: any;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

const truckTypes = [
    { id: 1, truckType: 'LINE HALL', typeName: 'ขนย้ายสินค้า' }
    // { id: 2, truckType: 'DIST', typeName: 'กระจายสินค้า' }
]

const FormBookingTruck: React.FC<IProps> = (props) => {
    const { id, trigger, setTrigger, cancleTrigger, setCancleTrigger } = props;
    const history = useHistory();
    const [form] = Form.useForm();
    const [exBookingTruck, setExBookingTruck] = useState<ExBookingTruck>({} as ExBookingTruck);
    const [partners, setPartners] = useState<TruckPartner[]>([]);
    const [partnerId, setPartnerId] = useState(0);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [routes, setRoutes] = useState<ExBookingRoute[]>([]);
    const [stations, setStations] = useState<Station[]>([]);
    const defaultDate = dayjs(); //moment(CurrDate.getCurrDate(), CurrDate.dateFormat()[0])
    const defaultTime = dayjs();
    const [searchName, setSearchName] = useState('');
    const sessionUser : SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const columns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as "center",
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "สาขา", dataIndex: "refStationName", key: "refStationName",
        },
        {
            title: "#", dataIndex: "delete", key: "delete",
            render: (value: string, item: any, index: number) => {
                return (
                    <DeleteTwoTone twoToneColor="#f5222d" style={{ fontSize: '18px' }} onClick={() => { deleteStation(item) }} />
                )
            },
        },
    ];

    const drawerColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as "center",
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "สาขา", dataIndex: "stationName", key: "stationName",
            render: (value: string, item: any, index: number) => <a onClick={() => addStation(item)}>{value}</a>,
        },
    ];

    useEffect(() => {
        search();
        if (id === 0) {
            loadForm(defaultDate, defaultTime, exBookingTruck);
        } else {
            const fetchData = async () => {
                const data = await loadData(id);
                const date = toDayjs(currDate());  //moment(data.exBookDate, 'YYYYMMDD');
                const time = toDayjs(currDate()); //moment(data.exBookTime, 'HH:mm:ss')
                loadForm(date, time, data);
            }
            fetchData();
        }
    }, []);


    useEffect(() => {
        if (trigger) {
            console.log(form.getFieldValue("routes"));
            form.submit();
        }
        if (cancleTrigger) {
            confirm();
        }
    }, [trigger, cancleTrigger]);


    async function loadForm(date: any, time: any, exBookingTruck: any) {

        const res = await truckPartnerServies.getByParam('', '', '', '', 0)
        if (res.status === 200) {
            setPartners(await res.data)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }

        const types = truckTypes.find((item) => (item.truckType === exBookingTruck.bookingType));
        const partner = await res.data.find((item: any) => (item.id === exBookingTruck.truckPartnerId));

        form.setFieldsValue({
            truckPartnerName: partner !== undefined ? partner.truckPartnerName : null,
            setTruckDate: date,
            setTruckTime: time,
            truckType: types !== undefined ? types?.truckType : null,
            refNo: exBookingTruck.refNo,
            registId: exBookingTruck.registId,
            driverName: exBookingTruck.driverName,
            amount: exBookingTruck.amt,
            remark: exBookingTruck.remark,
            routes: exBookingTruck.exBookingRoutes !== undefined ? exBookingTruck.exBookingRoutes : []
        });
        if (partner !== undefined) setPartnerId(partner.id);
        setRoutes(exBookingTruck.exBookingRoutes !== undefined ? exBookingTruck.exBookingRoutes : []);
    }

    async function loadData(bookId: number) {
        const res = await bookingTruckServies.getById(bookId);
        if (res.status === 200) {
            setExBookingTruck(res.data)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
        return res.data;
    }

    const onValuesChange = async (values: any) => {
        console.log("value change", values);
    }

    const onFinish = async (values: any) => {
        console.log(' on finish ',values);

        if (routes.length <= 1) {
            message.error('ตัองระบุสาขาอย่างน้อย 2 สาขา ต้นทาง/ปลายทาง');
            setTrigger(false);
            return;
        }
        let _routes: ExBookingRoute[] = [] as ExBookingRoute[];
        let i = 1;
        for (const route of routes) {
            _routes.push({ seq: i, refStationCode: route.refStationCode, refStationName: route.refStationName })
            i++;
        }
        const bookDate = formatDate(values.setTruckDate); //moment(values.setTruckDate).format('YYYYMMDD')
        const bookTime = formatTime(values.setTruckTime);//moment(values.setTruckTime).format('HHmmss')

        const _save: ExBookingTruck = {
            id: id,
            // truckCode: '',
            registId: values.registId,
            // driverCode: '',
            driverName: values.driverName,
            bookingType: values.truckType,
            refNo: values.refNo,
            amt: values.amount,
            remark: values.remark !== undefined ? values.remark : '',
            truckPartnerId: partnerId,
            exBookDate: bookDate,
            exBookTime: bookTime,
            useFlg: 0,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username,
            savStation: sessionUser.stationCode,
            exBookingRoutes: _routes
        };

        if (id === 0) {
            try {
                bookingTruckServies.addBookingTruck(_save).then((res: any) => {
                    history.replace("/app/bookingTruck");
                    message.success("บันทึกข้อมูลจองรถแล้ว", 1.5);
                }).catch((err: any) => {
                    console.log(err);
                });
            } catch (err) {
                showMessage('' + err);
            }
        }
        console.log('id', id, _save);
        if (id !== 0) {
            bookingTruckServies.updateBookingTruck(_save).then((res: any) => {
                history.replace("/app/bookingTruck");
                message.success("ปรับปรุงข้อมูลจองรถแล้ว", 1.5);
            }).catch((err: any) => {
                console.log(err)
            });
        }
        setTrigger(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        setTrigger(false);
    };

    const addStation = async (value: Station) => {
        if ('DIST' === form.getFieldValue('truckType') && routes.length > 0) {
            showMessage('ประเภทการวิ่งรถ กระจายสินค้า สามารถมีได้เพียง 1 สาขา')
            return;
        }

        let newRoutes = [...routes]
        newRoutes.push({
            seq: newRoutes.length + 1, id: value.id,
            refStationCode: value.stationCode, refStationName: value.stationName
        });
        setRoutes(newRoutes);
        form.setFieldsValue({routes: newRoutes});
    }

    const deleteStation = async (item: ExBookingRoute) => {
        let newRoutes = [] as ExBookingRoute[]
        for (let route of routes) {
            if (route.id !== item.id) {
                newRoutes.push(route)
            }
        }
        setRoutes(newRoutes);
    }

    const onOpenStationDrawer = () => {
        setSearchName('');
        search();
        setIsShowDrawer(true);
    }

    const onCloseStationDrawer = () => {
        setIsShowDrawer(false);
    }

    const search = async () => {
        const res = await stationServices.getByParam('', searchName,'', '', 0)
        if (res.status === 200) {
            setStations(await res.data)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    const partnersSelect = partners.map((obj: any) => <Option key={obj.id} value={obj.id}>{obj.truckPartnerName}</Option>);
    const typeSelect = truckTypes.map((obj: any) => <Option key={obj.id} value={obj.truckType}>{obj.typeName}</Option>);

    const confirm = () => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'ยืนยันยกเลิกตั้งรถ',
            okText: 'ใช่',
            cancelText: 'ไม่',
            onOk: () => { handleCancleBooking() },
            onCancel: () => { handleCancelModal() }
        });
    };

    const handleCancleBooking = () => {
        bookingTruckServies.updateBookingTruckStatus(id, -1,
            0, //userid
            'admin', //username
            '0' //stationcode
        ).then((res: any) => {
            history.replace("/app/bookingTruck");
            message.success("ยกเลิกข้อมูลจองรถแล้ว", 1.5);
        }).catch((err: any) => {
            console.log(err)
        });
        setCancleTrigger(false);
    }

    const handleCancelModal = () => {
        setCancleTrigger(false);
    }

    return (
			<>
				<Form
					form={form}
					name="basic"
					initialValues={{
						// setTruckDate: defaultDate,
						// setTruckTime: defaultTime,
						remark: "",
					}}
					layout="vertical"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
                    onValuesChange={onValuesChange}
				>
					<Row gutter={[20, 10]}>
						<Col span={12}>
							<Section>
								<Form.Item
									label="บริษัท"
									name="truckPartnerName"
									rules={[{ required: true, message: "Required : บริษัท" }]}
								>
									<Select
										filterOption={true}
										placeholder="เลือกบริษัท"
										optionFilterProp="children"
										onSelect={(item: any) => setPartnerId(item)}
										showSearch
									>
										{partnersSelect}
									</Select>
								</Form.Item>

								<Form.Item
									label="ประเภทการวิ่งรถ"
									name="truckType"
									rules={[{ required: true, message: "Required : ประเภทการวิ่งรถ" }]}
								>
									<Select
										placeholder="เลือกประเภทการวิ่งรถ"
										optionFilterProp="children"
										filterOption={true}
										onChange={() => {
											setRoutes([]);
										}}
										showSearch
									>
										{typeSelect}
									</Select>
								</Form.Item>

								<Row>
									<Col span={12}>
										<Form.Item
											label="วันที่ตั้งรถ"
											name="setTruckDate"
											rules={[{ required: true, message: "Required : วันที่ตั้งรถ" }]}
										>
											<DatePicker format={"DD MMM YYYY"} style={{ width: 200 }} />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="เวลาตั้งรถ"
											name="setTruckTime"
											rules={[{ required: true, message: "Required : เวลาตั้งรถ" }]}
										>
											<TimePicker allowClear={false} style={{ width: 200 }} />
										</Form.Item>
									</Col>
								</Row>
								<Form.Item label="หมายเหตุ" labelCol={{ offset: 2 }} name="remark" wrapperCol={{ offset: 0 }}>
									<TextArea rows={3} showCount maxLength={250} />
								</Form.Item>
							</Section>
						</Col>
						<Col span={12}>
							<Section>
								<Form.Item
									label="เลขที่อ้างอิง"
									name="refNo"
									rules={[{ required: true, message: "Required : เลขที่อ้างอิง" }]}
								>
									<Input />
								</Form.Item>

								<Form.Item
									label="ทะเบียนรถ"
									name="registId"
									rules={[{ required: true, message: "Required : ทะเบียนรถ" }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label="พนักงานขับรถ"
									name="driverName"
									rules={[{ required: true, message: "Required : พนักงานขับรถ" }]}
								>
									<Input />
								</Form.Item>

								<Form.Item label="ราคา" name="amount">
									<Input />
								</Form.Item>
							</Section>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<Section style={{ marginTop: 30 }}>
								<Button
                                    size="middle"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => onOpenStationDrawer()}
                                    type='primary'
                                    style={{marginBottom: 10}}
                                    >
									สาขา
								</Button>
								<Form.Item
									name="routes"
									rules={[{ required: true, message: "Required : กรุณาเลือกสาขา" }]}
								>
									<Table
                                        size='small'
                                        dataSource={routes}
                                        columns={columns}
                                        rowKey={(item: ExBookingRoute) => item.seq}

                                    />
								</Form.Item>
							</Section>
						</Col>
					</Row>


				</Form>

				<Drawer
					title="เพิ่มสาขา"
					width="560px"
					onClose={onCloseStationDrawer}
					open={isShowDrawer}
					bodyStyle={{ paddingBottom: 80 }}
					closable={false}
					destroyOnClose={true}
				>

						<Form name="basic" initialValues={{ searchName: "" }}>
							<Row gutter={5}>
								<Col
									span={5}
									style={{
										textAlign: "right",
										paddingRight: "5px",
									}}
								>
									<div style={{ padding: "8px 0" }}>ชื่อสาขา :</div>
								</Col>
								<Col span={10} push={0}>
									<Input onChange={(e) => setSearchName(e.target.value)} onPressEnter={search} />
								</Col>
								<Col span={6} pull={0}>
									<Button   icon={<SearchOutlined />} onClick={() => search()}>
										ค้นหา
									</Button>
								</Col>
							</Row>
							<Row>
								<Col span={24} style={{ paddingTop: 10}}>
									<Table
                                        size='small'
                                        dataSource={stations}
                                        columns={drawerColumns}
                                        rowKey={(item: Station) => item.id}
                                        pagination={false} />
								</Col>
							</Row>
						</Form>

				</Drawer>
			</>
		);
}

export default FormBookingTruck
