import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined ,SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Class} from "../../domain/classType";
import classServices from 'src/services/classServices';
import Section from 'src/components/container/Section';

const ListClass = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [classList,setClassList] = useState<Class[]>([]);
    const[searchName,setSearchName] = useState<any>("");
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            align: "center" as "center",
            render: (value: number, item: Class, index: number) => (<>{(page - 1) * 20 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "classNo",
            key: "classNo",
            //align: "center" as "center",
        },
        {
            title: "ประเภทข้อมูลหลัก",
            dataIndex: "className",
            key: "className",
            //align: "center" as "center",
            render: (value: string,item:Class,index:number) => <Link to={`/app/class/edit/${item.id}`}>{value}</Link>,
        },
    
    ];
    useEffect(() => {

        classServices.getAll()
            .then((res) => {
                console.log(res.data)
                setClassList(res.data)
            })

    }, []);

    const search = async () =>{
        const responseSearch = await classServices.getByParam(searchName, 0);
        setClassList(responseSearch.data);
    }

    return (
        <div>
        <PageHeader title="กำหนดข้อมูลหลัก">
                <Space>
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/class/add") }}
                    >เพิ่ม
                    </Button>
                </Space>
        </PageHeader>
        <Section style={{ display: "flex", flexDirection: "column" }}>
                <Form layout="vertical">
                    <Row gutter={[20, 10]} style={{ display: "flex", alignItems: "start" }}>
                        <Col span={8}>
                            <Form.Item label="ประเภทข้อมูล" name="searchName">
                                <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end'}} >
                            <Button  icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30}}>
                                ค้นหา
                            </Button>
					    </Col>
                    </Row>
                </Form>
        </Section>
        <Section style={{ marginTop: 30, paddingTop: 30 }}>
            <Table 
                pagination={{
                    pageSize: 20,
                    onChange(current) {
                        setPage(current);
                    },
                    showSizeChanger:false
                }}
                rowKey={(item:Class) => item.id}
                columns={columns}
                dataSource={classList} 
                size="small" 
            />
        </Section>
    </div>
    )
}

export default ListClass;
