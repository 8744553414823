
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { showDate } from '../../../src/utils/dateUtils';

interface IProps {
    fileprice: any;
}

const FilepriceViewHead: React.FC<IProps> = (props) => {
    const { fileprice } = props;

    return (
        <>
            <Row gutter={[0, 24]}>
                <Col span={3} style={{ textAlign: "right", paddingRight: "5px", }} >
                    <Typography.Text>เลขที่อ้างอิง :</Typography.Text>
                </Col>
                <Col span={9}>
                    <Typography.Text>{fileprice.id}</Typography.Text>
                </Col>
            </Row>
            <br />
            <Row gutter={[0, 24]}>
                <Col span={3} style={{ textAlign: "right", paddingRight: "5px", }} >
                    <Typography.Text>รหัสแฟ้ม :</Typography.Text>
                </Col>
                <Col span={9}>
                    <Typography>{fileprice.fileCode}</Typography>
                </Col>
                <Col span={3} style={{ textAlign: "right", paddingRight: "5px", }} >
                    <Typography.Text>ชื่อแฟ้ม :</Typography.Text>
                </Col>
                <Col span={9}>
                    <Typography>{fileprice.fileName}</Typography>
                </Col>
                <Col span={3} style={{ textAlign: "right", paddingRight: "5px", }} >
                    <Typography.Text>วันที่เริ่มใช้ :</Typography.Text>
                </Col>
                <Col span={9}>
                    <Typography>{fileprice.startDate != undefined ? showDate(fileprice.startDate) : ''}</Typography>
                </Col>
                <Col span={3} style={{ textAlign: "right", paddingRight: "5px", }} >
                    <Typography.Text>วันที่สิ้นสุด :</Typography.Text>
                </Col>
                <Col span={9}>
                    <Typography>{fileprice.endDate != undefined ? showDate(fileprice.endDate) : ''}</Typography>
                </Col>
            </Row>
        </>
    )
}

export default FilepriceViewHead
