import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'fleetdrvstation';

const getbystationCode = (stationcode: string) => {
    const param = `stationcode=${stationcode}`;
    return http.get(url + "/getbystationCode/?" + param);
}

const getbydriverCode = (driverCode: string) => {
    const param = `driverCode=${driverCode}`;
    console.log(url + "/getbydriverCode/?" + param)
    return http.get(url + "/getbydriverCode/?" + param);
}

const addFleetDrvStation = (FleetDrv: any) => {
    console.log(url + "/addfleetdrvstation", FleetDrv)
    return http.post(url + "/addfleetdrvstation", FleetDrv);
};

const updateFleetDrvStation = (FleetDrvStation: any) => {
    return http.put(url + "/updatefleetdrvstation", FleetDrvStation);
};

export default {
    getbystationCode,
    getbydriverCode,
    addFleetDrvStation,
    updateFleetDrvStation
}