import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "src/store/authen/slice";
import { reducer as userReducer } from "src/store/user/slice";
import { reducer as logReducer } from "src/store/log/slice";


const rootReducer = combineReducers({
  auth: authReducer,
  userState: userReducer,
  logState: logReducer,
});

export default rootReducer;
