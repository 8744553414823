import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'reportDistribute';

const getReportDelivered = (sDate: string, eDate: string, stationSrcCode: string) => {
    const param = `sDate=${sDate}&eDate=${eDate}&stationSrcCode=${stationSrcCode}`;
    return http.get(url + "/delivered/?" + param);
};

const getReportCustReceive = (sDate: string, eDate: string, stationDestCode: string, itemBy: string) => {
    const param = `sDate=${sDate}&eDate=${eDate}&stationDestCode=${stationDestCode}&itemBy=${itemBy}`;
    return http.get(url + "/custReceive/?" + param);
};

export default { getReportDelivered, getReportCustReceive }