import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'deliverytrip';

const getHistoryByTruckCode = (code: string) => {
    // console.log(`${url}/bytruckcode/${code}`)
    return http.get(`${url}/bytruckcode/${code}`);
}

const getHitoryBydrvCode = (code: string) => {
    return http.get(`${url}/bydrvcode/${code}`);
}

export default {
    getHistoryByTruckCode,
    getHitoryBydrvCode,
}