import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'filepriceSizing';

const getSizingsByFilter = (filepriceId: number, productTypeCode: string, fileCode: string, sizeCode: string) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&fileCode=${fileCode}&sizeCode=${sizeCode}`;
    return http.get(url + "/byparam/?" + param);
};

const isDuplicate = (filepriceId: number, productTypeCode: string, sizeCode: string) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&sizeCode=${sizeCode}`;
    return http.get(url + "/isDuplicate/?" + param);
};

const addFilepriceSizing = (list: any) => {
    return http.post(url + "/add", list);
};

const isUsedSizing = (filepriceId: number, productTypeCode: string, sizeCode: string) => {
    const param = `filepriceId=${filepriceId}&productTypeCode=${productTypeCode}&sizeCode=${sizeCode}`;
    return http.get(url + "/isUsedSizing/?" + param);
}

const editFilepriceSizing = (list: any) => {
    return http.post(url + "/edit", list);
};

export default { getSizingsByFilter, isDuplicate, addFilepriceSizing, isUsedSizing, editFilepriceSizing }