export const menuModules = [
  // { id: 1, name: "overview", icon: "BlockOutlined", code: "001" },
  { id: 2, name: "Franchise", icon: "Franchise", code: "001" },
  { id: 3, name: "Customer", icon: "Users", code: "002" },
  { id: 4, name: "Report", icon: "Report", code: "003" },
  { id: 5, name: "Settings", icon: "Settings", code: "004" },
];

export const menus = [
  // {
  //   moduleCode: "001",
  //   moduleMenus: [{ id: 1, name: "Overview", path: "/app/dashboard" }],
  // },
  {
    moduleCode: "001",
    moduleMenus: [
      {
        id: 10010,
        key: "title-1001",
        name: "ข้อมูล Franchise",
        type: "title"
      },


      {
        id: 10011,
        key: "franchise",
        name: "กำหนดข้อมูล แฟรนไชส์",
        path: "/app/franchisee",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      {
        id: 10012,
        key: "franchise-station",
        name: "กำหนดสาขา NIM SHOP",
        path: "/app/station",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      {
        id: 10013,
        key: "franchise-station-aria",
        name: "กำหนด STATION AREA",
        path: "/app/stationarea",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      {
        id: 10018,
        key: "franchise-fileprice",
        name: "กำหนดแฟ้มราคา",
        path: "/app/fileprice",
        roles: ["BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      {
        id: 10014,
        key: "201-divider",
        type: "divider",
      },
      {
        id: 10015,
        key: "title-1002",
        name: "เกียวกับรถ",
        type: "title"
      },
      {
        id: 10016,
        key: "francise-truck",
        name: "กำหนดสาขาให้รถ",
        path: "/app/fleetstation",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      {
        id: 10017,
        key: "franchise-setup-truck",
        name: "ตั้งรถส่วนกลาง (ขนย้าย)",
        path: "/app/bookingtruck",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },

      {
        id: 10018,
        key: "franchise-fleet-driver",
        name: "ข้อมูลพนักงานขับรถ",
        path: "/app/fleetstation/driver",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },

      {
        id: 10019,
        key: "franchise-fleet-truck",
        name: "ข้อมูลรถ",
        path: "/app/fleetstation/truck",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },

      // { id: 13, name: "BankTransfer", path: "/app/banktransfer", roles: ["SUPERVISOR", "ADMIN"] },
      // { id: 4, name: "Payment", path: "/app/payment", roles: ["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"] },
    ],
  },
  {
    moduleCode: "002",
    moduleMenus: [
      // { id: 5, name: "Counter", path: "/app/counter" }
      {
        id: 5,
        key: "customer",
        name: "กำหนดข้อมูลลูกค้า",
        path: "/app/customer",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      // {
      //   id: 6,
      //   key: "setupCar",
      //   name: "ตั้งรถ",
      //   path: "/app/trip",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
      // {
      //   id: 14,
      //   key: "setupSmallTruck",
      //   name: "ตั้งรถ (กระจาย)",
      //   path: "/app/bookingSmc",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
    ],
  },
  {
    moduleCode: "003",
    moduleMenus: [
      {
        id: 30001,
        key: "francise-smc-report",
        name: "รายงานการปล่อยรถกระจาย",
        path: "/app/reportsmc",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      

      // {
      //   id: 9,
      //   key: "getPaid",
      //   name: "รับชำระ",
      //   path: "/app/payment",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
      // {
      //   id: 10,
      //   key: "receipt",
      //   name: "ใบเสร็จรับเงิน",
      //   path: "/app/receipt",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
      // {
      //   id: 11,
      //   key: "dailyPaymentReport",
      //   name: "รายงานสรุปรับเงินประจำวัน",
      //   path: "/app/payment/report/paymentSumReport",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
      // {
      //   id: 12,
      //   key: "dailyPaymentReportAcc",
      //   name: "รายงานสรุปรับเงินประจำวัน(บัญชี)",
      //   path: "/app/payment/report/paymentReportAcc",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
      // {
      //   id: 17,
      //   key: "deposit",
      //   name: "สรุปฝากเงินประจำวัน",
      //   path: "/app/deposit",
      //   roles: ["SUPERVISOR", "ADMIN"],
      // },
      {
        id: 18,
        key: "delivery",
        name: "รายงานข้อมูลการรับสินค้า",
        path: "/app/report/summaryDelivered",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
      {
        id: 19,
        key: "customerReceive",
        name: "รายงานข้อมูลลูกค้ารับสินค้า",
        path: "/app/report/summaryCustomerReceive",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
    ],
  },
  {
    moduleCode: "004",
    moduleMenus: [
      {
        id: 13,
        key: "setup-class",
        name: "กำหนดข้อมูลหลัก",
        path: "/app/class",
        roles: ["ADMIN"],
      },
      {
        id: 14,
        key: "setup-prename",
        name: "กำหนดคำนำหน้า",
        path: "/app/prename",
        roles: ["ADMIN"],
      },
      {
        id: 16,
        key: "setup-bank",
        name: "กำหนดธนาคาร",
        path: "/app/bank",
        roles: ["ADMIN"],
      },
      {
        id: 16,
        key: "setup-bank-account",
        name: "กำหนดบัญชีธนาคาร",
        path: "/app/accountbank",
        roles: ["ADMIN"],
      },
    ],
  },
  {
    moduleCode: "999",
    moduleMenus: [
      {
        id: 15,
        name: "Change password",
        path: "/app/profile/changepassword",
        roles: ["BRANCH_USER", "BRANCH_MANAGER", "SUPERVISOR", "ADMIN"],
      },
    ],
  },
];