import dayjs, { Dayjs, isDayjs } from "dayjs";
import moment, { isMoment } from "moment";
import { deflate } from "zlib";

export function strDate2ReportDate(strDate: string, type?: string) {
    if (strDate === null || strDate === '' || strDate === 'null') return '';

    if (strDate.length > 8) {
        strDate = strDate.substring(0, 8);
    }

    const year = strDate.substring(0, 4);
    const yearTh: string = '' + (Number(year) + 543);
    const month = strDate.substring(4, 6);
    const day = strDate.substring(6, 8);
    let date = day + "/" + month + "/";
    date += (type && type === 'TH') ? + yearTh : + year;
    return date;
}

export function strTime2ReportTime(strTime: string) {
    if (strTime === null || strTime === '' || strTime === 'null') return '';

    if (strTime.length > 6) {
        strTime = strTime.substring(0, 6);
    }

    const hh = strTime.substring(0, 2);
    const mm = strTime.substring(2, 4);
    const ss = strTime.substring(4, 6);
    const time = hh + ":" + mm + ":" + ss;
    return time;
}

const DEFAULT_DATE_FORMAT = "YYYYMMDD";
const DEFAULT_SHOWDATE_FORMAT = "DD/MM/YYYY";

export const currDate = ():string => {
    return dayjs().format(DEFAULT_DATE_FORMAT);
}

export const formatTime = (value:object): string => {
    if (isDayjs(value)) {
        return value.format("hhmmss");
    }

    if (isMoment(value)) {
        return value.format("hhmmss");
    }

    throw Error("date value not support");
}

export const formatDate = (value:object):string => {


    if (isDayjs(value)) {
        return value.format(DEFAULT_DATE_FORMAT);
    }

    if (isMoment(value)) {
        return value.format(DEFAULT_DATE_FORMAT);
    }

    throw Error("date value not support");
}

export const showDate = (value: string): string => {
    if (!value) return " x ";

    let dayJsdate = dayjs(value,DEFAULT_DATE_FORMAT, true);

    if (isDayjs(dayJsdate)) {
        return dayJsdate.format(DEFAULT_SHOWDATE_FORMAT);
    }

    let momentDate = moment(value,DEFAULT_DATE_FORMAT, true);
    if (isMoment(momentDate)) {
        return momentDate.format(DEFAULT_SHOWDATE_FORMAT);
    }

    throw Error("date value not support");
}

export const showTime = (date: string, time: string): string => {
    if (!date) return " x ";

    const value = date + time;
    const format = DEFAULT_DATE_FORMAT + "hhmmss";

    let dayJsdate = dayjs(value,format, true);

    if (isDayjs(dayJsdate)) {
        return dayJsdate.format("hh:mm:ss");
    }

    let momentDate = moment(value,format, true);
    if (isMoment(momentDate)) {
        return momentDate.format("hh:mm:ss");
    }

    throw Error("date value not support");
}


export const toDayjs = (value: string):Dayjs => {
    return dayjs(value, DEFAULT_DATE_FORMAT);
}

export const firstDateOfMonth = ():string => {
    return dayjs().startOf("month").format(DEFAULT_DATE_FORMAT);
}

export const lastDateOfMonth = ():string => {
    return dayjs().endOf("month").format(DEFAULT_DATE_FORMAT);
}