import axios from 'axios';
import http from '../lib/http';
import { servicePort } from './configServices';
const url = servicePort + 'fleetstation';
const fleetUrl = process.env.REACT_APP_FLTURL;

const getFleetStationByParam = async (params: any) => {
    let url1 = `${url}/byparam`;
    let res = await axios.post(url1, params);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}

const getFleetByTruckCode = async (params: any) => {
    return http.post(`${url}/getfleetbytruckcode`, params);
    // let url1 = `${url}/getfleetbytruckcode`;
    // console.log(url1)
    // let res = await axios.post(url1, params);
    // if(res.status === 200){
    //     return res.data;
    // }else{
    //     return null;
    // }
}

const getAllFleetTruck = () => {
    console.log(`${url}/getallfleettruck`)
    return http.post(`${url}/getallfleettruck`);
 };

const addFleet = (Fleet: any) => {
    return http.post(url + "/addfleet", Fleet);
};
const addFleetStation = (FleetStation: any) => {
    return http.post(url + "/addfleetstation", FleetStation);
};

const updateFleetStation = (FleetStation: any) => {
    return http.put(url + "/updatefleetstation", FleetStation);
};

const getAllFleet = (regitsId : string , useFlg : number) => {
    //const fleetUrl = "https://api15.nimsoft.xyz/truck/truckDistribute";
    return http.get(fleetUrl + "/truck/truckDistribute");
}

const getbytruckCode = (driverCode: string) => {
    const param = `truckCode=${driverCode}`;
    console.log(url + "/getbytruckCode/?" + param)
    return http.get(url + "/getbytruckCode/?" + param);
}

const addFleetTruckStation = (Fleet: any) => {
    console.log(url + "/addfleettruckstation", Fleet)
    return http.post(url + "/addfleettruckstation", Fleet);
};


export default {
    getFleetStationByParam,
    addFleetStation,
    getAllFleet,
    updateFleetStation,
    getFleetByTruckCode,
    addFleet,
    getAllFleetTruck,
    getbytruckCode,
    addFleetTruckStation
}