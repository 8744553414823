import { Button, Space, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import Flex from "src/components/container/Flex";

/* icon */
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
  title: string;
  subTitle?: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({
  title,
  subTitle,
  onClose,
  children,
}) => {
  const history = useHistory();

  const closeHandler = () => {
    if (onClose) onClose();
  };

  const titleComp = typeof title === "string"
          ? (<Typography.Title
              level={3}
              style={{ fontWeight: 500, fontSize: 28, paddingBottom: 0, marginBottom: 0}}>
            {title ? title : "..."}
            </Typography.Title>)
          : title;

  return (
    <div
      className="app-page-header"
      style={{ marginBottom: 30}}
      // style={{ height: "65px", padding: "0px 20px" }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
        {/* <h3 className="mb-0 mr-3 font-weight-semibold">
          {title ? title : "..."}
        </h3> */}
        <div>
          {titleComp}
          <Typography.Text type="secondary" style={{ fontSize: 12}}>{subTitle}</Typography.Text>
        </div>


        <div style={{display: 'flex',  justifyContent: 'space-between', alignItems:'center'}}>
          <div className="action-container">{children}</div>
          <div>
            {onClose && (
              <Button
                type="text"
                className="btn-close"
                onClick={closeHandler}
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
