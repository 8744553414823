import { Button, Col, DatePicker, Form, Image, message, Row, Select, Table } from "antd";
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import PageHeader from 'src/layouts/PageHeader';
import reportDistributeService from 'src/services/reportDistributeService';
import stationServices from 'src/services/stationServices';
import { showMessage } from 'src/utils';
import { firstDateOfMonth, lastDateOfMonth, toDayjs } from 'src/utils/dateUtils';
import excelImg from "src/views/images/excel.png";
import Section from '../../components/container/Section';
import { currDate, formatDate, showDate } from "../../utils/dateUtils";
import numberUtils from '../../utils/numberUtils';

const { Option } = Select;
const { RangePicker } = DatePicker;
const itemBys = [
    { itemByCode: '', itemByName: 'ทั้งหมด' },
    { itemByCode: 'RECEIVE', itemByName: 'ลูกค้ามารับสินค้า' },
    { itemByCode: 'SMC', itemByName: 'กระจายสินค้า' },
]

const SummaryCustomerReceive = () => {
    const [form] = Form.useForm();
    const [stations, setStations] = useState([{ stationCode: '', stationName: 'ทั้งหมด' }]);
    const tableRef = useRef(null);
    const [listData, setListData] = useState([]);
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');
    const [stationCode, setstationCode] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadList, setIsLoadList] = useState(false);

    const columns = [
        {
            title: "รายงานข้อมูลลูกค้ารับสินค้า",
            children: [
                {
                    title: 'วันที่ ' + showDate(sDate) + ' ถึง ' + showDate(eDate),
                    children: [
                        {
                            title: "ลำดับ", dataIndex: "index", key: "index", //fixed: 'left' as AlignType,
                            align: "center" as AlignType, width: 50,
                            render: (value: number, item: any, index: number) => (<>{index + 1}</>)
                        },
                        {
                            title: "เลขที่ใบส่งสินค้า", dataIndex: "billNo", key: "billNo", //fixed: 'left' as AlignType,
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "วันที่ใบส่งสินค้า", dataIndex: "billDate", key: "billDate",
                            align: "center" as AlignType, width: 150,
                            render: (value: any, item: any, index: number) => {
                                return <span>{value && value !== 'null' ? showDate(value) : '-'}</span>;
                            },
                        },
                        {
                            title: "วันที่ลูกค้ารับสินค้า", dataIndex: "itemDate", key: "itemDate",
                            align: "center" as AlignType, width: 150,
                            render: (value: any, item: any, index: number) => {
                                return <span>{value && value !== 'null' ? showDate(value) : '-'}</span>;
                            },
                        },
                        {
                            title: "สาขาต้นทาง", dataIndex: "stationSrcName", key: "stationSrcName",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "สาขาปลายทาง", dataIndex: "stationDestName", key: "stationDestName",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "สาย", dataIndex: "stationLineCode", key: "stationLineCode",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "ผู้รับ", dataIndex: "consigneeName", key: "consigneeName",
                            width: 250,
                        },
                        {
                            title: "ผู้ส่ง", dataIndex: "consignorName", key: "consignorName",
                            width: 250,
                        },
                        {
                            title: "รายการสินค้า", dataIndex: "itemName", key: "itemName",
                            width: 200,
                        },
                        {
                            title: "ขนาด", dataIndex: "sizeCode", key: "sizeCode",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "จำนวน", dataIndex: "shipQty", key: "shipQty",
                            align: "center" as AlignType, width: 100,
                            render: (value: number, item: any, index: number) => (
                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {value}
                                </Col>
                            )
                        },
                        {
                            title: "หน่วย", dataIndex: "unitName", key: "unitName",
                            align: "center" as AlignType, width: 150,
                        },
                        {
                            title: "ราคารวม", dataIndex: "totalAmt", key: "totalAmt",
                            align: "right" as AlignType, width: 150, fixed: 'right' as AlignType,
                            render: (value: number, item: any, index: number) => (
                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {numberUtils.numberWithCommas(value)}
                                </Col>
                            )
                        },
                        {
                            title: "สถานะการชำระเงิน", dataIndex: "paymentStatus", key: "paymentStatus",
                            align: "center" as AlignType, width: 100,
                        },
                        {
                            title: "สถานะใบส่งสินค้า", dataIndex: "billStatus", key: "billStatus",
                            align: "center" as AlignType, width: 100,
                        },
                        {
                            title: "ประเภทการรับสินค้า", dataIndex: "itemBy", key: "itemBy",
                            align: "center" as AlignType, width: 100,
                        },
                    ]
                }
            ]
        },
    ];

    useEffect(() => {
        if (!isLoaded) {
            setStations([{ stationCode: '', stationName: 'ทั้งหมด' }])
            renderStation();
            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isLoadList) {
            onDownload();
            setIsLoadList(false);
        }
    }, [isLoadList]);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'SumCustReceive_' + stationCode + sDate + eDate,
        sheet: 'report'
    })

    const renderStation = async () => {
        const res = await stationServices.getByParam('', '', '', '', 0)
        if (res.status === 200) {
            const data = await res.data;
            setStations(stations => stations.concat(data))
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    function onSearch(values: any) {
        if (values) {
            console.log("search param", values, values.rangeDate.toString());
        }

        let sDate: string = currDate();
        let eDate: string = currDate();

        if (values !== null && values.rangeDate !== undefined) {
            sDate = formatDate(values.rangeDate[0])
            eDate = formatDate(values.rangeDate[1])
        }
        setSDate(sDate);
        setEDate(eDate);
        setstationCode(form.getFieldValue('stationDestCode'));

        (async () => {
            try {
                const res = await reportDistributeService.getReportCustReceive(
                    sDate,
                    eDate,
                    form.getFieldValue('stationDestCode'),
                    form.getFieldValue('itemBy')
                );

                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].index = i + 1;
                    }
    
                    setListData(res.data);
                    setIsLoadList(true);
                } else {
                    showMessage("ไม่พบข้อมูล!");
                }
            } catch (err) {
                showMessage("ไม่พบข้อมูล!");
            }
        })();
    }

    const onFinishFailed = (values: any) => {
        message.info("ไม่พบข้อมูล");
    };

    return (
        <>
            <PageHeader
                title="รายงานข้อมูลลูกค้ารับสินค้า"
            />

            <div>
                <Form
                    form={form}
                    name="basic"
                    initialValues={{
                        rangeDate: [toDayjs(firstDateOfMonth()), toDayjs(lastDateOfMonth())],
                        stationDestCode: "",
                        itemBy: "",
                    }}
                    layout="vertical"
                    onFinish={onSearch}
                    onFinishFailed={onFinishFailed}
                >
                    <Section style={{ display: "flex", flexDirection: "column" }}>
                        <Row gutter={[20, 10]}>
                            <Col span={6}>
                                <Form.Item label="วันที่ลูกค้ารับสินค้า" name="rangeDate">
                                    <RangePicker format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="สาขาปลายทาง" name="stationDestCode">
                                    <Select>
                                        {stations.map((item: any) => (
                                            <Option value={item.value} key={item.stationCode}>
                                                {item.stationName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="ประเภทการรับสินค้า" name="itemBy">
                                    <Select>
                                        {itemBys.map((item: any) => (
                                            <Option value={item.value} key={item.itemByCode}>
                                                {item.itemByName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={3} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    onClick={() => form.submit()}
                                    style={{ width: 120, marginTop: 30 }}
                                >
                                    ส่งออก
                                    <Image
                                        width={30} height={30}
                                        src={excelImg}
                                        preview={false}
                                    />
                                </Button>
                            </Col>
                        </Row>
                    </Section>

                    <Form.Item name="table" style={{ display: "none" }}>
                        <Table
                            columns={columns}
                            dataSource={listData}
                            pagination={false}
                            size="small"
                            ref={tableRef}
                            scroll={{ x: 1500, y: 300 }}
                            summary={(pageData) => {
                                let sumTotalAmt = 0;

                                pageData.forEach(({ totalAmt }) => {
                                    sumTotalAmt += totalAmt;
                                });

                                return (
                                    <Table.Summary fixed>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} colSpan={13} align={"right"}>
                                                รวมรายได้
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={13}>
                                                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <text>{numberUtils.numberWithCommas(sumTotalAmt)}</text>
                                                </Col>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                );
                            }}
                        />
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default SummaryCustomerReceive
