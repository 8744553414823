

import axios from 'axios';
import { fleetRequest } from 'src/domain/fleetRequestType';
import http from '../lib/http';
import { fleetPort } from './configServices';


 const url = fleetPort + 'fleetrequest';
// const url = "http://localhost:8085/fleetrequest";
// const url = "https://app8.nimsoft.xyz/api/bookingtest";

 const getDeliveryStatus = async (statusRequest: any[]):Promise<fleetRequest[]> => {
    const response =  await http.post(`${url}/deliverystatus/`,statusRequest);
    return response.data;
}



const getTruckDataFromFleet = async (params: any) => {
    let url1 = `${url}/getTruck`;
    let res = await axios.post(url1, params);
    if(res.status === 200){
        return res.data;
    }else{
        return null;
    }
}

export default {
    getDeliveryStatus,
    getTruckDataFromFleet
}