import { Button, Col, Form, Input, Radio, Row, Select, Table, message,Modal } from "antd";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router";
import { Customer,CustomerTel } from "src/domain/customerType"
import { PreName } from "src/domain/preNameType";
import customerServices from "src/services/customerServices";
import prenameServices from "src/services/prenameServices";
import { showMessage, handleEnter } from "src/utils";
import { DeleteTwoTone ,PlusCircleOutlined, MinusCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import { SessionUser } from "src/store/authen/types";
import { useSelector } from "react-redux";
import { LocationView } from "src/domain/locationViewType";
import locationServices from "src/services/locationServices";
import Section from "../../components/container/Section";

const { Option } = Select;

interface IProps {
  id: number;
  trigger: boolean;
  setTrigger: any;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FormCustomer: React.FC<IProps> = (props) => {
  const { id, trigger, setTrigger } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const [customerTels, setCustomerTels] = useState<CustomerTel[]>([]);
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [prename, setPrename] = useState<PreName[]>([]);
  const [prenameId, setPrenameId] = useState(Number);
  const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  const [locationCode, setLocationCode] = useState(String);
  const [locationAddrPath, setLocationAddrPath] = useState(String);
  const [zipcode, setZipCode] = useState(String);
  const [selectId, setSelectId] = useState(Number);
  const [page, setPage] = React.useState(1);
  const [custTel, setCustTel] = useState<CustomerTel>({} as CustomerTel);


  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: number, item: CustomerTel, index: number) => (<>{(page - 1) * 20 + index + 1}</>)
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "custTel",
      key: "custTel",
      render: (value: string, item: CustomerTel, index: number) => {
        return (
          <div onClick={() => {
            console.log('edit item')
            onEditItem(item.id)
          }} style={{ cursor: "pointer" }}>
            {value}
          </div>
        )
      },
    },
    {
      title: "#", dataIndex: "delete", key: "delete",
      render: (value: string, item: any, index: number) => {
          return (
              <DeleteTwoTone twoToneColor="#f5222d" style={{ fontSize: '18px' }} onClick={() => { deleteCustomerTel(item.id) }} />
      )}
    },
  ];
  const onEditItem = async (selectedId: number) => {
    let selectTel = customer.customerTels.filter((item) => item.id === selectedId);
    setCustTel(selectTel[0]);
    setSelectId(selectedId);
  }

  const deleteCustomerTel = async (toDeleteId : number) => {
    Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: 'ยืนยันลบเบอร์โทรศัพท์',
        okText: 'ใช่',
        cancelText: 'ไม่',
        onOk: () => { 
           customerServices.deleteCustomerTel(toDeleteId);
           let newTels = [] as CustomerTel[]
           for (let tel of customerTels) {
                if (tel.id !== toDeleteId) {
                   newTels.push(tel)
                }
           }
            setCustomerTels(newTels);
         },
        onCancel: () => {  }
    });
};

  useEffect(() => {
    getAllLocations();
    getAllPrenames();
    if (id !== 0) {
      getCustomer();
    }
  }, []);

  const getAllLocations = async () => {
    const res = await locationServices.getAll();
    setLocationOptions(res.data);
  }
  const getCustomer = async () => {
    const res = await customerServices.getCustomerTelByCustomerId(Number(id));
    
    let findcustomer = res.data;
    console.log(" findcustomer ", findcustomer);
    setPrenameId(findcustomer.prenameId);
    setLocationCode(findcustomer.locationCode);
    setZipCode(findcustomer.zipCode);

    const resprename = await prenameServices.getById(findcustomer.prenameId);
    setPrename(resprename.data.prename);

    const locationview = await locationServices.getByTambonCode(findcustomer.locationCode);
    setLocationAddrPath(locationview.data.addrPath);

    setCustomer(findcustomer);
    setCustomerTels(findcustomer.customerTels);
  }

  useEffect(() => {
    if (customer.id !== undefined) {
      console.log(' use customer ', customer)
      
      form.setFieldsValue({
        preNameId: customer.prenameId,
        custName: customer.custName,
        address: customer.address,
        addrPath: locationAddrPath,
        zipCode: customer.zipCode,
        useFlg: customer.useFlg,
      });
    }
  }, [customer]);

  const getAllPrenames = async () => {
    const res = await prenameServices.getAll();
    setPrenameOptions(res.data);
  }

  //Prename select
  const [prenameOptions, setPrenameOptions] = useState<Array<{ value: string, objectvalue: PreName }>>([]);
  const onSearch = async (searchText: string) => {
    console.log(" searchText " + searchText);
    const res = await prenameServices.getByParam(searchText, 0);
    setPrenameOptions(res.data);
  };
  const onSelect = async (selectedId: any) => {
    const resprename = await prenameServices.getById(Number(selectedId));
    setPrenameId(selectedId);
    setPrename(resprename.data.prename);
  };

  //Location select
  const [locationOptions, setLocationOptions] = useState<Array<LocationView>>([]);
  const [locationFilterOptions, setLocationFilterOptions] = useState<Array<LocationView>>([]);

  const onSearchLocation = async (searchText: string) => {
    let search = locationOptions.filter((item) => item.addrPath.includes(searchText));
    setLocationFilterOptions(search);
  };

  const onSelectLocation = async (locationCode: any) => {
    const res = await locationServices.getByTambonCode(locationCode);
    setLocationCode(locationCode);
    setZipCode(res.data.zipcode);
  };

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = async (values: any) => {
    console.log(id)
    console.log(' values ', values);

    //let datas = { ...values.tels }
    let datas = [];
    datas = values.tels;
    console.log('===tels====' ,datas);
    let xArray = [];
    //if (datas && datas.length > 0) {
      
      for (const element of datas) {
        let telno = element;
        console.log('===telno====' ,telno);
        if (telno !== "") {
          let custTel: CustomerTel = {
            id: 0,
            custId: id,
            custTel: element,
            useFlg: form.getFieldValue('useFlg') !== undefined ? form.getFieldValue('useFlg') : 0,
            savStation: sessionUser.stationCode,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username
          };
          console.log(custTel);
          xArray.push(custTel);
          setCustomerTels(xArray);
          console.log(" x array === ", xArray);
        }
      //}
      console.log("custtels : ", customerTels);
    }

    let addrPath = "";
    if (values.addrPath != null) {
      addrPath = locationCode.substring(0, 2) + "/" + locationCode.substring(0, 4) + "/" + locationCode;
      console.log("addr path", addrPath);
    }

    const cust: Customer = {
      id: id,
      prenameId: prenameId,
      custName: values.custName,
      address: values.address,
      addrPath: addrPath,
      locationCode: locationCode,
      zipCode: zipcode,
      useFlg: values.useFlg === undefined ? 0 : values.useFlg,
      savStation: sessionUser.stationCode,
      customerTels: xArray,
      createUserid: sessionUser.userId,
      createUsername: sessionUser.username
    };

    if (id === 0) {
      try {

        customerServices.addCustomer(cust).then(res => {
          history.replace("/app/customer");
          message.success("Save Complete", 1.5);
        }).catch(err => {
          console.log(err);
        });
      } catch (err) {
        showMessage('' + err);
      }
    }

    if (id !== 0) {
      customerServices.updateCustomer(cust).then(res => {
        console.log("cust ", res.data);
        history.replace("/app/customer");
        message.success("Update Complete", 1.5);
      }).catch(err => {
        console.log(err)
      });

    }
    setTrigger(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setTrigger(false);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >

    <Row gutter={[20, 10]}>
					<Col span={12}>
						<Section>
            <Form.Item label="คำนำหน้า" name="preNameId"
                rules={[
                  { required: true, message: "Required : คำนำหน้า" },
                ]}
              >
                <Select
                  showSearch
                  size="middle"
                  placeholder="ระบุคำนำหน้า"
                  optionFilterProp="children"
                  filterOption={true}
                  onSearch={onSearch}
                  onSelect={onSelect}
                >
                  {prenameOptions.map((obj: any) => {
                    return (
                      <Option key={obj.id} value={obj.id}>
                        {obj.prename}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="custName" label="ชื่อลูกค้า"  rules={[
                  { required: true, message: "กรุณาระบุชื่อลูกค้า" },
                ]}>
                <Input onKeyDown={handleEnter}/>
              </Form.Item>
              <Form.Item label="เลขที่" name="address"
                rules={[
                  { required: true, message: "กรุณาระบุเลขที่บ้าน" },
                ]}
              >
                <Input placeholder="บ้าน ซอย ถนน" onKeyDown={handleEnter} />
              </Form.Item>
              <Form.Item label="ที่อยู่" name="addrPath"
                rules={[
                  { required: true, message: "กรุณาค้นหา ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์" },
                ]}
              >
                <Select
                  showSearch
                  size="middle"
                  placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
                  optionFilterProp="children"
                  filterOption={true}
                  onSearch={onSearchLocation}
                  onSelect={onSelectLocation}
                  value={locationAddrPath}
                >
                  {locationOptions.map((obj: any) => {
                    return (
                      <Option key={obj.id} value={obj.tambonCode}>
                        {obj.addrPath}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="useFlg" label="สถานะ" initialValue={0}>
                <Radio.Group>
                  <Radio value={0}>ใช้งาน</Radio>
                  <Radio value={-1}>ไม่ใช้งาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Section>
          </Col>
          <Col span={12}>
            <Section>
              <Table 
                  // pagination={{
                  //     pageSize: 20,
                  //     onChange(current) {
                  //         setPage(current);
                  //     },
                  //     showSizeChanger:false
                  // }}
                  rowKey={(item:CustomerTel) => item.id}
                  columns={columns}
                  dataSource={customerTels} 
                  size="small" 
              />
            </Section>

						<Section style={{ marginTop: 30}}>
              <Form.List name="tels">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        //{...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        //label={index === 0 ? "เบอร์โทร" : ""}
                        label={"เบอร์โทร"}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            placeholder=""
                            style={{ width: "60%" }}
                            maxLength={10}
                            onKeyDown={handleEnter}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ marginLeft: 10, }}
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ marginLeft: 10, }}
                        icon={<PlusCircleOutlined />}

                      >
                        เพิ่มเบอร์โทร
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Section>
          </Col>
      </Row>
    </Form>
    </>
  )
}

export default FormCustomer;