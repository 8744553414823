import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort+'bank';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByParam = (name : any , useFlg : number) => {
    const param = `name=${name}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
    
  };

const addBank = (Bank: any) => {
    return http.post(url + "/add", Bank);
};

const updateBank = (Bank: any) => {
    return http.put(url + "/update", Bank);
};

export default {
    getAll,
    getById,
    getByParam,
    addBank,
    updateBank,
}