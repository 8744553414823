import { StationAreaTemp } from 'src/domain/stationAreaTempType';
import { StationArea } from 'src/domain/stationAreaType';

import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort+'stationarea';

const getAll = () => {
   return http.get(url);
};

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByStationCode = (stationCode : string) => {
    return http.get(`${url}/getByStationCode/${stationCode}`);
}

const getByStationNameCount = (stationName : string) => {
    const param = `name=${stationName}`;
    return http.get(url + "/getByStationNameCount/?" + param);
}

const getByParam = (stationcode : any, locationcode : any, linecode: any, useFlg: any) => {
    const param = `stationcode=${stationcode}&locationcode=${locationcode}&linecode=${linecode}&useFlg=${useFlg}`;
    return http.get(url + "/byparam/?" + param);
    
  };

const addStationArea = (StationArea: any) => {
    return http.post(url + "/add", StationArea);
};

const updateStationArea = (StationArea : any) => {
    return http.put(url + "/update", StationArea);
};

const getByLineCode = (lineCode : any) => {
    return http.get(url + "/getByLineCode/" + lineCode);
};

const getByStationCodeAndFilepriceId = (stationcode : any, useFlg: any, filepriceId: any) => {
    const param = `stationcode=${stationcode}&useFlg=${useFlg}&filepriceId=${filepriceId}`;
    return http.get(url + "/byStationCodeAndFilepriceId/?" + param);
}

//ไม่ใช่แล้ว
const addStationAreaProcess = (stationCode : string, locationList : any) => {
    console.log(" locationList in service === ", locationList)
    const locationArray = locationList as Array<StationAreaTemp>;
    if (locationArray.length > 0) {
        for (let i = 0; i < locationArray.length; i++) {
            let tempLocate = locationArray[i];
            console.log(" temp location === ", tempLocate)
            console.log(" location code === ", tempLocate.locationCode[2])
            console.log(" service home === ", tempLocate.serviceHome)
            console.log(" service station  === ", tempLocate.serviceStation)
            console.log(" use flg === ", tempLocate.useFlg);

            const stationArea_: StationArea = {
                id: 0,
                stationCode : stationCode,
                locationCode : tempLocate.locationCode[2],
                stationName : "",
                locationFullName : "",
                serviceHome : tempLocate.serviceHome === true ? 1 : 0,
                serviceStation : tempLocate.serviceStation === true ? 1 : 0,
                useFlg : tempLocate.useFlg,
                locationCount : 0,
                lineCode : "",
                lineName : "",
                savStation : "",
                createUserid : 0,
                createUsername : "",
                remark : "",

            };

            const saveEntity = addStationArea(stationArea_);
        }

    }
}

export default {
    getAll,
    getById,
    getByParam,
    addStationArea,
    updateStationArea,
    addStationAreaProcess,
    getByStationCode,
    getByStationNameCount,
    getByLineCode,
    getByStationCodeAndFilepriceId
}