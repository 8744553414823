import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, {useState } from 'react'
import PageHeader from "src/layouts/PageHeader";
import FormNimStation from"./FormNimStation";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import { useHistory } from 'react-router';


const AddNimStation = () => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();

    return (
        <>
        <PageHeader title="เพิ่มข้อมูล สาขา" subTitle='กำหนดข้อมูล สาขา'>
					<Space>
						<Button type="primary" icon={<SaveOutlined />} onClick={() => setTrigger(true)}>
							บันทึก
						</Button>
						<Button icon={<CloseCircleOutlined />} onClick={() => history.goBack()}>
              กลับ
						</Button>
					</Space>
				</PageHeader>
        <Content>
          <Row  >
            <Col span={24}>
                <FormNimStation id={0} trigger={trigger} setTrigger={setTrigger} />
            </Col>
          </Row>

        </Content>
      </>
    )
}

export default AddNimStation;
