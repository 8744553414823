import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, {useState } from 'react'
import PageHeader from "src/layouts/PageHeader";
import { CloseCircleOutlined,SaveOutlined  } from '@ant-design/icons';
import { useHistory } from 'react-router';
import FormFranchisee from './FormFranchisee'
import Section from '../../components/container/Section';


const AddFranchisee = () => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();

    return (
			<>
				<PageHeader title="เพิ่มข้อมูล ผู้ซื้อแฟรนไชส์">
					<Space>
						<Button type="primary" icon={<SaveOutlined />} onClick={() => setTrigger(true)}>
							บันทึก
						</Button>
						<Button icon={<CloseCircleOutlined />} onClick={() => history.goBack()}>
							กลับ
						</Button>
					</Space>
				</PageHeader>

				<FormFranchisee id={0} trigger={trigger} setTrigger={setTrigger} />
			</>
		);
}

export default AddFranchisee;
