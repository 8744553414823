import { Col, Row ,Form ,Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Section from 'src/components/container/Section';
import { TruckRequest } from 'src/domain/fleetRequestTruckType';
import fleetRequestTruckService from 'src/services/fleetRequestTruckService';


interface IProps {
    truckUId: string;
    trigger: boolean;
    setTrigger: any;
  }

  const { Text } = Typography;

const FleetTruckInfo :React.FC<IProps>=(props) => {

    const { truckUId, trigger, setTrigger } = props;
    const [form] = Form.useForm();
    const [truck,setTruck] = useState<TruckRequest>();

    useEffect(() => {

      console.log("truckUId >>",truckUId)
  
      fleetRequestTruckService.getTruckDataFromFleet(truckUId)
      .then((res) => {
        setTruck(res.data)
          console.log(res.data)
      })
   }, []);


  return (
    <>
      <Form
       form = {form}
       name="basic"
       initialValues={{ remember: true }}
       layout="vertical"
      >
          <Row gutter={[20,10]}>
          <Col span={24}>
            <Section>
              <Form.Item
                label="เจ้าของรถ"
                name="partnerName"
              >
                 <Text style={{fontSize:"large"}}>{truck?.partnerName}</Text>
              </Form.Item>
              <Form.Item
                label="ชนิดรถ"
                name="truckTypeName"
              >
                 <Text style={{fontSize:"large"}}>{truck?.truckTypeName}</Text>
              </Form.Item>
              <Form.Item
                label="ยี่ห้อรถ"
                name="brand"
              >
               <Text style={{fontSize:"large"}}>{truck?.brand}</Text>
              </Form.Item>
              <Form.Item
                label="ประเภทการวิ่ง"
                name="truckRunningType"
              >
               <Text style={{fontSize:"large"}}>{truck?.truckRunningTypeStr}</Text>
              </Form.Item>
            </Section>
          </Col>
          </Row>

      </Form>
    </>
  )
}

export default FleetTruckInfo