import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + 'zone';

const getByAll = () => {
    return http.get(url + "/byAll");
};

const getById = (id: any) => {
    const param = `id=${id}`;
    return http.get(url + "/byId/?" + param);
};

const getByCode = (code: any) => {
    const param = `code=${code}`;
    return http.get(url + "/byCode/?" + param);
};

export default { getByAll, getById, getByCode }