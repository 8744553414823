import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader';
import FormBookingTruck from './FormBookingTruck';

interface IParams {
    id: string;
}
const ViewBookingTruck: React.FC<RouteComponentProps<IParams>> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const history = useHistory();
    const id = Number(props.match.params.id);

    return (
        <>
            <PageHeader title="ข้อมูลตั้งรถส่วนกลาง">
                <Space>
                    <Button
                        icon={<CloseCircleOutlined />}
                        onClick={() => history.goBack()}
                    >
                        กลับ
                    </Button>
                </Space>
            </PageHeader>

            <FormBookingTruck id={id} trigger={trigger} setTrigger={setTrigger} />
        </>
    )
}

export default ViewBookingTruck
