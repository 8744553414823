import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import Section from 'src/components/container/Section';
import { FleetStation } from 'src/domain/fleetStation';
import fleetStationServices from 'src/services/fleetStationServices';
import { PlusOutlined  } from '@ant-design/icons';
import StationCheckModal from '../driver/modal/StationCheckModal';
import FleetStationSelectModal from './modal/FleetStationSelectModal';

interface IProps {
  truckUId: string;
  trigger: boolean;
  setTrigger: any;
}



const FleetTruckStationInfo : React.FC<IProps> = (props) => {

  const { truckUId, trigger, setTrigger } = props;
  const [page, setPage] = React.useState(1);
  const [fleetStations , setFleetStations] = useState<FleetStation[]>([]);
  const [isStationModalOpen, setIsStationModalOpen] = useState<boolean>(false);

  
  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      render: (value: number, item: FleetStation, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "สาขา",
      dataIndex: "stationCode",
      key: "stationCode",
    },
    {
      title: "ชื่อสาขา",
      dataIndex: "stationName",
      key: "stationName",
    },
    {
      title: "สถานะ",
      dataIndex: "useFlg",
      key: "useFlg",
      render: (value: string, item: FleetStation, index: number) => 
      <>
          {item.useFlg === 0? "ใช้งาน": "ยกเลิกใช้งาน"}
      </>
    },
  ];


  useEffect(() => {

    // console.log("driverUId >>",driverUId)
    fleetStationServices.getbytruckCode(truckUId)
    .then((res) => {
        setFleetStations(res.data)
        console.log(res.data)
    })
 }, []);

 useEffect(() => {

  // console.log("driverUId >>",truckUId)
  fleetStationServices.getbytruckCode(truckUId)
  .then((res) => {
      setFleetStations(res.data)
      // console.log(res.data)
  })
}, [isStationModalOpen]);
  
 

const showModal = () => {
  // console.log(' driverUId ++++', driverUId);
  setIsStationModalOpen(true);
};



  return (
    <>
     <Section>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
      <Button 
          size="middle"
          type="primary"
          icon={<PlusOutlined  />}
           onClick={() => showModal()}
        >
          เพิ่ม
        </Button>
        </div>
        <Table 
          style={{ marginTop: 20 }}
          pagination={{
            pageSize: 10,
            onChange(current) {
              setPage(current);
            },
            showSizeChanger: false
          }}
          rowKey={(item: FleetStation) => item.id}
          columns={columns}
          dataSource={fleetStations}
          size="small" />

      </Section>
      <FleetStationSelectModal
          isModalOpen={isStationModalOpen}
          onOk={null}
          onCancel={() => { setIsStationModalOpen(false) }}
          // onRowDoubleClick={(data: Station, index: number, evt: any) => {
          //   console.log("onRowDoubleClick: ", data, index, evt);
          //   onDoubleClickStation(data);
          // }}
          truckUId= {truckUId}
        />
    </>
  )
}

export default FleetTruckStationInfo