import type { AppThunk } from "../index";
import { SessionUser } from "./types";
import {
  setSessionUser as setSessionUserAction,
  getSessionUserFromLocal as getSessionUserFromLocalAction,
  removeSessionUser as removeSessionUserAction,
} from "./slice";

export const getSessionUserFromLocal =
  (): AppThunk<void> =>
  async (dispatch): Promise<void> => {
    let sessionUserStr = localStorage.getItem("sessionUser");
    if(sessionUserStr) {
      let sessionUser : SessionUser = JSON.parse(sessionUserStr) as SessionUser;

      dispatch(getSessionUserFromLocalAction(sessionUser));
    }
    console.log("sessionUserStr ", sessionUserStr);

    // const email = localStorage.getItem("sessionUser.email");

    // console.log("username, email", username, email);
    // const sessionUser: SessionUser = {
    //   username: username as string,
    //   email: email as string,
    // };

  };

export const setSessionUser =
  (sessionUser: SessionUser): AppThunk<void> =>
  async (dispatch): Promise<void> => {
    // console.log("sessionUser json", sessionUser);
    // sessionUser.roles = ["BRANCH_USER"];
    console.log("session User", JSON.stringify(sessionUser));
    localStorage.setItem("sessionUser", JSON.stringify(sessionUser));

    console.log("action::setSessionUser", sessionUser);
    dispatch(setSessionUserAction(sessionUser));
  };

export const removeSessionUser =
  (sessionUser: SessionUser): AppThunk<void> =>
  async (dispatch): Promise<void> => {
    localStorage.removeItem("sessionUser");
    // localStorage.removeItem("sessionUser.email");

    console.log("action::removeSessionUser");
    dispatch(removeSessionUserAction(sessionUser));
  };