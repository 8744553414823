import { Button, Space, Form, Row, Col, Input ,Select,Table } from 'antd'
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import Section from 'src/components/container/Section';
import { FleetDrv } from 'src/domain/fleetDrvType';
import { Link } from 'react-router-dom';
import fleetDrvService from 'src/services/fleetDrvService';


const statusOptions = [
    {
      value: '1',
      label: 'ทั้งหมด',
    },
    {
      value: '0',
      label: 'ใช้งาน',
    },
    {
      value: '-1',
      label: 'ยกเลิกใช้งาน',
    },
  ];

const FleetDriverList = () => {
    const history = useHistory();
    const [searchName, setSearchName] = useState<any>("");
    // const [stations,setStations] = useState<Station[]>([]);
    const [status,setStatus] = useState(String);
    const [list, setList] = useState<FleetDrv[]>([]);
    const [page, setPage] = React.useState(1);

     const columns:any[] = [
        {
            title: "ลำดับ",
            key: "index",
             render: (value: number, item: FleetDrv, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัสพนักงาน",
            dataIndex: "driverCode",
            key: "driverCode",
        },
        {
            title: "ชื่อพนักงานขับรถ",
            dataIndex: "driverName",
            key: "driverName",
            // sorter: (a, b) => a.age - b.age,
            sorter: (a:any, b:any) => a.driverName.localeCompare(b.driverName),
            defaultSortOrder: 'ascend',
            render: (value: string, item: FleetDrv, index: number) => <Link to={`/app/fleetstation/driver/edit/${item.driverCode}`}>{value}</Link>,
        },
       
        {
            title: "สถานะ",
            dataIndex: "useFlg",
            key: "useFlg",
            render: (value: string, item: FleetDrv, index: number) => 
            <>
                {item.useFlg === 0? "ใช้งาน": "ยกเลิกใช้งาน"}
            </>
            ,
        },
    ];

   
    useEffect(() => {
        // loadStation();
        onSearch();
    }, []);

    // const loadStation = async () => {
    //     await stationServices.getAll().then((res) => {
    //         setStations(res.data);
    //     });
    // }

    const onSearch = async () => {
        let params = {
            // stationCode : stationCode,
            useFlg : status,
            searchData : searchName
        }
        let resData: FleetDrv[] = await fleetDrvService.getFleetDrvByParam(params);
        setList(resData);
    }


    const onChangeStatus = (selectStatus : any) => {
        setStatus(selectStatus);
     };
 
    return (
        <>
            <PageHeader title="ข้อมูลพนักงานขับรถ">
                <Space>
                    <Button
                        size="large"
                        style={{ width: 120 }}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/fleetstation/driver/add") }}
                    >
                        เพิ่ม
                    </Button>
                    {/* <Button
                        size="large"
                        style={{ width: 120 }}
                        type="default"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/fleetstation/driver/edit/DV-44a769557dd8") }}
                    >
                        from
                    </Button> */}
                </Space>
            </PageHeader>
            <div>
                <Form 
                  layout="vertical"
                >
                    <Section style={{ display: "flex", flexDirection: "column"  }}>
                        <Row gutter={[20, 10]}>
                            <Col span={8}>
                                <Form.Item label="ชื่อพนักงานขับรถ">
                                    <Input name="drvName" onChange={(e) => setSearchName(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item label="สถานะ">
                                    <Select
                                        defaultValue={"1"}
                                        onChange={onChangeStatus}
                                        options={statusOptions}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={4}   style={{ display: 'flex', justifyContent: 'flex-end' ,alignItems:'center', marginTop: 10}}>
                                <Button
                                    icon={<SearchOutlined />}
                                     onClick={() => onSearch()}
                                    style={{ width: 100}}
                                >
                                    ค้นหา
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                           
                        </Row>
                    </Section>
                </Form>
            </div>
            <Section style={{ marginTop: 30}}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                          setPage(current);
                        },
                        showSizeChanger:false
                    }}
                    rowKey={(item: FleetDrv) => item.id}
                    columns={columns}
                    dataSource={list}
                    size="small" />

            </Section>

        </>
    )
}

export default FleetDriverList