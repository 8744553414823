import { Col, Form, Input, Radio, Row, Select, Typography,Layout, message } from "antd";
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import prenameServices from "src/services/prenameServices";
import { SessionUser } from "src/store/authen/types";
import { showMessage, handleEnter } from "src/utils";
import { PreName } from "../../domain/preNameType";
import Section from "../../components/container/Section";

interface IProps {
    id: number;
    trigger: boolean;
    setTrigger: any;
  }

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

const FormPrename:React.FC<IProps> = (props) => {
    const { id, trigger, setTrigger } = props;
    console.log(id)
    const [prename, setPreName] = useState<PreName>({} as PreName);
    const history = useHistory();
    const [form] = Form.useForm();
    const [action, setAction] = useState(false);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
  
    useEffect(() => {
    prenameServices.getById(Number(id))
      .then((res) => {
        setPreName(res.data)
      })

    }, []);
  
    useEffect(() => {
      if (trigger) {
        form.submit();
      }
    }, [trigger]);
  
  
    useEffect(() => {
      if(id === 0){
  
      }else {
        setAction(true);
        form.setFieldsValue({
          id : prename.id,
          prenameCode : prename.prenameCode,
          prenamefield : prename.prename,
          useFlg : prename.useFlg
        });
      }
      
    }, [prename]);
  
    const onFinish = async (values: any) => {
      console.log(id);
      console.log("", values);

      const prename_: PreName = {
        id: id,
        prenameCode : values.prenameCode,
        prename : values.prenamefield,
        useFlg : values.useFlg,
        savStation : sessionUser.stationCode,
        createUserid: sessionUser.userId,
        createUsername : sessionUser.username
      };

      if (id === 0) {
        try {
          prenameServices.addPreName(prename_).then(res => {
            history.replace("/app/prename");
            message.success("Save Complete", 1.5);
          }).catch(err => {
            console.log(err);
           });
        } catch (err) {
          showMessage(''+err);
        }
      }
  
      if (id !== 0) {
          prenameServices.updatePreName(prename_).then(res => {
          history.replace("/app/prename");
          message.success("Update Complete", 1.5);
        }).catch(err => {
          console.log(err)
        });
       
      }
      setTrigger(false);
    };
  
    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
      setTrigger(false);
    };

  return (
    <>
      <Layout.Content className="app-page-content">
        <Form

          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >

      <Row gutter={[20, 10]}>
						<Col span={16}>
            <Section>
                <Form.Item name="prenameCode" label="รหัส"
                  rules={[
                    { required: true, message: "กรุณาระบุรหัส" },
                  ]}
                >
                  <Input  onKeyDown={handleEnter} maxLength={5} disabled={action}/>
                </Form.Item>
                <Form.Item  label="คำนำหน้า" name="prenamefield"
                  rules={[
                    { required: true, message: "กรุณาระบุชื่อคำนำหน้า" },
                  ]}
                >
                  <Input onKeyDown={handleEnter} maxLength={150} />
                </Form.Item>
                </Section>
            </Col>
            <Col span={8}>
              <Section>
                <Form.Item name="useFlg" label="สถานะ">
                  <Radio.Group defaultValue={0} >
                    <Radio value={0} >ใช้งาน</Radio>
                    <Radio value={-1}>ไม่ใช้งาน</Radio>
                  </Radio.Group>
                </Form.Item>
              </Section>
            </Col>
          </Row>

        </Form>
      </Layout.Content>
    </>
  )
}

export default FormPrename;