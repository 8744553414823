import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Table } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import filepriceServices from 'src/services/filepriceServices';
import { showDate } from 'src/utils/dateUtils';
import Section from '../../components/container/Section';
import { Station } from '../../domain/stationType';
import FilepriceDrawer from './drawer/FilepriceDrawer';

const FilepriceList = () => {
    const [fileprices, setFileprices] = useState([])
    const [searchName, setSearchName] = useState<any>("");
    const [page, setPage] = React.useState(1);

    const [isShowDrawer, setIsShowDrawer] = useState(false);

    const columnSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            align: "center" as AlignType, width: 60,
            render: (value: number, item: Station, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "เลขที่อ้างอิง",
            dataIndex: "id",
            key: "id",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.id, b.id),
        },
        {
            title: "รหัสแฟ้ม",
            dataIndex: "fileCode",
            key: "fileCode",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.fileCode, b.fileCode),
            render: (value: string, item: Station, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ชื่อแฟ้ม",
            dataIndex: "fileName",
            key: "fileName",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.fileName, b.fileName),
            render: (value: string, item: Station, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Link to={`/app/fileprice/view/${item.id}`}>{value}</Link>
                </Col>
            )
        },
        {
            title: "วันที่เริ่ม",
            dataIndex: "startDate",
            key: "startDate",
            align: "center" as AlignType,
            render: (value: any, item: any, index: number) => {
                return <span>{value && value !== 'null' ? showDate(value) : '-'}</span>;
            },
        },
        {
            title: "วันที่สิ้นสุด",
            dataIndex: "endDate",
            key: "endDate",
            align: "center" as AlignType,
            render: (value: any, item: any, index: number) => {
                return <span>{value && value !== 'null' ? showDate(value) : '-'}</span>;
            },
        },
    ];

    useEffect(() => {

    }, []);

    const changeSearchText = (searchText: any) => {
        setSearchName(searchText);
    }

    const search = () => {
        filepriceServices.getByFilter("", "", "", searchName)
            .then((res) => {
                setFileprices(res.data)
            })
    }

    const onOpenDrawer = () => {
        setIsShowDrawer(true);
    }

    const onCloseDrawerWithRefresh = (val: boolean) => {
        setIsShowDrawer(false);
        if (val === true) {
            search();
        }
    }

    return (
        <div>
            <PageHeader title="กำหนดแฟ้มราคา" subTitle="เพิ่ม แก้ไข เปลี่ยนแปลงข้อมูลแฟ้มราคา">
                <Button
                    size="large"
                    style={{ width: 120 }}
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={onOpenDrawer}
                >
                    เพิ่ม
                </Button>
            </PageHeader>

            <Section>
                <Form layout="vertical">
                    <Row style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Col span={12}>
                            <Form.Item label="ชื่อแฟ้ม">
                                <Input
                                    style={{
                                        borderRadius: "5px",
                                        width: "300px",
                                    }}
                                    name="searchName"
                                    onChange={(e) => changeSearchText(e.target.value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                icon={<SearchOutlined />}
                                onClick={() => search()}
                                style={{ marginTop: 30 }}>
                                ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Section>

            <Section style={{ marginTop: 30 }}>
                <Table
                    columns={columns}
                    dataSource={fileprices}
                    pagination={{
                        pageSize: 20,
                        onChange(current) {
                            setPage(current);
                        },
                    }}
                    rowKey={(item: Station) => item.id}
                    size="small"
                />
            </Section>

            <Drawer
                title="แฟ้ม"
                width="400px"
                onClose={() => onCloseDrawerWithRefresh(false)}
                open={isShowDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <FilepriceDrawer fileprice={undefined}
                    saveComplete={() => onCloseDrawerWithRefresh(true)}
                    onCloseDrawer={() => onCloseDrawerWithRefresh(false)}
                />
            </Drawer>
        </div >
    );
}

export default FilepriceList;
