import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputNumber, message, Row, Space, Table, Tabs, Typography } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StationArea } from 'src/domain/stationAreaType';
import { Station } from 'src/domain/stationType';
import filepriceAreaMarkupServices from 'src/services/filepriceAreaMarkupServices';
import stationAreaServices from 'src/services/stationAreaServices';
import stationServices from 'src/services/stationServices';
import { SessionUser } from 'src/store/authen/types';
import { showMessage } from 'src/utils';

interface IProps {
    filepriceId: any;
    isEdit: boolean;
    setIsEditing: any;
}

const FilepriceAreaView: React.FC<IProps> = (props) => {
    const { filepriceId, isEdit, setIsEditing } = props;
    const [stations, setStations] = useState<Station[]>([]);
    const [filterStation, setFilterStation] = useState<Station[]>([]);
    const [lines, setLines] = useState<StationArea[]>([]);
    const [temLines, setTemLines] = useState<StationArea[]>([]);
    const [filterLine, setFilterLine] = useState<StationArea[]>([]);
    const [selectStation, setSelectStation] = useState('');
    const [isLoad, setLoad] = useState(false);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const columnSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    const onEdit = (value: any, index: any) => {
        setTemLines((prevData: any) => {
            const newData = [...prevData];
            const newItem = { ...newData[index] };
            newItem.markupPercent = value;
            newData[index] = newItem;
            return newData;
        });
    };

    const lineColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "รหัสสาย", dataIndex: "lineCode", key: "lineCode",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.fileCode, b.fileCode),
            render: (value: string, item: StationArea, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ชื่อสาย", dataIndex: "lineName", key: "lineName",
            align: "center" as AlignType,
            render: (value: string, item: StationArea, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "เปอร์เซ็นต์", dataIndex: "markupPercent", key: "markupPercent",
            align: "center" as AlignType, editable: true,
            render: (value: number, item: StationArea, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'center' }}>
                    {isEdit ?
                        <InputNumber size={'middle'} style={{ flex: 1 }}
                            defaultValue={value}
                            onChange={(v) => {
                                onEdit(v, index)
                            }}
                            onFocus={(event) => event.target.select()}
                        />
                        :
                        <span>{value}</span>
                    }
                </Col>
            )
        },
    ];

    useEffect(() => {
        if (!isLoad) {
            handleSearchStation('');
            setLoad(true);
        }
    }, []);

    useEffect(() => {
        if (isEdit) {
            setTemLines(lines);
        } else {
            setTemLines([])
            handleSearchStationArea(selectStation);
        }
    }, [isEdit]);

    useEffect(() => {
        if (stations.length > 0) {
            setSelectStation(stations[0].stationCode);
            handleSearchStationArea(stations[0].stationCode);
        }
    }, [stations]);

    async function handleSearchStation(val: string) {
        const res = await stationServices.getByParam('', val, '', '', 0)
        if (res.status === 200) {
            const sort = await res.data.sort(function (a: any, b: any) {
                let x = a.stationName.toLowerCase();
                let y = b.stationName.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            setStations(await sort)
            setFilterStation(await sort)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function handleSearchStationArea(val: string) {
        setLines([])
        setFilterLine([])
        const res = await stationAreaServices.getByStationCodeAndFilepriceId(val, 0, filepriceId)
        if (res.status === 200) {
            const sort = await res.data.sort(function (a: any, b: any) {
                let x = a.lineCode.toLowerCase();
                let y = b.lineCode.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            setLines(await sort);
            setFilterLine(await sort);
            setSelectStation(val);
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function handleFilterStation(val: string) {
        const station = stations.filter((item) => item.stationName.includes(val))
        setFilterStation(station);
    }

    async function handleFilterLine(val: string) {
        const line = lines.filter((item) => item.lineName.includes(val))
        setFilterLine(line);
    }

    const handleSaveEdit = async () => {
        // if (!isValid()) {
        //     return;
        // }
        // console.log('temLines', lines)
        let _save: any[] = [];
        temLines.forEach((item: any) => {
            _save.push({
                stationCode: item.stationCode,
                stationAreaCode: item.lineCode,
                markupPercent: item.markupPercent,
                filepriceId: filepriceId,
                createUserid: sessionUser.userId,
                createUsername: sessionUser.username,
                savStation: sessionUser.stationCode,
            })
        })
        // setLines(temLines)
        try {
            filepriceAreaMarkupServices.updateFilepriceAreaMarkup(_save).then((res: any) => {
                setIsEditing(false);
                message.success("บันทึกข้อมูลแล้ว", 1.5);
            }).catch((err: any) => {
                message.warning('Service : ' + err)
            });
        } catch (err) {
            message.warning('บันทึกข้อมูลไม่สำเร็จ')
        }
    }


    // const isValid = () => {
    //     if (!code) {
    //         message.warning('กรุณาระบุรหัสแฟ้ม');
    //         return false;
    //     }
    //     if (!name) {
    //         message.warning('กรุณาระบุชื่อแฟ้ม');
    //         return false;
    //     }
    //     if (!sDate) {
    //         message.warning('กรุณาระบุวันที่เริ่มใช้');
    //         return false;
    //     }
    //     return true;
    // }

    return (
        <>
            <Row>
                <Col span={6}>
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                            <Typography.Text>สาขา :</Typography.Text>
                        </Col>
                        <Col span={16} push={0}>
                            <Input onChange={(e) => handleFilterStation(e.target.value)} />
                        </Col>
                    </Row>
                </Col>
                <Col span={18}>
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col span={2} style={{ textAlign: "right", paddingRight: "5px", }} >
                            <Typography.Text>สาย :</Typography.Text>
                        </Col>
                        <Col span={6} push={0}>
                            <Input onChange={(e) => handleFilterLine(e.target.value)} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{ paddingTop: 10, }}>
                    <Tabs tabPosition={'left'} size={'small'}
                        defaultActiveKey={selectStation}
                        tabBarStyle={{ width: '25%', paddingLeft: 30 }}
                        onTabClick={(key: any) => {
                            handleSearchStationArea(key);
                            if (isEdit) {
                                setIsEditing(false);
                            }
                        }}
                        items={filterStation.map((item: any, i) => {
                            return {
                                label: item.stationName,
                                key: item.stationCode,
                                children: (
                                    <Col span={24}>
                                        <Row>
                                            <Col span={24} style={{ paddingTop: 10 }}>
                                                <Table
                                                    size='middle'
                                                    dataSource={filterLine}
                                                    columns={lineColumns}
                                                    rowKey={(item: StationArea) => item.lineCode}
                                                    pagination={{ pageSize: 10, defaultCurrent: 1 }}
                                                />
                                            </Col>
                                        </Row>
                                        {isEdit && temLines.length > 0 ?
                                            <Row>
                                                <Col span={24} style={{ paddingTop: 10 }}>
                                                    <Space size="large" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            type="primary"
                                                            icon={<SaveOutlined />}
                                                            onClick={handleSaveEdit}
                                                            style={{ width: 120 }}
                                                        >
                                                            บันทึก
                                                        </Button>
                                                        <Button
                                                            icon={<CloseCircleOutlined />}
                                                            onClick={() => setIsEditing(false)}
                                                            style={{ width: 120 }}
                                                        >
                                                            ยกเลิก
                                                        </Button>
                                                    </Space >
                                                </Col>
                                            </Row>
                                            :
                                            <></>
                                        }
                                    </Col>
                                )
                            };
                        })}
                    />
                </Col>
            </Row>
        </>
    )
}

export default FilepriceAreaView
