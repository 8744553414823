import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Bank } from '../../domain/bankType';
import { Link } from 'react-router-dom';
import bankServices from 'src/services/bankServices';
import Section from "../../components/container/Section";

const List = () => {
    const history = useHistory();
    const { Content } = Layout;
    const [banks, setBanks] = useState([])
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [page, setPage] = React.useState(1);


    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            align: "center" as "center",
            render: (value: number, item: Bank, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "bankCode",
            key: "ิbankCode",
            align: "center" as "center",
        },
        {
            title: "ธนาคาร",
            dataIndex: "bankName",
            key: "bankName",
            align: "center" as "center",
            render: (value: string, item: Bank, index: number) => <Link to={`/app/bank/edit/${item.id}`}>{value}</Link>,
        },
    
    ];

    useEffect(() => {
        bankServices.getByParam("", 0)
            .then((res) => {
                console.log(res.data)
                setBanks(res.data)
            })
    }, []);

    const search = () => {
        bankServices.getByParam(searchName, status)
            .then((res) => {
                setBanks(res.data)
            })
    }

    return (
        <div>
            <PageHeader title="กำหนดธนาคาร" subTitle="เพิ่ม แก้ไข ข้อมูลธนาคาร">
                <Space>
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/bank/add") }}
                    >เพิ่ม
                    </Button>
                </Space>
            </PageHeader>

            <Section style={{ display: "flex", flexDirection: "column" }}>
                <Form layout="vertical">
                    <Row gutter={[20, 10]} style={{ display: "flex", alignItems: "start" }}>
                        <Col span={8}>
                            <Form.Item label="ชื่อธนาคาร" name="searchName">
                                <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/>
                            </Form.Item>
                            <Radio.Group name="status" defaultValue={0} onChange={(e) => setStatus(e.target.value)}>
                            <Space>
                                <Radio value={0} >ใช้งาน</Radio>
                                <Radio value={-1} >ไม่ใช้งาน</Radio>
                            </Space>
                        </Radio.Group>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end'}} >
                            <Button  icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30}}>
                                ค้นหา
                            </Button>
					    </Col>
                    </Row>
                </Form>
            </Section>
            <Section style={{ marginTop: 30, paddingTop: 30 }}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                            setPage(current);
                        },
                        showSizeChanger: false
                    }}
                    rowKey={(item: Bank) => item.id}
                    columns={columns}
                    dataSource={banks}
                    size="small" />
            </Section>
        </div>
    )
}

export default List;
