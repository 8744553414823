import { red } from '@ant-design/colors';
import { CloseCircleOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Col, InputNumber, message, Row, Space, Table, Typography, Upload } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import filepriceAreaMarkupServices from 'src/services/filepriceAreaMarkupServices';
import filepriceServices from 'src/services/filepriceServices';
import stationAreaServices from 'src/services/stationAreaServices';
import stationServices from 'src/services/stationServices';
import templateExcelServices from 'src/services/templateExcelServices';
import { SessionUser } from 'src/store/authen/types';
import { showMessage } from 'src/utils';
import { currDate } from 'src/utils/dateUtils';
import { read, utils } from 'xlsx';
import Section from '../../../components/container/Section';
import { Fileprice } from '../../../domain/filepriceType';
import FilepriceViewHead from '../FilepriceViewHead';

interface IParams {
    id: string;
}

const ImportFilepriceArea: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id = Number(props.match.params.id);
    const history = useHistory();
    const [fileprice, setFileprice] = useState<Fileprice>({} as Fileprice)
    const [importFile, setImportFile] = useState([] as any)
    const [startRow, setStartRow] = useState<any>();
    const [endRow, setEndRow] = useState<any>();
    const [lines, setLines] = useState<any[]>([]);
    const [errorMsg, setErrorMsg] = useState<any[]>([]);
    const [isValid, setValid] = useState(false);
    const [isLoadingStatus, setLoadingStatus] = useState(false);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    const importColumns = ['stationCode', 'lineCode', 'markupPercent'];

    const lineColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "รหัสสาขา", dataIndex: "stationCode", key: "stationCode",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ชื่อสาขา", dataIndex: "stationName", key: "stationName",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "รหัสสาย", dataIndex: "lineCode", key: "lineCode",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "ชื่อสาย", dataIndex: "lineName", key: "lineName",
            align: "center" as AlignType,
            render: (value: string, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "เปอร์เซ็นต์", dataIndex: "markupPercent", key: "markupPercent",
            align: "center" as AlignType,
        },
    ];

    async function getStationByCode(val: string) {
        const res = await stationServices.getByCode(val)
        if (res.status === 200) {
            return res.data
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }
    async function getStationAreaByCode(val: string) {
        const res = await stationAreaServices.getByLineCode(val);
        if (res.status === 200) {
            return res.data
        } else {
            message.error('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }
    async function isDuplicateAreaMarkup(stationCode: string, stationAreaCode: string) {
        const res = await filepriceAreaMarkupServices.isDuplicate(id, stationCode, stationAreaCode);
        if (res.status === 200) {
            return res.data
        } else {
            message.error('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }

    const getColumnHeader = async (name: string) => {
        const res = await templateExcelServices.getByHeadNameAndEffDate(name, currDate())
        if (res.status === 200) {
            return await res.data;
        } else {
            message.error('Error status : ' + res.status + ' ' + res.statusText);
            return null;
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== 0) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        const res = await filepriceServices.getById(id);
        setFileprice(res.data)
    }

    const importExcel = (file: any) => {
        const fileReader = new FileReader();
        fileReader.onload = async (event) => {
            try {
                const result = event.target?.result;
                const workbook = read(result, { type: "binary" });
                const sheetName = workbook.SheetNames[0];// ชีทแรก ที่ index 0
                const ws = workbook.Sheets[sheetName];
                const r = ws['!ref'] ? ws['!ref'] : null;
                let ranges = undefined;
                if (r != null && (startRow || endRow)) {
                    ranges = await calRange(utils.decode_range(r));
                }
                if (workbook.Sheets.hasOwnProperty(sheetName)) {
                    const data = utils.sheet_to_json(ws, { header: importColumns, range: ranges })
                    await generateList(data);
                }
                message.success("upload success!");
            } catch (e) {
                message.error("file type is incorrect!");
            }
            setLoadingStatus(false);
        };
        fileReader.readAsBinaryString(file);
    };

    async function calRange(sheetRange: any) {
        const sRow = startRow ? startRow : sheetRange.s.r;
        const eRow = endRow ? endRow : sheetRange.e.r;
        let headSize = 2; // colum index 0 - 2;
        const templateExcelDetails = await getColumnHeader('fileprice_area_markup');
        if (templateExcelDetails !== null && templateExcelDetails.lenght > 0) {
            headSize = templateExcelDetails.lenght - 1;
        }
        return { s: { c: 0, r: sRow }, e: { c: headSize, r: eRow } }
    }

    async function generateList(data: any) {
        setLines([]);
        setErrorMsg([]);
        if (data.length > 0) {
            let isSave = true;
            let map: any[] = [];
            let err: any[] = [];
            for (let i = 0; i < data.length; i++) {
                const chk = await isDuplicateAreaMarkup(data[i].stationCode, data[i].lineCode)
                if (chk && chk !== false) {
                    err.push(`แถว ${i + 1} รหัสสาขา ${data[i].stationCode} รหัสสาย ${data[i].lineCode} มีในฐานข้อมูลแล้ว`);
                    isSave = false;
                }
                const station = await getStationByCode(data[i].stationCode)
                if (station && station.stationName != null) {
                    data[i].stationName = station.stationName;
                } else {
                    err.push(`แถว ${i + 1} รหัสสาขา ${data[i].stationCode} ไม่พบใน Station`);
                    isSave = false;
                }
                const stationArea = await getStationAreaByCode(data[i].lineCode)
                if (stationArea && stationArea.lineName != null) {
                    data[i].lineName = stationArea.lineName;
                } else {
                    err.push(`แถว ${i + 1} รหัสสาย ${data[i].lineCode} ไม่พบใน Station Area`);
                    isSave = false;
                }
                for (let j = 0; j < data.length; j++) {
                    if (data[j].stationCode === data[i].stationCode && data[j].lineCode === data[i].lineCode && i !== j) {
                        data[i].isDuplicate = true;
                        if (i < j) {
                            err.push(`แถว ${i + 1} มีข้อมูลซ้ำกับแถว ${j + 1}`);
                            isSave = false;
                        }
                    }
                }
                console.log(data[i])
                map.push(data[i]);
            }
            setValid(isSave);
            setErrorMsg(err);
            setLines(map);
        }
    }

    const uploadProps: UploadProps = {
        onRemove: () => {
            onRemoveImport();
            return false;
        },
        beforeUpload: () => {
            return false;
        },
    };
    function onFileChange(file: any) {
        setLoadingStatus(true);
        setImportFile(file.fileList);
        importExcel(file.file);
    }
    function onRemoveImport() {
        setImportFile([] as any);
        setLines([]);
        setErrorMsg([]);
        setValid(false)
    }

    const handleSetStartRow = (val: number | null) => {
        if (val != null && val > 0) {
            setStartRow(val - 1);
        } else {
            setStartRow(undefined);
        }
    }
    const handleSetEndRow = (val: number | null) => {
        if (val != null && val > 0) {
            setEndRow(val - 1);
        } else {
            setEndRow(undefined);
        }
    }

    const handleSave = () => {
        onSaveArea();
    }

    function onSaveArea() {
        let _save: any[] = [];
        lines.forEach((item: any) => {
            _save.push({
                fileCode: fileprice.fileCode,
                stationCode: item.stationCode,
                stationAreaCode: item.lineCode,
                markupPercent: item.markupPercent,
                filepriceId: id,
                createUserid: sessionUser.userId,
                createUsername: sessionUser.username,
                savStation: sessionUser.stationCode,
            })
        })

        // console.log('_save', _save)
        try {
            filepriceAreaMarkupServices.addFilepriceAreaMarkup(_save).then((res: any) => {
                if (res.status === 200) {
                    onRemoveImport();
                    message.success("บันทึกข้อมูลแล้ว");
                }
            }).catch((err: any) => {
                message.warning('Service : ' + err)
            });
        } catch (err) {
            message.warning('บันทึกข้อมูลไม่สำเร็จ')
        }
    }

    return (
        <>
            <PageHeader title="นำเข้าข้อมูล - เปอร์เซ็นต์ AREA" >
                <Space>
                    <Button
                        size="large"
                        style={{ width: 120 }}
                        icon={<CloseCircleOutlined />}
                        onClick={() => history.goBack()}
                    >
                        กลับ
                    </Button>
                </Space>
            </PageHeader>

            <Section>
                <Row>
                    <Col span={24}>
                        <FilepriceViewHead fileprice={fileprice}></FilepriceViewHead>
                    </Col>
                </Row>
            </Section>
            <br />
            <Row gutter={24}>
                <Col span={6}>
                    <Section>
                        <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={24} style={{ paddingLeft: "5px", }} >
                                    <Typography.Title level={5} style={{ margin: 0 }}>จำนวนแถว</Typography.Title>
                                </Col>
                            </Row>

                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                                    <Typography.Text>เริ่ม :</Typography.Text>
                                </Col>
                                <Col span={18} style={{ display: 'flex' }}>
                                    <InputNumber style={{ flex: 1 }} onChange={handleSetStartRow} />
                                </Col>
                            </Row>

                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                                    <Typography.Text>ถึง :</Typography.Text>
                                </Col>
                                <Col span={18} style={{ display: 'flex' }}>
                                    <InputNumber style={{ flex: 1 }} onChange={handleSetEndRow} />
                                </Col>
                            </Row>

                            <Row style={{ alignItems: 'baseline' }}>
                                <Col span={24}  >
                                    <Upload {...uploadProps} fileList={importFile} onChange={onFileChange} maxCount={1} accept=".cvs, .xlsx, .xls" >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                </Col>
                            </Row>
                        </Space>
                    </Section>
                </Col>
                <Col span={18}>
                    <Section>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Row>
                                <Col span={24} style={{ paddingTop: 10 }}>
                                    {errorMsg.length <= 0 ? <></> :
                                        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                            {errorMsg.map((item: any) => (
                                                <>
                                                    <Typography.Text type={'danger'} >{item}</Typography.Text>
                                                </>
                                            ))}
                                        </Space>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Table
                                        size='small'
                                        dataSource={lines}
                                        loading={isLoadingStatus}
                                        columns={lineColumns}
                                        rowKey={(item: any) => item.lineCode}
                                        pagination={false}
                                        // pagination={{ pageSize: 10, defaultCurrent: 1 }}
                                        onRow={(record, rowIndex) => {
                                            return {
                                                style: { background: record.isDuplicate === true ? red[3] : 'white' }
                                            };
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} style={{ display: "flex", justifyContent: "flex-end", alignItems: 'flex-start' }}>
                                    <Space size="large" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            type="primary"
                                            icon={<SaveOutlined />}
                                            onClick={() => handleSave()}
                                            style={{ width: 120 }}
                                            disabled={!isValid}
                                        >
                                            บันทึก
                                        </Button>
                                        <Button
                                            onClick={() => onRemoveImport()}
                                            style={{ width: 120 }}
                                        >
                                            ยกเลิก
                                        </Button>
                                    </Space >
                                </Col>
                            </Row>
                        </Space>
                    </Section>
                </Col>
            </Row>
        </>
    )
}

export default ImportFilepriceArea
