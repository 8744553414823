import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Layout, Radio, Row, Space, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { PreName } from '../../domain/preNameType';
import { Link } from 'react-router-dom';
import prenameServices from 'src/services/prenameServices';
import Section from "../../components/container/Section";

const ListPrename = () => {
    const history = useHistory();
    const [prenames, setPrenames] = useState<PreName[]>([]);
    const [searchName, setSearchName] = useState<any>("");
    const [status, setStatus] = useState<any>(0);
    const [page, setPage] = React.useState(1);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            align: "center" as "center",
            render: (value: number, item: PreName, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "prenameCode",
            align: "center" as "center",
            key: "prenameCode",
        },
        {
            title: "คำนำหน้า",
            dataIndex: "prename",
            key: "prename",
            align: "center" as "center",
            render: (value: string, item: PreName, index: number) => <Link to={`/app/prename/edit/${item.id}`}>{value}</Link>,
        },
    ];
    useEffect(() => {
        prenameServices.getByParam("", 0)
            .then((res) => {
                console.log(res.data)
                setPrenames(res.data);
            })
    }, []);

    const search = async () => {
        prenameServices.getByParam(searchName, status)
            .then((res) => {
                setPrenames(res.data)
            })
    }

    return (
        <div>
            <PageHeader title="กำหนดคำนำหน้า">
                <Space>
                    <Button
                        size="large"
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={() => { history.push("/app/prename/add") }}
                    >เพิ่ม
                    </Button>
                </Space>
            </PageHeader>
            <Section style={{ display: "flex", flexDirection: "column" }}>
                <Form layout="vertical">
                    <Row gutter={[20, 10]} style={{ display: "flex", alignItems: "start" }}>
                        <Col span={8}>
                            <Form.Item label="คำนำหน้า" name="searchName">
                                <Input name="searchName" onChange={(e) => setSearchName(e.target.value)}/>
                            </Form.Item>
                            <Radio.Group name="status" defaultValue={0} onChange={(e) => setStatus(e.target.value)}>
                            <Space>
                                <Radio value={0} >ใช้งาน</Radio>
                                <Radio value={-1} >ไม่ใช้งาน</Radio>
                            </Space>
                        </Radio.Group>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end'}} >
                            <Button  icon={<SearchOutlined />} onClick={() => search()} style={{ marginTop: 30}}>
                                ค้นหา
                            </Button>
					    </Col>
                    </Row>
                </Form>
            </Section>

           <Section style={{ marginTop: 30, paddingTop: 30 }}>
                <Table
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                            setPage(current);
                        },
                        showSizeChanger:false
                    }}
                    rowKey={(item: PreName) => item.id}
                    columns={columns}
                    dataSource={prenames}
                    size="small" />
           </Section>
        </div>
    )
}

export default ListPrename;
